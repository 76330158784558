import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import CustomTerritoryCardView from './CustomTerritoryCardView';
import CustomTerritoryGroupCardView from './CustomTerritoryGroupCardView';

import {
  FormatColorFill,
  FormatColorFillOutlined,
  InfoOutlined,
  TextFields,
  TextFieldsOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import GlobalTerritorySettingsMenu from './GlobalTerritorySettingsMenu';

const CustomTerritoriesMenu = () => {
  const {
    territoryFillEnabled,
    toggleTerritoryFillEnabled,
    evaluatedTerritoryId,
    setEvaluatedTerritoryId,
    selectedTerritoryGroup,
    setSelectedTerritoryGroup,
    editingTerritoryBoundaryId,
  } = useDynamicMapStore((state) => ({
    territoryFillEnabled: state.territoryFillEnabled,
    toggleTerritoryFillEnabled: state.toggleTerritoryFillEnabled,
    evaluatedTerritoryId: state.evaluatedTerritoryId,
    setEvaluatedTerritoryId: state.setEvaluatedTerritoryId,
    selectedTerritoryGroup: state.selectedTerritoryGroup,
    setSelectedTerritoryGroup: state.setSelectedTerritoryGroup,
    editingTerritoryBoundaryId: state.editingTerritoryBoundaryId,
  }));

  const {
    customTerritories,
    territoriesEnabled,
    toggleAllTerritoriesVisibility,
  } = useCustomTerritories();

  const evaluatedTerritory = useMemo(() => {
    if (evaluatedTerritoryId == null) return null;
    return customTerritories.find(({ id }) => id === evaluatedTerritoryId);
  }, [customTerritories, evaluatedTerritoryId]);

  const [infoMessage, setInfoMessage] = useState('');

  useEffect(() => {
    if (selectedTerritoryGroup === null && evaluatedTerritory === null) {
      setInfoMessage(
        'Organize your territories! Create a territory group to categorize and manage related territories. This could be by city, region, or any other way that makes sense for your business.'
      );
    } else if (selectedTerritoryGroup !== null && evaluatedTerritory === null) {
      setInfoMessage(
        "Build your territories! Now that you've chosen a group, create a territory to define a specific area within your group. Give it a name and choose how you'll define its boundaries."
      );
    } else if (selectedTerritoryGroup !== null && evaluatedTerritory !== null) {
      setInfoMessage(
        'You can now define and modify the boundaries of your selected territory. Use the available tools to shape your territory according to your needs.'
      );
    } else {
      setInfoMessage('');
    }
  }, [selectedTerritoryGroup, evaluatedTerritory]);

  const breadcrumbs: { label: string; onClick?: () => void }[] = useMemo(() => {
    const defaultBreadcrumbs = [
      {
        label: 'Territory Groups',
        ...(selectedTerritoryGroup != null || evaluatedTerritory != null
          ? {
              onClick: () => {
                setSelectedTerritoryGroup(null);
                setEvaluatedTerritoryId(null);
              },
            }
          : {}),
      },
    ];

    if (evaluatedTerritory == null && selectedTerritoryGroup != null) {
      return [...defaultBreadcrumbs, { label: selectedTerritoryGroup }];
    }

    if (evaluatedTerritory != null) {
      return [
        ...defaultBreadcrumbs,
        {
          label: evaluatedTerritory.group,
          onClick: () => {
            setSelectedTerritoryGroup(evaluatedTerritory.group);
            setEvaluatedTerritoryId(null);
          },
        },
      ];
    }

    return defaultBreadcrumbs;
  }, [
    evaluatedTerritory,
    selectedTerritoryGroup,
    setEvaluatedTerritoryId,
    setSelectedTerritoryGroup,
  ]);

  const territoryLabelsEnabled = useDynamicMapStore(
    (state) => state.territoryLabelsEnabled
  );
  const toggleTerritoryLabelsEnabled = useDynamicMapStore(
    (state) => state.toggleTerritoryLabelsEnabled
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={-1}
      >
        <Typography variant="h6" marginRight={1}>
          TERRITORY BUILDER
        </Typography>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip
              title={
                territoriesEnabled ? 'Hide Territories' : 'Show Territories'
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={toggleAllTerritoriesVisibility}
                color={territoriesEnabled ? 'primary' : 'default'}
                disabled={!!editingTerritoryBoundaryId}
              >
                {territoriesEnabled ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Fill Territories"
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={toggleTerritoryFillEnabled}
                color={
                  territoriesEnabled && territoryFillEnabled
                    ? 'primary'
                    : 'default'
                }
                disabled={!territoriesEnabled || !!editingTerritoryBoundaryId}
              >
                {territoryFillEnabled ? (
                  <FormatColorFill />
                ) : (
                  <FormatColorFillOutlined />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                territoryLabelsEnabled
                  ? 'Hide Territory Labels'
                  : 'Show Territory Labels'
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'window',
                    },
                  },
                ],
              }}
            >
              <IconButton
                onClick={toggleTerritoryLabelsEnabled}
                color={
                  territoriesEnabled && territoryLabelsEnabled
                    ? 'primary'
                    : 'default'
                }
                disabled={!territoriesEnabled || !!editingTerritoryBoundaryId}
              >
                {territoryLabelsEnabled ? (
                  <TextFields />
                ) : (
                  <TextFieldsOutlined />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        // paddingLeft={1}
        gap={1}
        flexShrink={0}
        paddingX={1}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ minHeight: '50px' }}
          paddingY={1}
        >
          {(selectedTerritoryGroup != null || evaluatedTerritory != null) && (
            <IconButton
              onClick={() => {
                if (evaluatedTerritory != null) {
                  setEvaluatedTerritoryId(null);
                } else if (selectedTerritoryGroup != null) {
                  setSelectedTerritoryGroup(null);
                }
              }}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          )}
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs.map((crumb, index) => {
              const clickable = crumb.onClick != null;
              const textColor =
                clickable || index === 0 ? 'text.primary' : 'text.disabled';

              return (
                <Typography
                  key={crumb.label}
                  variant="body1"
                  color={textColor}
                  onClick={clickable ? crumb.onClick : undefined}
                  sx={{
                    cursor: clickable ? 'pointer' : 'default',
                    textDecoration: clickable ? 'underline' : 'none',
                  }}
                >
                  {crumb.label}
                </Typography>
              );
            })}
          </Breadcrumbs>
          {infoMessage && (
            <Tooltip title={infoMessage} placement="right">
              <InfoOutlined sx={{ marginLeft: 1, cursor: 'pointer' }} />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box flex="1 1 auto" overflow="auto">
        {selectedTerritoryGroup != null ? (
          <CustomTerritoryCardView />
        ) : (
          <CustomTerritoryGroupCardView />
        )}
      </Box>
      <Box flexShrink={0}>
        <GlobalTerritorySettingsMenu />
      </Box>
    </Box>
  );
};

export default CustomTerritoriesMenu;
