import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Popup } from 'react-map-gl';
import { formatDateRange, formatTierRange } from '../../utils/formatters';
import { FormattedTAIDData } from './PulsePriceTAIDSource';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`taid-tabpanel-${index}`}
      aria-labelledby={`taid-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

interface TAIDPopupProps {
  data: FormattedTAIDData;
  onClose: () => void;
}

const formatCurrency = (value: number | null) => {
  if (value === null) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(Math.round(value));
};

const formatValue = (value: string | number | null | undefined) => {
  if (value === null || value === undefined || value === '' || value === 0) {
    return '-';
  }
  return value;
};

const TAIDPricingPopup: React.FC<TAIDPopupProps> = ({
  data,
  onClose,
}: TAIDPopupProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleAccordionChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const renderLocationInfo = () => {
    const locationFields = [
      { label: 'Territory', value: data.territory },
      { label: 'Market (CBSA)', value: data.cbsa },
      { label: 'Urbanicity Type', value: data.urbanizationType },
      { label: 'Radius (mi)', value: data.radius },
      { label: 'Competitors', value: data.competitors },
    ];

    return (
      <Table size="small">
        <TableBody>
          {locationFields.map(({ label, value }, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                sx={{
                  pl: 0,
                  width: '150px',
                  fontWeight: 'bold',
                }}
              >
                {label}
              </TableCell>
              <TableCell sx={{ pl: 2 }}>{formatValue(value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const renderPricingInfo = () => {
    return (
      <>
        <Table size="small" sx={{ mb: 3 }}>
          <TableBody>
            <TableRow>
              <TableCell component="th" sx={{ pl: 0, fontWeight: 'bold' }}>
                Dates Data Collected
              </TableCell>
              <TableCell sx={{ pl: 2 }}>
                {formatDateRange(data.datesCollected)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" sx={{ pl: 0, fontWeight: 'bold' }}>
                Pulse Tier
              </TableCell>
              <TableCell sx={{ pl: 2 }}>
                {formatValue(data.pulseTier)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Box sx={{ display: 'flex', gap: 4, mb: 2 }}>
          {/* Pulse Index Section */}
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  position: 'relative',
                  '&::before, &::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    width: '50px',
                    height: '1px',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  },
                  '&::before': {
                    right: '100%',
                    marginRight: '8px',
                  },
                  '&::after': {
                    left: '100%',
                    marginLeft: '8px',
                  },
                }}
              >
                Pulse Index
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 4, justifyContent: 'center' }}>
              <Box>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Avg National Index
                </Typography>
                <Typography>{formatValue(data.avgNationalIndex)}</Typography>
              </Box>
              <Box>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Avg Market Index
                </Typography>
                <Typography>{formatValue(data.avgMarketIndex)}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'bold',
              position: 'relative',
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                width: '50px',
                height: '1px',
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              },
              '&::before': {
                right: '100%',
                marginRight: '8px',
              },
              '&::after': {
                left: '100%',
                marginLeft: '8px',
              },
            }}
          >
            Pulse Pricing
          </Typography>
        </Box>

        {Object.entries(data.offerings)
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([category, items]) => (
            <Accordion
              key={category}
              expanded={expandedAccordion === category}
              onChange={handleAccordionChange(category)}
              sx={{
                '&:before': { display: 'none' },
                boxShadow: 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  px: 1,
                  '&.Mui-expanded': {
                    minHeight: '48px',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '12px 0',
                  },
                }}
              >
                <Typography variant="subtitle2">
                  {category} ({Object.keys(items).length} items)
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Pulse Tier Range
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Pulse Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(items)
                      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
                      .map(([key, { name, price, tierRange }]) => (
                        <TableRow key={key}>
                          <TableCell>{name}</TableCell>
                          <TableCell>{formatTierRange(tierRange)}</TableCell>
                          <TableCell>{formatCurrency(price)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  };

  return (
    <Popup
      latitude={data.popupCoordinates.latitude}
      longitude={data.popupCoordinates.longitude}
      closeButton={false}
      closeOnClick={false}
      maxWidth="800px"
    >
      <Card sx={{ maxWidth: 800, boxShadow: 'none' }}>
        <CardContent sx={{ padding: 1, position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 4,
              right: 4,
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              mb: 2,
              pr: 3,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Box sx={{ mr: 4 }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {data.taId}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mb: 0.5, display: 'block' }}
                  >
                    {data.address}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: 'block', mb: 1 }}
                  >
                    {data.coordinates}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      minHeight: 36,
                      '& .MuiTab-root': {
                        minHeight: 36,
                        padding: '6px 12px',
                        fontSize: '0.875rem',
                      },
                    }}
                  >
                    <Tab label="Pulse Price" />
                    <Tab label="Location Info" />
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Box>

          <TabPanel value={tabValue} index={0}>
            {renderPricingInfo()}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {renderLocationInfo()}
          </TabPanel>
        </CardContent>
      </Card>
    </Popup>
  );
};

export default TAIDPricingPopup;
