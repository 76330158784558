import { Box, Typography } from '@mui/material';
import turfCircle from '@turf/circle';
import { bbox } from '@turf/turf';
import { useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import loading from '~/src/assets/animations/logo-loop.json';
import LogoAnimation from '~/src/common/components/animations/LogoAnimation';
import { PulseReportConfig } from '~/src/constants';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import { ReportMap } from '../ReportHeatmapMap';
import { ReportTable } from '../ReportTable';
import { ReportItem } from '../services/pulse-service';

const { LOADING_LABEL } = PulseReportConfig;

export const GeneratorsSection = ({
  generators = [],
  isLoading,
}: {
  generators?: ReportItem[];
  isLoading: boolean;
}) => {
  //filter duplicate generators by comparing lat lng values
  const uniqueGenerators = generators?.filter(
    (generator, index, self) =>
      index ===
      self.findIndex((t) => t.lat === generator.lat && t.lng === generator.lng)
  );

  const reportsWithIndexes = useMemo(
    () => uniqueGenerators.map((report, index) => ({ ...report, index })),
    [uniqueGenerators]
  );

  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const { customPins: pins } = useCustomPins();
  const evaluatedPin = useMemo(
    () => pins.find((pin) => pin.id === evaluatedPinId),
    [pins, evaluatedPinId]
  );

  const [selectedPinId, setSelectedPinId] = useState<number>(0);

  const circleBoundary = useMemo(() => {
    if (!evaluatedPin) return null;
    return turfCircle([evaluatedPin.pos.lng, evaluatedPin.pos.lat], 0.5, {
      units: 'miles',
    });
  }, [evaluatedPin]);

  const zoomBbox = useMemo(
    () => (circleBoundary ? bbox(circleBoundary) : undefined),
    [circleBoundary]
  );

  const userSettings = useSettingsStore((state) => state.userSettings);

  const generatorPoi = userSettings?.poiGroups?.find((poiGroup) => {
    return (
      poiGroup.group.toLowerCase().includes('cotenants') ||
      poiGroup.group.toLowerCase().includes('co-tenants')
    );
  });

  return (
    <Box
      flex={1}
      width="100%"
      position="relative"
      p={2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" fontWeight={500} mb={2}>
        Generators
      </Typography>
      <ReportMap
        context="generators"
        reports={reportsWithIndexes}
        selectedPinId={selectedPinId}
        setSelectedPinId={setSelectedPinId}
        zoomBbox={zoomBbox as [number, number, number, number] | undefined}
        preparedLocation={
          reportsWithIndexes[0]
            ? reportsWithIndexes[0]
            : ({ lat: evaluatedPin?.pos?.lat, lng: evaluatedPin?.pos?.lng } as {
                lat: number;
                lng: number;
              })
        }
        additionalLayers={
          circleBoundary && (
            <Source
              id="generators-boundary"
              type="geojson"
              data={circleBoundary}
            >
              <Layer
                id="generators-boundary-halo"
                type="line"
                paint={{
                  'line-color': mapStyle === 'streets-v12' ? '#fff' : '#000',
                  'line-opacity': mapStyle === 'streets-v12' ? 0.5 : 0.9,
                  'line-width': 8,
                }}
              />
              <Layer
                id="generators-boundary-line"
                type="line"
                paint={{
                  'line-color': mapStyle === 'streets-v12' ? '#000' : '#fff',
                  'line-opacity': mapStyle === 'streets-v12' ? 0.5 : 0.9,
                  'line-width': 4,
                }}
              />
            </Source>
          )
        }
      />
      {!isLoading ? (
        <ReportTable
          onRowClick={(index: number) => setSelectedPinId(index)}
          reports={reportsWithIndexes}
          selectedPinId={selectedPinId}
          isGroupConfigured={!!generatorPoi}
          groupName="generators"
        />
      ) : (
        <Box width="100%" display="flex" justifyContent="center" mt={4}>
          <LogoAnimation src={loading} label={LOADING_LABEL} />
        </Box>
      )}
    </Box>
  );
};
