export const citylifeLocalBusinessCodes = {
  '812112': 'Beauty Salons',
  '621310': 'Chiropractors',
  '621210': 'Dentists',
  '6211%': 'Physicians',
  '453110': 'Florists',
  '524210': 'Insurance Agencies',
  '448310': 'Jewelry Stores',
  '522310': 'Loan Brokers',
  '621399': 'Health Practitioners',
  '621320': 'Optometrists',
  '522110': 'Commercial Banking',
  '523930': 'Investment Advice',
  '622110': 'Hospitals',
  '531210': 'Real Estate Agents',
  '561730': 'Landscaping Services',
  '922110': 'Law Practices', // Naics code for "courts", for some reason. No results found for lawyers.
  '541940': 'Veterinary Services',
  '713940': 'Fitness Centers',
  '561720': 'Janitorial Services',
  '711310': 'Event Facilities',
  '2361%': 'Residential Construction',
  '2383%': 'Building Contractors',
  '2382%': 'Equipment Contractors',
  '2381%': 'Building Contractors',
  '2389%': 'Specialty Contractors',
  '4411%': 'Auto Dealers',
  '5414%': 'Design Services',
  '6233%': 'Assisted Living Facilities',
};
