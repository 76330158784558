import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { Permissions } from '~/src/constants';
import { checkPermission } from '~/src/global/services/permissionService';
import { fetchTileset, LayerInfo } from '../services/mapboxService';

export type TrafficVintages = 2021 | 2022;

export const trafficSources: Record<TrafficVintages, string> = {
  2021: 'mapbox://luketruitt1.plotr-traffic',
  2022: 'mapbox://luketruitt1.plotr-traffic-2022',
};

export interface TrafficStore {
  fetchPermission: (
    accessToken: string,
    setPermissionStore: (permission: string, value: boolean) => void
  ) => Promise<boolean>;
  layerInfo: LayerInfo[];
  error: Error | null;
  isLoading: boolean;
  fetchLayerInfo: ({ vintage }: { vintage?: TrafficVintages }) => Promise<void>;
  trafficSelection: {
    selectedDay: number; // 0-7
    selectedHour: number; // -1 to 23
    direction: number; // 1, 2, 3
  };

  trafficEnabled: boolean;
  setTrafficEnabled: (enabled: boolean) => void;

  trafficRange: { min: number; max: number };
  setTrafficRange: ({ min, max }: { min: number; max: number }) => void;

  selectedTrafficSegments: string[];
  setSelectedTrafficSegments: (segments: string[]) => void;

  trafficBbox: [number, number, number, number] | null;
  setTrafficBbox: (bbox: [number, number, number, number]) => void;

  setTrafficSelectionDay: (day: number) => void;
  setTrafficSelectionHour: (hour: number) => void;
  setTrafficSelectionDirection: (direction: number) => void;

  selectedLayers: string[];
  setSelectedLayers: (layers: string[]) => void;

  selectedVintage: TrafficVintages;
  setSelectedVintage: (vintage: TrafficVintages) => void;
}

const useTrafficStore = create(
  persist(
    subscribeWithSelector<TrafficStore>((set) => ({
      hasPermission: false,
      async fetchPermission(
        accessToken: string,
        setPermissionStore: (permission: string, value: boolean) => void
      ): Promise<boolean> {
        // Ensure this matches the return type in the interface
        set({ isLoading: true, error: null });

        try {
          const hasPermission = await checkPermission(
            accessToken,
            Permissions.READ_TRAFFIC,
            setPermissionStore
          );

          set({
            isLoading: false,
          });

          return hasPermission; // Return the boolean value as expected
        } catch (error) {
          set({
            error:
              error instanceof Error
                ? error
                : new Error('An unexpected error occurred'),
            isLoading: false,
          });

          return false; // Return false if an error occurs
        }
      },
      layerInfo: [],
      error: null,
      isLoading: false,
      trafficSelection: {
        selectedDay: 0, // Default value 0 indicating average of all days
        selectedHour: -1, // Default value -1 indicating average of all hours
        direction: 3, // Default: bidirectional
      },

      trafficEnabled: false,
      setTrafficEnabled: (enabled: boolean) => {
        set(() => ({
          trafficEnabled: enabled,
        }));
      },

      async fetchLayerInfo({
        vintage = 2021,
      }: {
        vintage?: TrafficVintages;
      }): Promise<void> {
        set({ isLoading: true, error: null });

        const trafficTilesetURL = trafficSources[vintage];

        try {
          const tileset = await fetchTileset({ tilesetURL: trafficTilesetURL });
          set({
            layerInfo: tileset.vector_layers,
            error: null,
            isLoading: false,
          });
        } catch (error: unknown) {
          if (error instanceof Error) {
            set({ error, isLoading: false });
          } else {
            console.error('Error fetching layer info: ', error);
            set({ error: new Error('Unknown error'), isLoading: false });
          }
        }
      },

      // Traffic selection setters
      setTrafficSelectionDay: (day) => {
        if (day >= 0 && day <= 7) {
          set((state) => ({
            trafficSelection: {
              ...state.trafficSelection,
              selectedDay: day,
            },
          }));
        } else {
          console.error('Invalid day selected for traffic data.');
        }
      },

      setTrafficSelectionHour: (hour) => {
        if (hour >= -1 && hour <= 23) {
          set((state) => ({
            trafficSelection: {
              ...state.trafficSelection,
              selectedHour: hour,
            },
          }));
        } else {
          console.error('Invalid hour selected for traffic data.');
        }
      },

      setTrafficSelectionDirection: (direction) => {
        if ([1, 2, 3].includes(direction)) {
          set((state) => ({
            trafficSelection: {
              ...state.trafficSelection,
              direction: direction,
            },
          }));
        } else {
          console.error('Invalid direction selected for traffic data.');
        }
      },
      trafficRange: { min: 0, max: 1 },
      setTrafficRange: ({ min, max }) => {
        set(() => ({
          trafficRange: {
            min: min,
            max: max,
          },
        }));
      },
      selectedTrafficSegments: [],
      setSelectedTrafficSegments: (segments) => {
        set(() => ({
          selectedTrafficSegments: segments,
        }));
      },

      trafficBbox: null,
      setTrafficBbox: (bbox) => {
        set(() => ({
          trafficBbox: bbox,
        }));
      },

      selectedLayers: [],
      setSelectedLayers: (layers) => {
        set(() => ({
          selectedLayers: layers,
        }));
      },

      selectedVintage: 2021,
      setSelectedVintage: (vintage) => {
        set(() => ({
          selectedVintage: vintage,
        }));
      },
    })),
    {
      name: 'traffic-store',
    }
  )
);

useTrafficStore.subscribe(
  (state) => state.selectedVintage,
  (selectedVintage) => {
    useTrafficStore.setState((state) => ({
      ...state,
      fetchLayerInfo: () => state.fetchLayerInfo({ vintage: selectedVintage }),
    }));
  }
);

export default useTrafficStore;
