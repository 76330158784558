import { FlightTakeoff } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CustomTerritory,
  TerritoryType,
} from '@plotr/plotr-multiplayer-data/src';
import { featureCollection, polygon } from '@turf/helpers';
import axios from 'axios';
import { Feature, Polygon } from 'geojson';
import { memo, Ref, useCallback, useState } from 'react';

import useGeometryCache from '~/src/common/hooks/useGeometryCache';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import { parseEnv } from '../../../../../common-utils/src';
import fitMapToBoundary from '../../dynamic-map/helpers/fitMapToBoundary';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import EditTerritoryGroupDialog from '../EditTerritoryGroupDialog';
import { getBoundingBoxFromBoundaries } from '../helpers/BoundariesExtermePoints';

const env = parseEnv({ API_V2: process.env.API_V2 });

const TerritoryGroupCard = memo(
  ({
    cardRef,
    group,
    territoryCount,
    onAction,
    onHover,
  }: {
    cardRef?: Ref<HTMLDivElement>;
    group: string;
    territoryCount: number;
    onAction: () => void;
    onHover?: (group: string, value: boolean) => void;
  }) => {
    const map = useMapContext();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const geometryCache = useGeometryCache();

    const { hiddenTerritoryGroups, toggleTerritoryGroupVisibility } =
      useDynamicMapStore((state) => ({
        hiddenTerritoryGroups: state.hiddenTerritoryGroups,
        toggleTerritoryGroupVisibility: state.toggleTerritoryGroupVisibility,
      }));

    const isReadOnly = usePermissionsStore((state) => state.isReadOnly);
    const { customTerritories } = useCustomTerritories();

    const handleFlyTo = useCallback(async () => {
      if (map == null) return;

      const territoriesInGroup = customTerritories.filter(
        (territory) => territory.group === group
      );

      const { drawnTerritories, standardTerritories } =
        territoriesInGroup.reduce<{
          drawnTerritories: CustomTerritory[];
          standardTerritories: CustomTerritory[];
        }>(
          (acc, territory) => {
            if (territory.type === TerritoryType.Custom) {
              acc.drawnTerritories.push(territory);
            } else {
              acc.standardTerritories.push(territory);
            }
            return acc;
          },
          { drawnTerritories: [], standardTerritories: [] }
        );

      if (standardTerritories.length === 0 && drawnTerritories.length > 0) {
        const allBoundaries = drawnTerritories
          .map((territory) => Object.values(territory.boundaries))
          .flat();

        const coordinates = getBoundingBoxFromBoundaries(allBoundaries);
        if (coordinates != null) {
          fitMapToBoundary(
            map,
            featureCollection([
              polygon(coordinates),
            ]) as unknown as Feature<Polygon>
          );
        }
        return;
      }

      const zipcodes = new Set<string>();
      for (const territory of standardTerritories) {
        for (const boundary of Object.values(territory.boundaries)) {
          zipcodes.add(boundary.id.padStart(5, '0'));
        }
      }

      const zipcodesArray = Array.from(zipcodes);
      if (zipcodesArray.length === 0) return;

      // Check cached geometry
      const zoomRangeFourToSixteen = new Array(12).fill(0).map((_, i) => i + 4);
      const potentialCacheKeys = zoomRangeFourToSixteen.map(
        (zoom) => `${group}::${zoom}`
      );
      const cachedTerritories = potentialCacheKeys.map((key) =>
        geometryCache.get(key)
      );
      const highestQualityCached = cachedTerritories.reduce((acc, curr) => {
        return (curr?.data.coordinates.length ?? 0) >
          (acc?.data.coordinates.length ?? 0)
          ? curr
          : acc;
      }, null);

      if (highestQualityCached != null) {
        fitMapToBoundary(
          map,
          highestQualityCached.data as unknown as Feature<Polygon>
        );
        return;
      }

      try {
        const response = await axios.post(`${env.API_V2}/merge`, {
          zipcodes: zipcodesArray,
          bbox: true,
        });
        const data = response.data;
        data.features.push(
          ...drawnTerritories
            .map((territory) => {
              const coordinates =
                territory.boundaries[Object.keys(territory.boundaries)[0]]
                  ?.coordinates;
              return coordinates ? polygon([coordinates]) : null;
            })
            .filter(Boolean)
        );

        fitMapToBoundary(map, data);
      } catch (err) {
        console.error('Error fetching merged boundaries:', err);
      }
    }, [map, group, customTerritories, geometryCache]);

    const handleMouseEnter = useCallback(() => {
      onHover?.(group, true);
    }, [group, onHover]);

    const handleMouseLeave = useCallback(() => {
      onHover?.(group, false);
    }, [group, onHover]);

    const handleOnClick = useCallback(() => {
      onAction();
      onHover?.(group, false);
    }, [onAction, onHover, group]);

    const handleToggleVisibility = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        toggleTerritoryGroupVisibility(group);
      },
      [group, toggleTerritoryGroupVisibility]
    );

    const handleEdit = useCallback(
      (e: React.MouseEvent) => {
        if (!isReadOnly) {
          e.stopPropagation();
          setIsEditing(true);
        }
      },
      [isReadOnly]
    );

    return (
      <>
        <Paper
          ref={cardRef}
          variant="outlined"
          sx={{
            display: 'flex',
            p: 1,
            cursor: 'pointer',
            '&:hover': {
              boxShadow: 6,
            },
            height: '100%',
          }}
          onClick={handleOnClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="body1"
              sx={{
                mb: '0px',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {group}
            </Typography>
            <Box display="flex" gap={2} alignItems="center" alignSelf="start">
              <Chip
                label={
                  <Tooltip
                    title={`${territoryCount} ${territoryCount > 1 ? 'territories' : 'territory'}`}
                  >
                    <Typography fontSize={12}>{territoryCount}</Typography>
                  </Tooltip>
                }
                size="small"
                sx={{
                  backgroundColor: '#f5f5f5',
                  color: 'text.secondary',
                }}
              />
              <Typography
                variant="caption"
                color={!isReadOnly ? 'text.secondary' : '#ddd'}
                onClick={handleEdit}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { color: !isReadOnly ? 'text.primary' : '#ddd' },
                }}
              >
                Edit
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <IconButton onClick={handleToggleVisibility} size="small">
              {hiddenTerritoryGroups.includes(group) ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleFlyTo();
              }}
              size="small"
            >
              <FlightTakeoff sx={{ marginTop: 'auto' }} />
            </IconButton>
          </Box>
        </Paper>
        <EditTerritoryGroupDialog
          group={group}
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSave={() => setIsEditing(false)}
        />
      </>
    );
  }
);

TerritoryGroupCard.displayName = 'TerritoryGroupCard';

export default TerritoryGroupCard;
