import { TextField } from '@mui/material';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import { useState } from 'react';
import EditEntityDialog from './EditEntityDialog';

interface EditTerritoryGroupDialogProps {
  group: string;
  isOpen: boolean;
  onClose?: () => void;
  onSave?: () => void;
}

export default function EditTerritoryGroupDialog(
  props: EditTerritoryGroupDialogProps
) {
  const [groupName, setGroupName] = useState<string>(props.group);
  const territoryMethods = plotrMultiplayerData.methods?.territories;

  const handleDelete = () => {
    territoryMethods?.deleteGroup(props.group);
    props.onClose?.();
  };

  const handleSave = () => {
    territoryMethods?.renameGroup(props.group, groupName);
    props.onSave?.();
  };

  return (
    <EditEntityDialog
      dialogTitle="Edit Territory Group"
      isOpen={props.isOpen}
      entityName={groupName}
      isDeletable
      onClose={props.onClose}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <TextField
        label="Group Name"
        value={groupName}
        onChange={(event) => {
          setGroupName(event.target.value);
        }}
        fullWidth
        margin="dense"
        variant="outlined"
      />
    </EditEntityDialog>
  );
}
