import AddBoxIcon from '@mui/icons-material/AddBox';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Input } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import { useOverflowingContainer } from '../hooks/useOverflowingContainer';

interface KeyValueTableProps {
  data: Map<string, string>;
  addKeyValuePair: (key: string, value: string) => void;
  updateKeyValuePair: (key: string, value: string) => void;
  removeKeyValuePair: (key: string) => void;
  alertMessage?: (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => void;
}

const FADE_OUT_STYLES = {
  position: 'absolute',
  left: 0,
  right: 0,
  height: 30,
  pointerEvents: 'none',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  background:
    'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
};

const KeyValueTable: React.FC<KeyValueTableProps> = ({
  data,
  addKeyValuePair,
  updateKeyValuePair,
  removeKeyValuePair,
  alertMessage = () => {},
}) => {
  const {
    ref: overflowContainerRef,
    isOverflowing,
    isAtTop,
    isAtBottom,
  } = useOverflowingContainer();

  const [rows, setRows] = useState<Map<string, string>>(data);
  const [editingKey, setEditingKey] = useState<string>('');
  const [editingValue, setEditingValue] = useState<string>('');
  const [newKeyDuringEdit, setNewKeyDuringEdit] = useState<string>('');
  const [newKey, setNewKey] = useState<string>('');
  const [newValue, setNewValue] = useState<string>('');
  const keyInputRef = useRef<HTMLInputElement>(null);
  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const handleDelete = (key: string) => {
    removeKeyValuePair(key);
    alertMessage('Successfully removed key value pair.', 'warning');
    setRows((prevRows) => {
      const newRows = new Map(prevRows);
      newRows.delete(key);
      return newRows;
    });
  };

  const handleAddNewRow = () => {
    if (newKey && !newValue) {
      alertMessage(
        'Please add a value to save this key and value pairing.',
        'warning'
      );
      return;
    }
    if (newKey && newValue) {
      if (rows.has(newKey) && rows.get(newKey) === newValue) {
        alertMessage('This key-value pair already exists.', 'info');
        return;
      }
      if (rows.has(newKey) && rows.get(newKey) !== newValue) {
        alertMessage('Updated existing key with new value.', 'success');
      } else {
        alertMessage('Successfully added key value pair.', 'success');
      }
      addKeyValuePair(newKey, newValue);
      setRows((prevRows) => {
        const newRows = new Map(prevRows);
        newRows.set(newKey, newValue);
        return newRows;
      });
      setNewKey('');
      setNewValue('');
      keyInputRef.current?.focus();
    }
  };

  const startEditing = (key: string, value: string) => {
    setEditingKey(key);
    setEditingValue(value);
    setNewKeyDuringEdit(key);
  };

  const cancelEditing = () => {
    setEditingKey('');
    setEditingValue('');
    setNewKeyDuringEdit('');
  };

  const updateKeyValuePairHandler = () => {
    if (!editingValue) {
      alertMessage(
        'Please add a value to save this key and value pairing.',
        'warning'
      );
      return;
    }
    if (newKeyDuringEdit !== editingKey && rows.has(newKeyDuringEdit)) {
      alertMessage('Duplicate key detected. Please use a unique key.', 'error');
      return;
    }
    if (newKeyDuringEdit !== editingKey) {
      removeKeyValuePair(editingKey);
    }
    updateKeyValuePair(newKeyDuringEdit, editingValue);
    alertMessage('Successfully updated key value pair.', 'success');
    setRows((prevRows) => {
      const newRows = new Map(prevRows);
      newRows.delete(editingKey);
      newRows.set(newKeyDuringEdit, editingValue);
      return newRows;
    });
    setEditingKey('');
    setEditingValue('');
    setNewKeyDuringEdit('');
  };

  const handleKeyDown = (keyEvent: React.KeyboardEvent, isEditing: boolean) => {
    if (keyEvent.key === 'Enter') {
      if (isEditing) {
        updateKeyValuePairHandler();
      } else {
        handleAddNewRow();
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2}>
        <Input
          type="text"
          placeholder="Key"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, false)}
          size="small"
          fullWidth
          inputRef={keyInputRef}
        />
        <Input
          type="text"
          placeholder="Value"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, false)}
          size="small"
          fullWidth
        />
        <IconButton
          onClick={handleAddNewRow}
          disabled={!newKey || !newValue || isReadOnly}
        >
          <AddBoxIcon color={newKey && newValue ? 'primary' : 'disabled'} />
        </IconButton>
      </Box>
      <Box position="relative">
        <Box
          sx={{
            ...FADE_OUT_STYLES,
            top: 0,
            opacity: isAtTop ? 0 : 1,
            visibility: isAtTop ? 'hidden' : 'visible',
            background:
              'linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
          }}
        />
        <Box
          ref={overflowContainerRef}
          display="flex"
          flexDirection="column"
          gap={0.5}
          maxHeight="300px"
          overflow="auto"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {Array.from(rows.entries()).map(([key, val]) => (
            <Grid
              container
              xs={12}
              key={key}
              display="flex"
              alignItems="center"
              sx={{
                transition: 'background-color 0.5s',
                padding: 0.5,
              }}
            >
              <Grid
                item
                xs={4}
                title={key}
                sx={{
                  pr: editingKey === key ? 1 : 0,
                }}
              >
                {editingKey === key ? (
                  <Input
                    type="text"
                    placeholder="Key"
                    value={newKeyDuringEdit}
                    onChange={(e) => setNewKeyDuringEdit(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, true)}
                    size="small"
                    fullWidth
                  />
                ) : (
                  key
                )}
              </Grid>
              <Grid item xs={5} sx={{ textAlign: 'right', pr: 1 }} title={val}>
                {editingKey === key ? (
                  <Input
                    type="text"
                    placeholder="Value"
                    value={editingValue}
                    onChange={(e) => setEditingValue(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, true)}
                    size="small"
                    fullWidth
                  />
                ) : (
                  val
                )}
              </Grid>
              <Grid
                item
                xs={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {editingKey === key ? (
                  <>
                    <IconButton
                      onClick={updateKeyValuePairHandler}
                      sx={{ padding: 0.5 }}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton onClick={cancelEditing} sx={{ padding: 0.5 }}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      disabled={isReadOnly}
                      onClick={() => startEditing(key, val)}
                      sx={{ padding: 0.5 }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      disabled={isReadOnly}
                      onClick={() => handleDelete(key)}
                      sx={{ padding: 0.5 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          ))}
        </Box>
        <Box
          sx={{
            ...FADE_OUT_STYLES,
            bottom: 0,
            opacity: isOverflowing && !isAtBottom ? 1 : 0,
            visibility: isOverflowing && !isAtBottom ? 'visible' : 'hidden',
            background:
              'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
          }}
        />
      </Box>
    </Box>
  );
};

export default KeyValueTable;
