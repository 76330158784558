import { Box, Grid, Typography } from '@mui/material';
import { CustomPin } from '@plotr/plotr-multiplayer-data/src';
import { Control } from 'react-hook-form';
import { EditPinSchema } from '../schema';
import { PinTerritories } from './PinTerritories';
import { PinTags } from './PinTags';
import { PinGroup } from './PinGroup';
import { ReactNode } from 'react';

type GeneralTabProps = {
  pinGroups: string[];
  pin: CustomPin;
  control: Control<EditPinSchema>;
  isEditing: boolean;
};

const GeneralTab = ({
  pinGroups,
  pin,
  control,
  isEditing,
}: GeneralTabProps) => {
  const fields: { label: string; input: ReactNode }[] = [
    {
      label: 'Group',
      input: (
        <PinGroup
          pinGroups={pinGroups}
          control={control}
          isEditing={isEditing}
          pin={pin}
        />
      ),
    },
    {
      label: 'Territories',
      input: (
        <PinTerritories control={control} isEditing={isEditing} pin={pin} />
      ),
    },
    {
      label: 'Tags',
      input: <PinTags control={control} isEditing={isEditing} />,
    },
  ];

  return (
    <Box minHeight={10} display="flex" flexDirection={'column'} gap={2} mt={1}>
      {fields.map((field, index) => (
        <Grid display="flex" container spacing={2} key={index}>
          <Grid item xs={3}>
            <Typography
              variant="body2"
              fontWeight={500}
              title={field.label}
              sx={{ pt: 0.2 }}
            >
              {field.label}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {field.input}
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default GeneralTab;
