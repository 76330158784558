import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { GlobalStyles as MUIGlobalStyles, ThemeProvider } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ModalProvider } from './common/hooks/useModalDataContext';
import Error404 from './pages/page-404/Page404';
import UserDashboard from './pages/user-dashboard/UserDashboard';
import theme from './theme';

const env = parseEnv({
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
});

const GlobalStyles = () => (
  <MUIGlobalStyles
    styles={{
      // Animation for wiggling lock icons
      '@keyframes wiggle': {
        '0%': { transform: 'rotate(0deg)' },
        '12.5%': { transform: 'rotate(-15deg)' },
        '25%': { transform: 'rotate(15deg)' },
        '37.5%': { transform: 'rotate(-15deg)' },
        '50%': { transform: 'rotate(15deg)' },
        '62.5%': { transform: 'rotate(-15deg)' },
        '75%': { transform: 'rotate(15deg)' },
        '87.5%': { transform: 'rotate(-15deg)' },
        '100%': { transform: 'rotate(0deg)' },
      },
    }}
  />
);

const queryClient = new QueryClient();

// redirect login page to Auth0 login
function LoginRedirect() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return null;
}

export default function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={env.AUTH0_DOMAIN}
          clientId={env.AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: env.AUTH0_AUDIENCE,
          }}
        >
          <ModalProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                  <Routes>
                    <Route path="/login" Component={LoginRedirect} />
                    <Route path="/" Component={UserDashboard} />
                    <Route path="*" Component={Error404} />
                  </Routes>
              </BrowserRouter>
            </QueryClientProvider>
          </ModalProvider>
        </Auth0Provider>
      </ThemeProvider>
    </>
  );
}
