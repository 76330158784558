import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useState } from 'react';
import * as Y from 'yjs';

import useMultiplayerContext from './useMultiplayerContext';

export type GetStateFn<T> = (ydoc: Y.Doc | null) => T;

const useSyncedState = <T>(getState: GetStateFn<T>): T => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStateCallback = useCallback(getState, []);
  const { ydoc } = useMultiplayerContext();
  const [state, setState] = useState<T>(getState(ydoc));

  // Set initial state
  useEffect(() => {
    setState(getStateCallback(ydoc));
  }, [ydoc, getStateCallback]);

  const updateState = useCallback(
    (_update: Uint8Array, _origin: unknown, doc: Y.Doc) => {
      const newState = getStateCallback(doc);
      const shouldUpdate = !isEqual(state, newState);

      if (shouldUpdate) {
        setState(newState);
      }
    },
    [getStateCallback, state]
  );

  // Subscribe to updates
  useEffect(() => {
    ydoc?.on('updateV2', updateState);
    return () => {
      ydoc?.off('updateV2', updateState);
    };
  }, [ydoc, getStateCallback, updateState]);

  return state;
};

export default useSyncedState;
