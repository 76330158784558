import { useEffect } from 'react';
import { Source } from 'react-map-gl';

import { Permissions } from '~/src/constants';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import TradeAreaHeatmapSource from '~/src/features/dynamic-map-controller/PulseDrawer/MobileData/TradeAreaHeatmapSource';
import TradeAreaOverlapHeatmapSource from '~/src/features/dynamic-map-controller/PulseDrawer/MobileTradeAreaOverlapData/TradeAreaOverlapHeatmapSource';
import usePermissionCheck from '~/src/global/hooks/usePermissionCheck';
import useDynamicMapStore from '../../hooks/useDynamicMapStore';
import type { Badge } from '../../services/poiService';
import POIBrandBadgeLayer from './poi-layers/POIBrandBadgeLayer';
import POIBrandPinLayer from './poi-layers/POIBrandPinLayer';
import POILocalFilterLayer from './poi-layers/POILocalFilterLayer';
import POIPlacesBadgeLayer from './poi-layers/POIPlacesBadgeLayer';
import POIPlacesPinLayer from './poi-layers/POIPlacesPinLayer';
import POIPulseSourceLayer from './poi-layers/POIPulseSourceLayer';

type BadgeMapping = {
  badge: Badge;
} & (
  | {
      brandIds: string[];
    }
  | {
      placeIds: string[];
    }
);

export function POISource() {
  const selectedBrands = useDynamicMapStore((state) => state.selectedBrands);
  const badgeMappingById = selectedBrands.reduce<Record<string, BadgeMapping>>(
    (acc, brandOrPlacesResult) => {
      const badge = brandOrPlacesResult.badge;
      if (badge == null) return acc;

      const existingBadgeMapping = acc[badge.id];

      if ('placeIds' in brandOrPlacesResult) {
        acc[badge.id] = {
          badge: badge,
          placeIds: (existingBadgeMapping != null &&
          'placeIds' in existingBadgeMapping
            ? existingBadgeMapping.placeIds
            : []
          ).concat(brandOrPlacesResult.placeIds),
        };
      } else {
        acc[badge.id] = {
          badge: badge,
          brandIds: (existingBadgeMapping != null &&
          'brandIds' in existingBadgeMapping
            ? existingBadgeMapping.brandIds
            : []
          ).concat(brandOrPlacesResult.id),
        };
      }

      return acc;
    },
    {}
  );
  const badgeMappings = Object.values(badgeMappingById);

  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  const clickedFeature = useDynamicMapStore((state) => state.clickedPOiFeature);
  const matchedBrand = useDynamicMapStore((state) => state.matchedBrand);
  const setMatchedBrand = useDynamicMapStore((state) => state.setMatchedBrand);
  const {
    showTradeAreaHeatmap,
    tradeAreaDataIsLoading,
    showTradeAreaOverlapHeatmap,
    tradeAreaOverlapDataIsLoading,
  } = useDemographicStore((state) => ({
    showTradeAreaHeatmap: state.showTradeAreaHeatmap,
    tradeAreaDataIsLoading: state.tradeAreaDataIsLoading,
    showTradeAreaOverlapHeatmap: state.showTradeOverlapAreaHeatmap,
    tradeAreaOverlapDataIsLoading: state.tradeAreaOverlapDataIsLoading,
  }));

  const showHeatmap = showTradeAreaHeatmap && !tradeAreaDataIsLoading;
  const showOverlapHeatmap =
    showTradeAreaOverlapHeatmap && !tradeAreaOverlapDataIsLoading;

  const hasPermission = usePermissionCheck(Permissions.READ_MOBILE_DATA);

  useEffect(() => {
    if (!clickedFeature) {
      setMatchedBrand(null);
      return;
    }

    const foundBrand = selectedBrands.find(
      (brandOrPlacesResult) =>
        ('id' in brandOrPlacesResult &&
          clickedFeature.properties?.brandId1 === brandOrPlacesResult.id) ||
        ('placeIds' in brandOrPlacesResult &&
          brandOrPlacesResult.placeIds.includes(
            clickedFeature.properties?.id
          )) ||
        ('localFilter' in brandOrPlacesResult &&
          clickedFeature.layer?.metadata?.placeName ===
            brandOrPlacesResult.name)
    );

    if (!foundBrand) {
      setMatchedBrand(null);
      return;
    }

    if ('id' in foundBrand && 'naicsCode' in foundBrand) {
      setMatchedBrand(foundBrand);
      return;
    }

    if ('placeIds' in foundBrand) {
      setMatchedBrand({
        id: foundBrand.id,
        name: foundBrand.name,
        pinImage: foundBrand.pinImage,
        naicsCode: '',
        parentId: '',
        website: '',
        group: '',
      });

      return;
    }

    if ('localFilter' in foundBrand) {
      setMatchedBrand({
        id: foundBrand.id,
        name: foundBrand.name,
        pinImage: foundBrand.pinImage,
        naicsCode: '',
        parentId: '',
        website: '',
        group: '',
      });
    }
  }, [clickedFeature, selectedBrands, setMatchedBrand]);

  return (
    <>
      <Source
        id="poi-places"
        type="vector"
        url="mapbox://luketruitt1.pg-poi-places"
      >
        {selectedBrands.map((brandOrPlacesResult) =>
          'placeIds' in brandOrPlacesResult ? (
            <POIPlacesPinLayer
              placesResult={brandOrPlacesResult}
              key={brandOrPlacesResult.id}
            />
          ) : 'localFilter' in brandOrPlacesResult ? (
            <POILocalFilterLayer
              placesResult={brandOrPlacesResult}
              key={brandOrPlacesResult.id}
            />
          ) : (
            <POIBrandPinLayer
              brandResult={brandOrPlacesResult}
              key={brandOrPlacesResult.id}
            />
          )
        )}
        {badgeMappings.map((badgeMapping) =>
          'placeIds' in badgeMapping ? (
            <POIPlacesBadgeLayer
              placeIds={badgeMapping.placeIds}
              badge={badgeMapping.badge}
              key={`places-badge-${badgeMapping.badge.id}`}
            />
          ) : (
            <POIBrandBadgeLayer
              brandIds={badgeMapping.brandIds}
              badge={badgeMapping.badge}
              key={`brands-badge-${badgeMapping.badge.id}`}
            />
          )
        )}
      </Source>
      {showOverlapHeatmap && <TradeAreaOverlapHeatmapSource />}
      {matchedBrand && clickedFeature && evaluatedDemographicPoint && (
        <>
          {showHeatmap && hasPermission && <TradeAreaHeatmapSource />}
          <POIPulseSourceLayer
            brandId={matchedBrand?.id}
            brandPinImage={matchedBrand?.pinImage}
            brandName={matchedBrand?.name}
          />
        </>
      )}
    </>
  );
}
