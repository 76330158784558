/* eslint-disable @typescript-eslint/no-explicit-any */
import MapboxDraw, { MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data';
import { Feature, Polygon } from 'geojson';
import { EventData, MapLayerEventType } from 'mapbox-gl';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import useRulesets from '../../dynamic-map/hooks/useRulesets';
import {
  isPolygon,
  mapCustomTerritoriesToFeatures,
} from '../helpers/CustomTerritoriesToFeatures';

const CustomDrawnTerritories: React.FC = () => {
  const map = useMapContext();

  const draw = useRef<MapboxDraw | null>(null);

  const [polygonFeatures, setPolygonFeatures] = useState<Feature<Polygon>[]>(
    []
  );
  const [hoveredFeatureId, setHoveredFeatureId] = useState<string | null>(null);

  const { customTerritories, territoriesEnabled } = useCustomTerritories();
  const rulesets = useRulesets();

  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;

  const {
    evaluatedTerritoryId,
    isDrawingTerritory,
    territoryFillEnabled,
    hiddenTerritoryGroups,
    editingTerritoryBoundaryId,
    setIsDrawingTerritory,
    territoryLabelsEnabled,
  } = useDynamicMapStore((state) => ({
    evaluatedTerritoryId: state.evaluatedTerritoryId,
    isDrawingTerritory: state.isDrawingTerritory,
    territoryFillEnabled: state.territoryFillEnabled,
    hiddenTerritoryGroups: state.hiddenTerritoryGroups,
    editingTerritoryBoundaryId: state.editingTerritoryBoundaryId,
    setIsDrawingTerritory: state.setIsDrawingTerritory,
    territoryLabelsEnabled: state.territoryLabelsEnabled,
  }));

  const evaluatedTerritory = customTerritories.find(
    (territory) => territory.id === evaluatedTerritoryId
  );
  const evaluatedBoundaries = evaluatedTerritory?.boundaries;
  const isActive = evaluatedTerritory?.type === TerritoryType.Custom;

  const features = useMemo(
    () =>
      mapCustomTerritoriesToFeatures({
        customTerritories,
        rulesets,
      }),
    [customTerritories, rulesets]
  );

  // Handle the editing of custom drawn territories
  useEffect(() => {
    if (!map || !evaluatedTerritoryId) return;

    if (!draw.current) {
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          trash: true,
        },
        defaultMode: 'draw_polygon',
      });

      if (!map.hasControl(draw.current as any)) {
        map.addControl(draw.current as any, 'bottom-right');
      }
    }

    const filteredFeatures = features?.filter(
      (item) =>
        item?.properties?.territoryId === evaluatedTerritoryId &&
        item?.properties?.boundary?.id === editingTerritoryBoundaryId
    );
    const featureIds = filteredFeatures?.map(
      (item) => item?.id?.toString() ?? ''
    );
    const existingFeatures = draw.current.getAll();
    const notAddedFeatures = filteredFeatures?.filter(
      (item) =>
        !existingFeatures.features.some(
          (existingFeature) => existingFeature.id === item?.id
        )
    );

    if (editingTerritoryBoundaryId) {
      notAddedFeatures?.forEach((feature) => {
        draw?.current?.add(feature!);
      });
      draw?.current?.changeMode('simple_select' as never, {
        featureId: featureIds,
      });
    } else {
      draw?.current?.delete(featureIds);
      if (map.hasControl(draw.current as any)) {
        map.removeControl(draw.current as any);
      }
    }

    return () => {
      if (draw?.current) {
        if (map.hasControl(draw.current as any)) {
          map.removeControl(draw.current as any);
        }
        draw.current = null;
      }
    };
  }, [editingTerritoryBoundaryId, evaluatedTerritoryId, features, map]);

  // Handle the creation and update of custom drawn territories
  // and display the polygons on the map
  useEffect(() => {
    if (!map) return;

    if (!draw.current && isDrawingTerritory && isActive) {
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {},
        defaultMode: 'draw_polygon',
      });
      if (!map.hasControl(draw.current as any)) {
        map.addControl(draw.current as any);
      }
    }

    setPolygonFeatures(features);

    // Handle creation or update of drawings
    const handleDrawCreateOrUpdate = (e: MapLayerEventType & EventData) => {
      if (!draw.current) return;
      const isCreateEvent = e?.type === 'draw.create';
      const isEditEvent = e?.type === 'draw.update';

      if (isEditEvent && !editingTerritoryBoundaryId) return;

      const allFeatures = draw.current.getAll();

      if (!allFeatures.features.length) return;

      const feature = (() => {
        const lastFeature =
          allFeatures.features[allFeatures.features.length - 1];

        if (isCreateEvent) {
          return lastFeature;
        }
        if (isEditEvent) {
          return allFeatures?.features?.find(
            (item) => item?.id === e?.features?.[0]?.id
          );
        }

        return lastFeature;
      })();

      if (isPolygon(feature!)) {
        const coordinates = feature.geometry.coordinates[0];
        if (evaluatedTerritoryId) {
          const territory = customTerritories.find(
            (t) => t.id === evaluatedTerritoryId
          );

          const boundaryId = isEditEvent
            ? (editingTerritoryBoundaryId as string)
            : `Custom-${territory?.boundaries ? Object.keys(territory?.boundaries).length + 1 : 1}`;

          customTerritoryMethods?.addOrUpdateBoundary({
            territoryId: evaluatedTerritoryId,
            boundaryId,
            polygonData: coordinates,
          });
        }
        setPolygonFeatures([feature]);
      }

      if (
        isCreateEvent &&
        isDrawingTerritory &&
        evaluatedTerritory?.type === TerritoryType.Custom
      ) {
        setIsDrawingTerritory(false);
      }
    };

    // Attach the event listeners to MapboxDraw
    map.on('draw.create', handleDrawCreateOrUpdate);
    map.on('draw.update', handleDrawCreateOrUpdate);
    map.on('draw.delete', handleDrawCreateOrUpdate);

    return () => {
      // Cleanup
      map.off('draw.create', handleDrawCreateOrUpdate);
      map.off('draw.update', handleDrawCreateOrUpdate);
      map.off('draw.delete', handleDrawCreateOrUpdate);
      if (draw.current) {
        if (map.hasControl(draw.current as any)) {
          map.removeControl(draw.current as any);
        }
        draw.current = null;
      }
    };
  }, [
    map,
    isDrawingTerritory,
    isActive,
    evaluatedTerritoryId,
    customTerritoryMethods,
    evaluatedBoundaries,
    customTerritories,
    rulesets,
    features,
    editingTerritoryBoundaryId,
    setIsDrawingTerritory,
    evaluatedTerritory?.type,
  ]);

  // Additional useEffect for hover effect
  useEffect(() => {
    if (!map || polygonFeatures.length === 0 || !territoriesEnabled) return;

    const handleMouseMove = (e: MapMouseEvent) => {
      const query = map.queryRenderedFeatures([e.point.x, e.point.y], {
        layers: ['custom-drawn-territories-fill'],
      });
      const feature = query?.[0];

      if (feature != null) {
        const featureId = feature.id;
        if (featureId && featureId !== hoveredFeatureId) {
          if (hoveredFeatureId) {
            map.setFeatureState(
              { source: 'custom-drawn-territories', id: hoveredFeatureId },
              { hover: false }
            );
          }
          setHoveredFeatureId(featureId.toString());
          map.setFeatureState(
            { source: 'custom-drawn-territories', id: featureId },
            { hover: true }
          );
        }
      } else {
        if (hoveredFeatureId) {
          map.setFeatureState(
            { source: 'custom-drawn-territories', id: hoveredFeatureId },
            { hover: false }
          );
          setHoveredFeatureId(null);
        }
      }
    };

    const handleMouseLeave = () => {
      if (hoveredFeatureId) {
        map.setFeatureState(
          { source: 'custom-drawn-territories', id: hoveredFeatureId },
          { hover: false }
        );
        setHoveredFeatureId(null);
      }
    };

    map.on('mousemove', handleMouseMove);
    map.on('mouseleave', handleMouseLeave);

    return () => {
      map.off('mousemove', handleMouseMove);
      map.off('mouseleave', handleMouseLeave);
    };
  }, [map, hoveredFeatureId, polygonFeatures.length, territoriesEnabled]);

  // Render the polygons as multiple layers
  return (
    <>
      {polygonFeatures.length > 0 && territoriesEnabled && (
        <Source
          id="custom-drawn-territories"
          type="geojson"
          data={{
            type: 'FeatureCollection',
            features: polygonFeatures.filter(
              (feature) =>
                feature.properties &&
                !hiddenTerritoryGroups.includes(feature.properties.group)
            ),
          }}
        >
          <Layer
            id="custom-drawn-territories-label"
            type="symbol"
            source="custom-drawn-territories"
            minzoom={1}
            layout={{
              visibility:
                territoriesEnabled && territoryLabelsEnabled
                  ? 'visible'
                  : 'none',
              'text-field': ['get', 'label'],
              'text-font': ['Arial Unicode MS Regular'],
              'text-size': ['interpolate', ['linear'], ['zoom'], 8, 16, 10, 20],
              'text-anchor': 'center',
              'text-offset': [0, 0.5],
              'text-allow-overlap': true,
              'text-ignore-placement': true,
            }}
            paint={{
              'text-color': 'black',
              'text-halo-color': 'rgba(255, 255, 255, 0.5)',
              'text-halo-width': 2,
              'text-opacity': ['case', ['get', 'hidden'], 0, 1],
            }}
          />
          <Layer
            id="custom-drawn-territories-fill"
            type="fill"
            source="custom-drawn-territories"
            paint={{
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                ['get', 'hoverColor'], // Use hover color when hovered
                ['get', 'color'], // Normal color otherwise
              ],
              'fill-opacity': 0.4,
            }}
            layout={{
              visibility: territoryFillEnabled ? 'visible' : 'none',
            }}
            beforeId={
              territoryLabelsEnabled
                ? 'custom-drawn-territories-label'
                : undefined
            }
          />
          <Layer
            id="custom-drawn-territories-line"
            type="line"
            source="custom-drawn-territories"
            paint={{
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                ['get', 'hoverColor'], // Use hover color when hovered
                ['get', 'color'], // Normal color otherwise
              ],
              'line-width': 2,
            }}
            layout={{
              visibility: 'visible',
            }}
          />
        </Source>
      )}
    </>
  );
};

export default CustomDrawnTerritories;
