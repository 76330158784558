import { Box } from '@mui/material';
import { useMemo } from 'react';
import PinCard from '~/src/features/custom-pins/pin-cards/PinCard';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useCustomPins from '~/src/global/hooks/useCustomPins';

const GroupCustomPinsMenu = () => {
  const { customPins } = useCustomPins();
  const selectedPinGroup = useDynamicMapStore(
    (state) => state.selectedPinGroup
  );

  const sortedPinsInGroup = useMemo(() => {
    return customPins
      .filter((pin) => pin.group === selectedPinGroup)
      .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
  }, [customPins, selectedPinGroup]);

  return (
    <Box sx={{ overflowY: 'auto' }}>
      {sortedPinsInGroup.map((pin) => (
        <PinCard key={pin.id} pin={pin} />
      ))}
    </Box>
  );
};

export default GroupCustomPinsMenu;
