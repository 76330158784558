import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { EditPinSchema } from '../schema';
import { Control, Controller } from 'react-hook-form';
import { CustomPin } from '@plotr/plotr-multiplayer-data/src';
import { DEFAULT_PIN_GROUP_NAME } from '~/src/constants';

type PinGroupProps = {
  pin: CustomPin;
  pinGroups: string[];
  control: Control<EditPinSchema>;
  isEditing: boolean;
};

export const PinGroup: FC<PinGroupProps> = ({
  pin,
  pinGroups,
  control,
  isEditing,
}) => {
  const [pinGroupInput, setPinGroupInput] = useState(
    pin.group || DEFAULT_PIN_GROUP_NAME
  );

  return (
    <Controller
      name="pinGroup"
      control={control}
      render={({ field: { value, onChange } }) =>
        isEditing ? (
          <Autocomplete
            id="pin-group"
            freeSolo
            disabled={!isEditing}
            options={pinGroups}
            value={value}
            inputValue={pinGroupInput}
            autoSelect
            onInputChange={(_, value) => setPinGroupInput(value)}
            onChange={(_, value) => onChange(value)}
            onBlur={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps }}
                variant="outlined"
                size="small"
              />
            )}
          />
        ) : (
          <Box display="flex" alignItems="end" height="100%">
            <Typography variant="body2">{value}</Typography>
          </Box>
        )
      }
    />
  );
};
