import {
  LayerConfig,
  LayerType,
} from '../../features/dynamic-map-controller/components/MapLayersMenu/types';
import { Color } from './colors';
function generateQuarters(startDate: Date, endDate: Date): string[] {
  const quarters = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const quarter = Math.floor(currentDate.getMonth() / 3) + 1;
    quarters.unshift(`${year}_Q${quarter}`);
    currentDate.setMonth(currentDate.getMonth() + 3);
  }

  return quarters;
}

const quarters = generateQuarters(new Date(2007, 0, 1), new Date(2024, 6, 1));

const housingAffordabilityLayers: Record<string, LayerConfig> = quarters.reduce(
  (acc, quarter) => {
    const [year, q] = quarter.split('_');

    acc[`bg_housing_affordability_${year}_${q}`] = {
      type: LayerType.Insights,
      featureType: 'polygon',
      displayName: `${year} ${q} - Housing Affordability`,
      mergeGroup: 'bg_housing_affordability',
      category: 'Population.Income',
      groupName: 'demographic_layers',
      class: 'housing',
      sourceTileset: {
        label: 'Block Group Insights',
        source: 'luketruitt1.bg_housing_affordability',
        source_layer: 'bg_housing_affordability',
      },
      styleConfig: {
        threshold: [0, 1, 2.5, 5, 7.5, 10, 15],
        colors: [
          Color.darkGreen,
          Color.darkGreen,
          Color.lightGreen,
          Color.yellow,
          Color.orange,
          Color.red,
          Color.darkRed,
        ],
        fallback: 0,
        expression: [
          'case',
          [
            'all',
            ['has', `income_${quarter}`],
            ['has', `homeValue_${quarter}`],
            ['>', ['get', `income_${quarter}`], 0],
          ],
          ['/', ['get', `homeValue_${quarter}`], ['get', `income_${quarter}`]],
          0,
        ],
        opacityStops: [0, 1, 1, 1, 1, 1],
        unit: 'ratio',
        hideBoundValues: true,
        boundLabels: ['More affordable', 'Less affordable'],
      },
      dataConfig: {
        header: 'geoid',
        fields: [
          {
            'Average Household Income': {
              key: `income_${quarter}`,
              format: 'dollars',
            },
          },
          {
            'Average Home Value': {
              key: `homeValue_${quarter}`,
              format: 'dollars',
            },
          },
          {
            'Housing Affordability Ratio': {
              expression: [
                'case',
                [
                  'all',
                  ['has', `income_${quarter}`],
                  ['has', `homeValue_${quarter}`],
                  ['>', ['get', `income_${quarter}`], 0],
                ],
                [
                  '/',
                  ['get', `homeValue_${quarter}`],
                  ['get', `income_${quarter}`],
                ],
                0,
              ],
              format: 'ratio',
            },
          },
        ],
      },
    };

    return acc;
  },
  {} as Record<string, LayerConfig>
);

export default housingAffordabilityLayers;
