import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import {
  CustomTerritory,
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useCustomTerritories from '../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import EditEntityDialog from './EditEntityDialog';

function containsNonAscii(str: string): boolean {
  return /[\u0080-\uFFFF]/.test(str);
}

interface AddEditTerritoryDialogProps {
  open: boolean;
  territory: CustomTerritory | null;
  mode: 'add' | 'edit';
  onClose: () => void;
  onSave: (updatedTerritory: CustomTerritory) => void;
  group: string;
}

const defaultGroupName = 'Default Group';

export default function AddEditTerritoryDialog({
  open,
  territory,
  mode,
  onClose,
  onSave,
  group,
}: AddEditTerritoryDialogProps) {
  const { customTerritories } = useCustomTerritories();
  const territoryMethods = plotrMultiplayerData.methods?.territories;

  const territoryGroups = useMemo(
    () => [...new Set(customTerritories.map((t) => t.group))],
    [customTerritories]
  );

  const [territoryDisplayName, setTerritoryDisplayName] = useState<string>('');
  const [territoryGroupInput, setTerritoryGroupInput] = useState<string>('');
  const [territoryType, setTerritoryType] = useState<TerritoryType | ''>(
    territory?.type || ''
  );
  const [isTypeSet, setIsTypeSet] = useState<boolean>(!!territory?.type);

  const [error, setError] = useState<string | null>(null);

  const setEvaluatedTerritoryId = useDynamicMapStore(
    (state) => state.setEvaluatedTerritoryId
  );

  useEffect(() => {
    if (territory && mode === 'edit') {
      setTerritoryDisplayName(territory.label);
    }
    setTerritoryGroupInput(territory?.group || defaultGroupName);
  }, [territory, open, mode]);

  const validateTerritoryName = useCallback(
    (name: string): string | null => {
      if (!name.trim()) return 'Territory name cannot be empty.';
      if (name.length > 50)
        return 'Territory name must be 50 characters or less.';
      if (containsNonAscii(name))
        return 'Territory name cannot contain non-ASCII characters.';
      const doesNameExist = customTerritories.some(
        (territory: CustomTerritory) => territory.label === territoryDisplayName
      );
      if (doesNameExist)
        return 'A territory with this name already exists. Please choose a different name.';
      return null;
    },
    [customTerritories, territoryDisplayName]
  );

  const clearState = () => {
    setError(null);
    setTerritoryDisplayName('');
    setTerritoryGroupInput(group || defaultGroupName);
  };

  const handleSave = () => {
    if (territory == null) {
      console.error('territory is null');
      return;
    }

    if (territoryDisplayName !== territory.label) {
      const nameError = validateTerritoryName(territoryDisplayName);
      if (nameError != null) {
        setError(nameError);
        return;
      }
    }

    if (!territoryType) {
      setError('Please select a valid territory type.');
      return;
    }

    onSave({
      ...territory,
      label: territoryDisplayName,
      group: territoryGroupInput,
      type: territoryType,
    });
    setIsTypeSet(true);
    clearState();
  };

  useEffect(() => {
    if (open) {
      // Check if dialog is open to ensure fresh state setup each time it opens.
      if (territory && mode === 'edit') {
        setTerritoryType(territory.type!); // Set to existing territory's type if editing
        setIsTypeSet(!!territory.type);
      } else {
        setTerritoryType(''); // Reset to empty string when adding a new territory
        setIsTypeSet(false);
      }
    }
  }, [territory, mode, open]); // Depend on 'open' to reset the state each time dialog opens or closes

  const handleClose = () => {
    clearState();
    onClose();
  };

  const handleDelete = () => {
    if (territoryMethods != null && territory != null) {
      territoryMethods.removeTerritory(territory.id);
      setEvaluatedTerritoryId(null);
    }
  };

  return territory != null &&
    territoryMethods != null &&
    territoryGroups != null ? (
    <EditEntityDialog
      isOpen={open}
      isDeletable={mode === 'edit'}
      dialogTitle={`${mode.charAt(0).toUpperCase() + mode.slice(1)} Territory Details`}
      entityName={territory.label}
      inputError={error ?? undefined}
      saveDisabled={!territoryDisplayName || !territoryGroupInput}
      onSave={handleSave}
      onClose={handleClose}
      onDelete={handleDelete}
    >
      <TextField
        label="Territory Display Name"
        value={territoryDisplayName}
        onChange={(e) => {
          setTerritoryDisplayName(e.target.value);
          setError(null);
        }}
        fullWidth
        margin="dense"
        variant="outlined"
      />
      <Autocomplete
        id="territory-group"
        fullWidth
        freeSolo
        options={territoryGroups}
        inputValue={territoryGroupInput}
        autoSelect
        onInputChange={(_e, value) => setTerritoryGroupInput(value)}
        onChange={() => {
          if (territoryGroupInput.length > 0) {
            setTerritoryGroupInput(territoryGroupInput);
          } else {
            setTerritoryGroupInput(defaultGroupName);
          }
        }}
        onBlur={() => {
          if (territoryGroupInput.length > 0) {
            setTerritoryGroupInput(territoryGroupInput);
          } else {
            setTerritoryGroupInput(defaultGroupName);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps }}
            label="Territory Group"
            variant="outlined"
            margin="normal"
          />
        )}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="territory-type-label">Territory Type</InputLabel>
        <Select
          labelId="territory-type-label"
          value={territoryType || ''}
          onChange={(e: SelectChangeEvent) =>
            setTerritoryType(e.target.value as TerritoryType)
          }
          label="Territory Type"
          disabled={isTypeSet}
        >
          {Object.values(TerritoryType).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </EditEntityDialog>
  ) : null;
}
