import { useCallback, useEffect, useState } from 'react';

type OverflowState = {
  isOverflowing: boolean;
  isAtTop: boolean;
  isAtBottom: boolean;
};

export function useOverflowingContainer() {
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [overflowState, setOverflowState] = useState<OverflowState>({
    isOverflowing: false,
    isAtTop: true,
    isAtBottom: false,
  });

  const checkOverflow = useCallback((containerElement: HTMLDivElement) => {
    const isOverflowing =
      containerElement.scrollHeight > containerElement.clientHeight;
    const isAtTop = containerElement.scrollTop === 0;
    const isAtBottom =
      containerElement.scrollHeight - containerElement.scrollTop ===
      containerElement.clientHeight;

    setOverflowState({
      isOverflowing,
      isAtTop,
      isAtBottom,
    });
  }, []);

  useEffect(() => {
    if (!containerElement) return;

    const callCheckOverflow = () => checkOverflow(containerElement);

    callCheckOverflow();
    containerElement.addEventListener('scroll', callCheckOverflow);
    const observer = new MutationObserver(callCheckOverflow);

    observer.observe(containerElement, {
      childList: true,
    });

    return () => {
      containerElement.removeEventListener('scroll', callCheckOverflow);
      observer.disconnect();
    };
  }, [containerElement, checkOverflow]);

  return {
    ref: setContainerElement,
    ...overflowState,
  };
}
