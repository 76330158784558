import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';

const env = parseEnv({ API_V2: process.env.API_V2 });

// Fetch pricing data from specific folder
export async function fetchPricingDataFromFolder(
  client: string,
  folder: string
): Promise<FeatureCollection<Geometry, GeoJsonProperties>> {
  const response = await axios.get<
    FeatureCollection<Geometry, GeoJsonProperties>
  >(`${env.API_V2}/client/${client}/pricing_${folder}/latest`);

  if (response.status !== 200) {
    throw new Error(`Error fetching pricing data: ${response.status}`);
  }
  return response.data;
}

// Fetch all pricing data types
export async function fetchAllPricingData(
  client: string
): Promise<FeatureCollection<Geometry, GeoJsonProperties>[]> {
  const folders = ['franchises', 'sampleset', 'taid', 'territories'];
  try {
    const pricingData = await Promise.all(
      folders.map((folder) => fetchPricingDataFromFolder(client, folder))
    );

    return pricingData;
  } catch (error) {
    console.error('Error fetching pricing data:', error);
    throw error;
  }
}
