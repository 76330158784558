import { CustomPin, YCustomPin } from '@plotr/plotr-multiplayer-data';
import { useMultiplayer } from '@plotr/react-multiplayer';
import { useMemo } from 'react';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

export default function useCustomPins() {
  const { hiddenPinGroups, toggleAllPinGroupsVisibility } = useDynamicMapStore(
    (state) => state
  );

  const customPins = useMultiplayer.State<CustomPin[]>((ydoc) => {
    const pins = ydoc?.getArray<YCustomPin>('customPins')?.toArray() ?? [];
    return pins.map((pin) => pin.toJSON() as CustomPin);
  });

  const pinGroups = useMemo(() => {
    const groups = new Map<string, number>();
    customPins.forEach((pin) => {
      groups.set(pin.group, (groups.get(pin.group) || 0) + 1);
    });
    return Array.from(groups.entries()).sort((a, b) => a[0].localeCompare(b[0]));
  }, [customPins]);

  const pinsEnabled = pinGroups.length > hiddenPinGroups.length;

  const toggleAllPinsVisibility = () =>
    toggleAllPinGroupsVisibility(pinGroups.map(([group]) => group));

  return {
    customPins,
    pinGroups,
    pinsEnabled,
    toggleAllPinsVisibility,
  };
}
