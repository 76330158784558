import { Box, Card, CardContent, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Layer, MapMouseEvent, Source } from 'react-map-gl';
import { formatPercentage } from '~/src/common/helpers/formatPercentage';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useLayerIds from '~/src/features/dynamic-map/hooks/useLayerIds';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { OverlapPerPin } from './OverlapPerPin';

type NonOverlapHoverInfo = {
  type: 'non-overlap';
  geoid: string;
  evaluatedPinVisits: number;
};

type OverlapHoverInfo = Omit<NonOverlapHoverInfo, 'type'> & {
  type: 'overlap';
  totalOverlapPercentage: number;
  overlapPerPin: {
    label: string;
    value: { percentage: number; visits: number };
  }[];
};

export const OVERLAP_COLORS = [
  '#3473ff',
  '#3447ff',
  '#5034ff',
  '#6734ff',
  '#9734ff',
];
export const NON_OVERLAP_COLOR = '#C23434';

const TradeAreaOverlapHeatmapSource: React.FC = () => {
  const { customPins } = useCustomPins();
  const { evaluatedDemographicEntity, expandedMobileDataAccordion } =
    useDynamicMapStore((state) => ({
      evaluatedDemographicEntity: state.evaluatedDemographicEntity,
      expandedMobileDataAccordion: state.expandedMobileDataAccordion,
    }));
  const {
    tradeAreaOverlapData,
    tradeAreaOverlapDataIsLoading,
    showTradeAreaOverlapHeatmap,
    setShowTradeAreaOverlapHeatmap,
    showTradeAreaOverlapHeatmapData,
  } = useDemographicStore((state) => ({
    tradeAreaOverlapData: state.tradeAreaOverlapData,
    tradeAreaOverlapDataIsLoading: state.tradeAreaOverlapDataIsLoading,
    showTradeAreaOverlapHeatmap: state.showTradeAreaOverlapHeatmap,
    setShowTradeAreaOverlapHeatmap: state.setShowTradeAreaOverlapHeatmap,
    showTradeAreaOverlapHeatmapData: state.showTradeAreaOverlapHeatmapData,
  }));

  const [hasOverlapData, hasNonOverlapData] = useMemo(
    () => [
      Boolean(
        Object.values(tradeAreaOverlapData?.overlapPerLocationId || {}).length
      ),
      Boolean(
        Object.keys(tradeAreaOverlapData?.nonOverlappingLocationsVisits || {})
          .length
      ),
    ],
    [tradeAreaOverlapData]
  );

  const pinIdNameMap = useMemo(() => {
    return customPins.reduce(
      (acc, pin) => {
        acc[pin.id] = pin.label;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [customPins]);

  const roadLayerId = useLayerIds((layer) =>
    layer.id.startsWith('road-label')
  )[0];

  const map = useMapContext();

  const [hoverInfo, setHoverInfo] = useState<
    OverlapHoverInfo | NonOverlapHoverInfo | null
  >(null);

  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Memoized values to optimize re-renders
  const overlapValues = useMemo(() => {
    if (tradeAreaOverlapData === null) {
      return [];
    }
    return tradeAreaOverlapData?.overlapPerLocationId
      ? Object.values(tradeAreaOverlapData.overlapPerLocationId).map(
          (location) => location.totalOverlapPercentage
        )
      : [];
  }, [tradeAreaOverlapData, setShowTradeAreaOverlapHeatmap]);

  const overlapMinValue = useMemo(
    () => Math.min(...overlapValues, 0),
    [overlapValues]
  );
  const overlapMaxValue = useMemo(
    () => Math.max(...overlapValues, 0.0025),
    [overlapValues]
  );

  const calculatedOverlapColorStops = useMemo(
    () =>
      OVERLAP_COLORS.map((color, index) => [
        overlapMinValue +
          (overlapMaxValue - overlapMinValue) *
            (index / (OVERLAP_COLORS.length - 1)),
        color,
      ]),
    [overlapMinValue, overlapMaxValue]
  );

  const { cleanedTradeAreaOverlapData, cleanedNonOverlappingLocationIds } =
    useMemo(() => {
      const cleanedTradeAreaOverlapData =
        tradeAreaOverlapData?.overlapPerLocationId
          ? Array.from(
              Object.entries(tradeAreaOverlapData.overlapPerLocationId).map(
                ([locationId, { totalOverlapPercentage }]) => [
                  parseInt(locationId),
                  totalOverlapPercentage,
                ]
              )
            ).flat()
          : [];

      const cleanedNonOverlappingLocationIds = Object.keys(
        tradeAreaOverlapData?.nonOverlappingLocationsVisits || {}
      ).map((locationId) => parseInt(locationId));

      return {
        cleanedTradeAreaOverlapData,
        cleanedNonOverlappingLocationIds,
      };
    }, [tradeAreaOverlapData]);

  // Conditions to determine rendering
  const shouldRender = useMemo(
    () =>
      showTradeAreaOverlapHeatmap &&
      !tradeAreaOverlapDataIsLoading &&
      evaluatedDemographicEntity?.type === 'point' &&
      tradeAreaOverlapData &&
      expandedMobileDataAccordion === 'mobileTradeAreaOverlap' &&
      (hasOverlapData || hasNonOverlapData),
    [
      showTradeAreaOverlapHeatmap,
      tradeAreaOverlapDataIsLoading,
      evaluatedDemographicEntity,
      tradeAreaOverlapData,
      expandedMobileDataAccordion,
      hasOverlapData,
      hasNonOverlapData,
    ]
  );

  useEffect(() => {
    if (
      !shouldRender ||
      !map ||
      expandedMobileDataAccordion !== 'mobileTradeAreaOverlap' ||
      !showTradeAreaOverlapHeatmap
    )
      return;

    const onMouseMove = (e: MapMouseEvent) => {
      const layers: string[] = [];

      if (hasOverlapData) {
        layers.push(
          'block-group-overlap-heatmap-layer',
          'block-group-overlap-outline-layer'
        );
      }
      if (hasNonOverlapData) {
        layers.push(
          'block-group-non-overlapping-heatmap-layer',
          'block-group-non-overlapping-outline-layer'
        );
      }

      const features = map.queryRenderedFeatures(e.point, {
        layers,
      });

      const relevantFeature = features.find((feature) =>
        layers.includes(feature.layer.id)
      );

      const geoid = relevantFeature?.id?.toString();

      if (relevantFeature && geoid) {
        setCursorPosition({ x: e.point.x, y: e.point.y });

        const locationOverlapData =
          tradeAreaOverlapData?.overlapPerLocationId[geoid];

        const locationNonOverlapData =
          tradeAreaOverlapData?.nonOverlappingLocationsVisits[geoid];

        if (locationNonOverlapData) {
          const _hoverInfo: NonOverlapHoverInfo = {
            type: 'non-overlap',
            geoid,
            evaluatedPinVisits: locationNonOverlapData,
          };

          setHoverInfo(_hoverInfo);
          return;
        }

        if (locationOverlapData) {
          const _hoverInfo: OverlapHoverInfo = {
            type: 'overlap',
            geoid,
            evaluatedPinVisits: locationOverlapData.evaluatedVisits,
            totalOverlapPercentage: locationOverlapData.totalOverlapPercentage,
            overlapPerPin: Object.entries(
              locationOverlapData.overlapPerPinId
            ).map(([pinId, value]) => {
              const pin = customPins.find((p) => p.id === pinId);
              return {
                label: pin?.label ?? pinId,
                value,
              };
            }),
          };

          setHoverInfo(_hoverInfo);
          return;
        }

        setHoverInfo(null);
      } else {
        setHoverInfo(null);
      }
    };

    const onMouseOut = () => {
      setHoverInfo(null);
    };

    map.on('mousemove', onMouseMove);
    map.on('mouseout', onMouseOut);

    return () => {
      map.off('mousemove', onMouseMove);
      map.off('mouseout', onMouseOut);
    };
  }, [
    map,
    showTradeAreaOverlapHeatmap,
    tradeAreaOverlapData,
    expandedMobileDataAccordion,
    pinIdNameMap,
    customPins,
  ]);

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <Source
        id="block-group-overlap-source"
        type="vector"
        url="mapbox://luketruitt1.block_group_insights"
      >
        {hasOverlapData && (
          <>
            <Layer
              id="block-group-overlap-heatmap-layer"
              type="fill"
              source="block-group-overlap-source"
              source-layer="block_group_insights"
              paint={{
                'fill-color': [
                  'interpolate',
                  ['linear'],
                  ['match', ['id'], ...cleanedTradeAreaOverlapData, 0],
                  ...calculatedOverlapColorStops.flat(),
                ],
                'fill-opacity': [
                  'case',
                  [
                    '>',
                    ['match', ['id'], ...cleanedTradeAreaOverlapData, 0],
                    0,
                  ],
                  0.5,
                  0,
                ],
                'fill-outline-color': [
                  'interpolate',
                  ['linear'],
                  ['match', ['id'], ...cleanedTradeAreaOverlapData, 0],
                  ...calculatedOverlapColorStops.flat(),
                ],
              }}
              beforeId={roadLayerId ?? undefined}
            />
            <Layer
              id="block-group-overlap-outline-layer"
              type="line"
              source="block-group-overlap-source"
              source-layer="block_group_insights"
              paint={{
                'line-color': 'transparent',
              }}
              beforeId={roadLayerId ?? undefined}
            />
          </>
        )}
        {hasNonOverlapData && (
          <>
            <Layer
              id="block-group-non-overlapping-heatmap-layer"
              type="fill"
              source="block-group-overlap-source"
              source-layer="block_group_insights"
              paint={{
                'fill-color': '#C23434',
                'fill-opacity': 0.3,
                'fill-outline-color': 'transparent',
              }}
              filter={[
                'all',
                [
                  'match',
                  ['id'],
                  cleanedNonOverlappingLocationIds,
                  true,
                  false,
                ],
              ]}
              beforeId={roadLayerId ?? undefined}
            />
            <Layer
              id="block-group-non-overlapping-outline-layer"
              type="line"
              source="block-group-overlap-source"
              source-layer="block_group_insights"
              paint={{
                'line-color': 'transparent',
              }}
              beforeId={roadLayerId ?? undefined}
            />
          </>
        )}
      </Source>

      {showTradeAreaOverlapHeatmapData && hoverInfo && (
        <Box
          position="absolute"
          top={cursorPosition.y + 10}
          left={cursorPosition.x + 10}
          zIndex={1000}
        >
          <Card raised>
            <CardContent>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  alignSelf="center"
                >
                  {hoverInfo.geoid}
                </Typography>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" gap={2} justifyContent="space-between">
                    <Typography variant="body2" fontWeight="bold">
                      Evaluated Pin Visits:
                    </Typography>
                    <Typography variant="body2">
                      {hoverInfo.evaluatedPinVisits}
                    </Typography>
                  </Box>
                  {hoverInfo.type === 'overlap' && (
                    <Box display="flex" gap={2} justifyContent="space-between">
                      <Typography variant="body2" fontWeight="bold">
                        Total Overlap Percentage:
                      </Typography>
                      <Typography variant="body2">
                        {formatPercentage(hoverInfo.totalOverlapPercentage)}
                      </Typography>
                    </Box>
                  )}
                  {hoverInfo.type === 'overlap' && (
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Typography variant="body2" fontWeight="bold">
                        Overlap Per Pin:
                      </Typography>
                      <OverlapPerPin
                        data={hoverInfo.overlapPerPin}
                        style="bold"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default TradeAreaOverlapHeatmapSource;
