import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { memo, MouseEvent, useEffect, useState } from 'react';
import { LockIcon } from '~/src/common/components/LockableButton';
import formatDayTime from '~/src/common/helpers/formatDayTime';
import { customDayTimeTrafficMarks, layerOpacityMarks } from '~/src/constants';
import useLayersStore, {
  LayerCard,
} from '../../../../dynamic-map/hooks/useLayersStore';
import useTrafficStore, {
  TrafficVintages,
} from '../../../../dynamic-map/hooks/useTrafficStore';

const roadTypes = {
  2021: {
    roadTypes: [
      { value: 'primary', label: 'Primary' },
      { value: 'secondary', label: 'Secondary' },
    ],
  },
  2022: {
    roadTypes: [
      { value: 'primary', label: 'Primary' },
      { value: 'secondary', label: 'Secondary' },
      { value: 'tertiary', label: 'Tertiary' },
      { value: 'trunk', label: 'Trunk' },
      { value: 'motorway', label: 'Motorway' },
      { value: 'unclassified', label: 'Unclassified' },
    ],
  },
};

interface SingleLayerCardProps {
  layer: LayerCard;
  onSave: (layerDetails: LayerCard) => boolean;
  onEdit: (layerDetails: LayerCard) => void;
}

const SingleLayerCard = memo((props: SingleLayerCardProps) => {
  const { layer, onSave } = props;
  const mergedLayers = layer.layersList;

  const { layers, updateLayer, deleteLayer } = useLayersStore((state) => state);

  const initiallyActiveMergeLayer =
    layers.find(({ id }) =>
      [mergedLayers?.[0]?.id, mergedLayers?.[1]?.id].includes(id)
    )?.id ||
    mergedLayers?.[0]?.id ||
    '';

  const [layerDetails, setLayerDetails] = useState<LayerCard>(layer);
  const [hidden, setHidden] = useState<boolean>(true);
  const [layerOpacity, setLayerOpacity] = useState<number>(0);
  const [activeMergeLayer, setActiveMergeLayer] = useState<string>(
    initiallyActiveMergeLayer
  );
  const [useCustomDayTime, setUseCustomDayTime] = useState(false);

  const { id, displayName, isLocked } = layerDetails;
  const savedLayer = layers.find(
    ({ id }) => id === (activeMergeLayer || layer.id)
  );

  useEffect(() => {
    const selectedFromMerged = mergedLayers?.find(
      ({ id }) => id === activeMergeLayer
    );

    const currentLayer = savedLayer || selectedFromMerged || layer;
    setLayerDetails(currentLayer);
    setHidden(currentLayer.opacity === 0);
    setLayerOpacity(currentLayer.opacity * 100);
  }, [activeMergeLayer, layer, layers, mergedLayers, savedLayer]);

  const handleBoundaryTypeChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (savedLayer) {
      const connectedLayer =
        mergedLayers?.find(({ id }) => id === value) || savedLayer;
      onSave({
        ...connectedLayer,
        opacity: savedLayer.opacity,
        displayName: displayName.split(' - ')[1] || displayName,
      });
      handleDeleteLayer();
    }
    setActiveMergeLayer(value);
  };

  const trafficEnabled = useTrafficStore((state) => state.trafficEnabled);
  const setDayPart = useTrafficStore((state) => state.setTrafficSelectionHour);
  const selectedRoadType = useTrafficStore((state) => state.selectedLayers);
  const setSelectedRoadType = useTrafficStore(
    (state) => state.setSelectedLayers
  );
  const selectedVintage = useTrafficStore((state) => state.selectedVintage);
  const setSelectedVintage = useTrafficStore(
    (state) => state.setSelectedVintage
  );

  const handleLayerOpacityChange = (newValue: number) => {
    if (!isLocked) setLayerOpacity(newValue);
  };

  const handleLayerOpacityCommit = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    const singleValue = Array.isArray(newValue) ? newValue[0] : newValue;
    const opacityValue = singleValue / 100;

    let hasPermission: boolean = true;
    if (!savedLayer && opacityValue)
      hasPermission = onSave({
        ...layerDetails,
        opacity: opacityValue,
        displayName: mergedLayers ? displayName.split(' - ')[1] : displayName,
      });
    // Save the new layer when the user applies it using either the slider or visibility icon
    else updateLayer(id, { opacity: opacityValue }); // Update the layer opacity here

    if (!hasPermission) return;

    setLayerOpacity(singleValue);
    setHidden(opacityValue === 0);
  };

  // const onLayerEdit = () => {
  //   const targetLayer = { ...layerDetails };
  //   if (mergedLayers)
  //     targetLayer.displayName = displayName.split(' - ')[1] || displayName;

  //   onEdit(targetLayer);
  // };

  const handleToggleClick = (e: MouseEvent<HTMLButtonElement>) =>
    handleLayerOpacityCommit(e, !hidden ? 0 : 100);

  const handleDeleteLayer = () => {
    if (savedLayer?.id) deleteLayer(savedLayer.id);
  };

  useEffect(() => {
    if (useCustomDayTime) setDayPart(12);
    else setDayPart(-1);
  }, [setDayPart, useCustomDayTime]);

  // TODO: Move to the separate component and try to apply the same logic as for other layers
  const TrafficController = () => {
    const trafficSelection = useTrafficStore((state) => state.trafficSelection);
    const setDayType = useTrafficStore((state) => state.setTrafficSelectionDay);
    const setDayPart = useTrafficStore(
      (state) => state.setTrafficSelectionHour
    );

    const handleTrafficOptionChange = (event: {
      target: { value: string };
    }) => {
      const isCustomDayTime = !!Number(event.target.value);
      setUseCustomDayTime(isCustomDayTime);
      if (isCustomDayTime) {
        // Set default values when switching to custom day/time
        setDayType(1); // Example: set to Sunday
        setDayPart(12); // Example: set to noon
      } else {
        // Set to average daily traffic
        setDayType(0); // 0 for average of all days
        setDayPart(-1); // -1 for average of all hours
      }
    };

    return (
      <Box marginTop={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box textAlign="center">
            <Select
              fullWidth
              value={useCustomDayTime ? '1' : '0'}
              onChange={handleTrafficOptionChange}
            >
              <MenuItem value="0">Average Daily Traffic</MenuItem>
              <MenuItem value="1">Custom Day/Time Traffic</MenuItem>
            </Select>
          </Box>
          <Box textAlign="center" display="flex" gap={2}>
            <FormControl fullWidth>
              <InputLabel id="road-types-label">Road Type</InputLabel>
              <Select
                labelId="road-types-label"
                multiple
                defaultValue={roadTypes[selectedVintage].roadTypes.map(
                  ({ value }) => value
                )}
                value={selectedRoadType}
                onChange={(event) => {
                  const value = event.target.value as string[];
                  if (value.length < 1) return;

                  setSelectedRoadType(
                    value.includes('all')
                      ? roadTypes[selectedVintage].roadTypes.map(
                          ({ value }) => value
                        )
                      : value
                  );
                }}
                fullWidth
                label="Road Type"
              >
                <MenuItem value="all">Select All</MenuItem>
                {roadTypes[selectedVintage].roadTypes.map((type) => (
                  <MenuItem value={type.value} key={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="road-types-label">Data Vintage</InputLabel>
              <Select
                labelId="road-types-label"
                value={selectedVintage}
                onChange={(event) =>
                  setSelectedVintage(event.target.value as TrafficVintages)
                }
                fullWidth
                label="Data Vintage"
              >
                {Object.keys(roadTypes).map((vintage) => (
                  <MenuItem key={vintage} value={vintage}>
                    {vintage}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {trafficEnabled && useCustomDayTime && (
          <Box marginTop={2}>
            {/* ToggleButtonGroup with Max Width */}
            <Box flex="1" pr={1} sx={{ maxWidth: 300 }}>
              {/* Adjust the max-width as per your design */}
              <ToggleButtonGroup
                aria-label="days of the week"
                value={trafficSelection.selectedDay}
                exclusive
                fullWidth={false} // Prevent full width
              >
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(
                  (day, index) => (
                    <ToggleButton
                      key={day}
                      value={index + 1}
                      color={
                        trafficSelection?.selectedDay === index
                          ? 'primary'
                          : 'secondary'
                      }
                      onClick={() => {
                        setDayType(index + 1);
                      }}
                    >
                      {day}
                    </ToggleButton>
                  )
                )}
              </ToggleButtonGroup>
            </Box>
            <Slider
              defaultValue={12}
              step={1}
              marks={customDayTimeTrafficMarks}
              min={6}
              max={23}
              onChange={(event, newValue) => setDayPart(newValue as number)}
              sx={{ py: 2 }}
            />
            <Typography variant="body1" textAlign="center" fontWeight={500}>
              {formatDayTime(
                trafficSelection.selectedDay,
                trafficSelection.selectedHour
              )}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        marginTop: 1,
        borderColor: id === savedLayer?.id ? 'secondary.main' : '#0000001f',
      }}
    >
      <Box padding={2}>
        {mergedLayers && (
          <Box margin="-5px 0 10px">
            <Select
              className={`merged-layers-dropdown${mergedLayers?.length > 1 ? ' has-options' : ''}`}
              value={activeMergeLayer}
              onChange={handleBoundaryTypeChange}
              disabled={mergedLayers?.length === 1}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    overflowY: 'auto',
                  },
                },
              }}
            >
              {mergedLayers.map(({ id, displayName }) => {
                return (
                  <MenuItem value={id} key={id}>
                    {displayName.split(' - ')[0].toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1}
        >
          <Typography variant="body1" fontWeight={500}>
            {isLocked && (
              <LockIcon
                active={false}
                styles={{ fontSize: '22px', margin: '-4px 0 0 -4px' }}
              />
            )}{' '}
            {mergedLayers
              ? displayName?.split(' - ')?.[1] || displayName
              : displayName}
          </Typography>
          <Box marginTop="-5px" display="flex">
            {/* Not used for now */}
            {/* <Tooltip title="Edit Layer" placement="top">
              <IconButton onClick={onLayerEdit} size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>*/}
            <Tooltip
              title={id === savedLayer?.id ? 'Remove Layer' : 'Add Layer'}
              placement="top"
            >
              <Checkbox
                checked={id === savedLayer?.id}
                onChange={(event) => {
                  if (event.target.checked) {
                    onSave({ ...layerDetails, opacity: 0.8 });
                  } else {
                    // Delete layer
                    handleDeleteLayer();
                  }
                }}
                size="small"
              />
            </Tooltip>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Tooltip title="Toggle Visibility (0% - 100%)">
            <IconButton
              size="small"
              onClick={handleToggleClick}
              color={!hidden ? 'primary' : 'default'}
              sx={{ marginLeft: -0.5 }}
            >
              {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          <Slider
            value={layerOpacity}
            marks={layerOpacityMarks}
            step={null}
            min={0}
            max={100}
            aria-label="Transparency"
            size="small"
            valueLabelDisplay="auto"
            valueLabelFormat={() => `${layerOpacity}%`}
            onChange={(event, newValue) =>
              handleLayerOpacityChange(newValue as number)
            }
            onChangeCommitted={handleLayerOpacityCommit}
            sx={{ mx: 2 }}
          />
        </Box>
        {id === 'traffic_volume' && <TrafficController />}
        {layer.controllableComponent && <layer.controllableComponent />}
      </Box>
    </Paper>
  );
});
SingleLayerCard.displayName = 'LayerItem';

export default SingleLayerCard;
