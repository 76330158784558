import mapboxgl from 'mapbox-gl';
import { makeReportPinElement } from './makeReportPinElement';
import html2canvas from 'html2canvas';
import { MapRef } from 'react-map-gl';
import { InitialViewState } from '../types/initialViewState';
import { PulseReportConfig } from '~/src/constants';
import { ReportPinProps } from '../ReportPin';

interface GetMapImageProps {
  section: HTMLElement;
  map: MapRef;
  mapInitialViewState?: InitialViewState;
  mapboxApiKey: string;
  mapPins?: ReportPinProps[];
  isFullMap: boolean;
}

const { PDF_MAP_WIDTH, PDF_FULL_MAP_HEIGHT, PDF_MAP_HEIGHT } =
  PulseReportConfig;

export async function getMapImage({
  section,
  map,
  mapInitialViewState,
  mapboxApiKey,
  mapPins,
  isFullMap,
}: GetMapImageProps) {
  const mapContainer = section.querySelector('.mapboxgl-map') as HTMLElement;
  mapContainer.style.maxHeight = 'none';
  mapContainer.style.height = isFullMap
    ? `${PDF_FULL_MAP_HEIGHT}px`
    : `${PDF_MAP_HEIGHT}px`;
  mapContainer.innerHTML = '';

  const mapParent = mapContainer.parentElement as HTMLElement;
  mapParent.style.minHeight = isFullMap
    ? `${PDF_FULL_MAP_HEIGHT}px`
    : `${PDF_MAP_HEIGHT}px`;
  mapParent.style.maxHeight = isFullMap
    ? `${PDF_FULL_MAP_HEIGHT}px`
    : `${PDF_MAP_HEIGHT}px`;
  mapParent.style.width = `${PDF_MAP_WIDTH}px`;

  const hiddenContainer = document.createElement('div');
  hiddenContainer.style.position = 'absolute';
  hiddenContainer.style.top = '-9999px';
  document.body.appendChild(hiddenContainer);

  hiddenContainer.appendChild(section);

  const clonedMap = new mapboxgl.Map({
    container: mapContainer,
    style: map.getStyle(),
    interactive: false,
    accessToken: mapboxApiKey,
    preserveDrawingBuffer: true,
    ...mapInitialViewState,
  });

  await new Promise((resolve) => clonedMap.on('render', resolve));

  const sources = map.getStyle().sources;
  for (const [sourceId, source] of Object.entries(sources)) {
    if (!clonedMap.getSource(sourceId)) {
      clonedMap.addSource(sourceId, source);
    }
  }

  map.getStyle().layers.forEach((layer) => {
    if (!clonedMap.getLayer(layer.id)) {
      clonedMap.addLayer(layer);
    }
  });

  mapPins?.forEach(({ pos: { lat, lng }, ...props }) => {
    const markerEl = makeReportPinElement(props);

    new mapboxgl.Marker({
      element: markerEl,
      anchor: 'bottom',
    })
      .setLngLat([lng, lat])
      .addTo(clonedMap);
  });

  await new Promise((resolve) => clonedMap.on('idle', resolve));

  const mapCanvas = await html2canvas(mapContainer, {
    scale: 2,
    useCORS: true,
    allowTaint: false,
    logging: false,
  });

  const imageUrl = mapCanvas.toDataURL('image/png');
  mapContainer.innerHTML = `<img src="${imageUrl}" style="width: 100%; height: 100%" />`;

  document.body.removeChild(hiddenContainer);
}
