import { Rule, SourceType } from '@plotr/plotr-multiplayer-data/src/lib/rulesets';
import { PIN_SCALE_CONFIG } from '~/src/features/custom-pins/styles/constants';

export function getColor(
  rulesets: Rule[],
  featureProperties: { [key: string]: string[] | string },
  tags: string[]
): string | null {
  const normalizedTags = tags.map((tag) => tag.toLowerCase());

  for (const rule of rulesets) {
    if (
      rule.sourceType === SourceType.KeyValue &&
      rule.propertyKey &&
      featureProperties[rule.propertyKey] === rule.evaluation.value
    ) {
      return rule.effect;
    }

    if (
      rule.sourceType === SourceType.Tag &&
      normalizedTags.includes(
        String(rule.evaluation.value).toLowerCase() as string
      )
    ) {
      return rule.effect;
    }
  }

  return null;
}

export function calculatePinScale(zoom: number, config = PIN_SCALE_CONFIG) {
  if (!zoom) return 1;

  const zoomDiff = zoom - config.ZOOM_STOPS[0];
  let scale;

  if (zoomDiff < 0) {
    scale = config.DEFAULT_ICON_SIZE * config.EXPONENTIAL_ICON_RATIO;
  } else {
    const scaleRange = config.DEFAULT_ICON_SIZE - config.DEFAULT_ICON_SIZE * config.EXPONENTIAL_ICON_RATIO;
    const step = scaleRange / (config.ZOOM_STOPS[1] - config.ZOOM_STOPS[0]);
    scale = config.DEFAULT_ICON_SIZE * config.EXPONENTIAL_ICON_RATIO + step * zoomDiff;
  }

  return Math.min(Math.max(scale, config.MIN_SCALE), config.MAX_SCALE);
}
