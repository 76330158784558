import { Grid } from '@mui/material';
import { useMemo } from 'react';

import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import CreatePulseTerritoryModal from './CreatePulseTerritoryModal';

export const POINT_EVAL_COMMON_STYLE = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '90%',
};

const PointEvalMenu = () => {
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const { customPins } = useCustomPins();

  const evaluatedPin = useMemo(() => {
    return evaluatedPinId == null
      ? null
      : (customPins.find(({ id }) => id === evaluatedPinId) ?? null);
  }, [customPins, evaluatedPinId]);

  return (
    <Grid
      container
      spacing={2}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sx={POINT_EVAL_COMMON_STYLE}>
        <CreatePulseTerritoryModal selectedPin={evaluatedPin} />
      </Grid>
    </Grid>
  );
};

export default PointEvalMenu;
