import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import { CustomPin, CustomTerritory } from '@plotr/plotr-multiplayer-data/src';
import { FC, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { getDemographicEntity } from '~/src/features/context-menu/helpers/getDemographicEntity';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import { EditPinSchema } from '../schema';

interface PinTerritoriesProps {
  pin: CustomPin;
  control: Control<EditPinSchema>;
  isEditing: boolean;
}

export const PinTerritories: FC<PinTerritoriesProps> = ({
  pin,
  control,
  isEditing,
}) => {
  const [territoriesInput, setTerritoriesInput] = useState('');

  const { customTerritories } = useCustomTerritories();
  const map = useMapContext();

  const pinTerritories = useMemo<CustomTerritory[]>(
    () =>
      pin
        ? customTerritories.filter((territory) =>
            territory.pins?.includes(pin.id)
          )
        : [],
    [customTerritories, pin]
  );

  const recommendedTerritories = useMemo(() => {
    if (map && pin?.pos) {
      const convertedPos = map?.project(pin.pos);
      const features = map.queryRenderedFeatures(convertedPos, {});

      const territoriesUnderPin: CustomTerritory[] = [];
      features.forEach((curValue) => {
        const { territory } = getDemographicEntity(curValue, customTerritories);
        if (territory) territoriesUnderPin.push(territory);
      });

      return territoriesUnderPin.filter(
        (territory, index, self) =>
          !pinTerritories.includes(territory) &&
          self.findIndex((t) => t.id === territory.id) === index
      );
    }

    return [];
  }, [customTerritories, map, pin?.pos, pinTerritories]);

  return (
    <Controller
      name="pinTerritories"
      control={control}
      render={({ field: { value, onChange } }) =>
        isEditing ? (
          <Box>
            <Autocomplete
              disabled={!isEditing}
              multiple
              disableCloseOnSelect
              id="pin-territories"
              options={customTerritories}
              value={value || []}
              onChange={(_, value) => onChange(value)}
              inputValue={territoriesInput}
              onInputChange={(_e, value) => setTerritoriesInput(value)}
              getOptionLabel={(option) => option.label}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pin Territories"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index: number) => {
                  const label = option?.label ?? '';
                  return (
                    <Chip
                      label={label}
                      {...getTagProps({ index })}
                      key={option?.id}
                      color="secondary"
                      variant="filled"
                      size="small"
                    />
                  );
                })
              }
            />
            {recommendedTerritories &&
              recommendedTerritories.filter(
                (recommended) =>
                  !value.find((territory) => recommended.id === territory.id)
              ).length > 0 && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  flexWrap={'wrap'}
                  gap={0.5}
                  mt={1}
                >
                  <Typography
                    variant="body2"
                    component={'p'}
                    color={isEditing ? 'textPrimary' : 'textSecondary'}
                  >
                    Recommended:{' '}
                  </Typography>
                  {recommendedTerritories
                    .filter(
                      (recommended) =>
                        !value.find(
                          (territory) => recommended.id === territory.id
                        )
                    )
                    .map((territory) => (
                      <Chip
                        disabled={!isEditing}
                        key={territory.id}
                        label={territory.label}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onDelete={() => onChange([...(value ?? []), territory])}
                        onClick={() => onChange([...(value ?? []), territory])}
                        deleteIcon={<AddIcon />}
                      />
                    ))}
                </Box>
              )}
          </Box>
        ) : value.length ? (
          <Box display="flex" height="100%" gap={1} flexWrap="wrap">
            {value.map((option) => {
              const label = option?.label ?? '';

              return (
                <Chip
                  label={label}
                  key={option?.id}
                  color="secondary"
                  variant="filled"
                  size="small"
                />
              );
            })}
          </Box>
        ) : (
          <Typography variant="body2" color="gray" sx={{ pt: 0.2 }}>
            No value
          </Typography>
        )
      }
    />
  );
};
