import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { citylifeLocalBusinessCodes } from '~/src/global/constants/cityLifestyleBusinessCodes';
import { Boundary } from '../hooks/useDemographicStore';
import { DemographicData } from './demographicSearch';

const env = parseEnv({ PLOTR_API: process.env.PLOTR_API });

export async function fetchLocalBusinessCounts(
  boundary: Boundary,
  accessToken: string
): Promise<DemographicData | null> {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.post(
      `${env.PLOTR_API}/poi-place-search-boundary`,
      {
        industries: Object.keys(citylifeLocalBusinessCodes),
        returnData: false,
        boundary: boundary.geometry,
      },
      { headers }
    );

    const additionalData = response.data;

    return {
      name: 'Local Business Count',
      locked: false,
      fields: Object.entries(additionalData).map(([key, value]) => ({
        name: `${key.charAt(0).toUpperCase() + key.slice(1)}`,
        value: value as number,
        type: 'integer',
      })),
    };
  } catch (error) {
    console.error('Error fetching local business counts:', error);
    return null;
  }
}
