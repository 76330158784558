[
  {
    "group": "Limited-Service Restaurants",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/fastfood_white.png",
    "color": "#ff7a00",
    "brands": [
      {
        "_name": "Burger King",
        "brandId": "SG_BRAND_60d8d6d29e2c4b14f4ea1983baefd36e",
        "website": "https://www.bk.com/"
      },
      {
        "_name": "CAVA",
        "brandId": "SG_BRAND_d80d7686921e3199bab40fd496bc2a62",
        "website": "https://cava.com/"
      },
      {
        "_name": "Chick-fil-A",
        "brandId": "SG_BRAND_4b56bb21dd2aebd521e2ffff47f524fb",
        "website": "https://www.chick-fil-a.com/"
      },
      {
        "_name": "Chipotle Mexican Grill",
        "brandId": "SG_BRAND_87bd111b6dd52ef32eaf3abf6d509065",
        "website": "https://www.chipotle.com/"
      },
      {
        "_name": "Crumbl Cookies",
        "brandId": "SG_BRAND_1c604a2df2019291",
        "website": "https://crumblcookies.com/"
      },
      {
        "_name": "Dairy Queen",
        "brandId": "SG_BRAND_d6fdfe0213b3ad6f7bec024ed7719b7e",
        "website": "https://www.dairyqueen.com/"
      },
      {
        "_name": "Firehouse Subs",
        "brandId": "SG_BRAND_a508445847685e426174fb21affa82bb",
        "website": "https://www.firehousesubs.com/"
      },
      {
        "_name": "Five Guys",
        "brandId": "SG_BRAND_87b52f7d201822f23c1015f8e9beb4b1",
        "website": "https://www.fiveguys.com/"
      },
      {
        "_name": "In-N-Out Burger",
        "brandId": "SG_BRAND_4b71da982ddf765573ace33493ba2e04",
        "website": "https://www.in-n-out.com/"
      },
      {
        "_name": "Jersey Mike's",
        "brandId": "SG_BRAND_2e5e0487913bda7532010357c3b014d8",
        "website": "https://www.jerseymikes.com/"
      },
      {
        "_name": "Jimmy John's",
        "brandId": "SG_BRAND_a04f493d784143c9b21b2689e32fd0d1",
        "website": "https://www.jimmyjohns.com/"
      },
      {
        "_name": "KFC",
        "brandId": "SG_BRAND_75e52dcc790fbad91ae83227c0fb6e2f",
        "website": "https://www.kfc.com/"
      },
      {
        "_name": "Mad Greens",
        "brandId": "SG_BRAND_af0f3cb4003168fe73ba0c5c707a2aca",
        "website": "https://www.madgreens.com/"
      },
      {
        "_name": "McAlister's Deli",
        "brandId": "SG_BRAND_a7b1ab4c6661237b2868db054a99f47f",
        "website": "https://www.mcalistersdeli.com/"
      },
      {
        "_name": "McDonald's",
        "brandId": "SG_BRAND_5179b21fc1d50950b99b4eecaa48c614",
        "website": "https://www.mcdonalds.com/"
      },
      {
        "_name": "Panda Express",
        "brandId": "SG_BRAND_4a453a402f10c481d2fe4cefaa1d2b09",
        "website": "https://www.pandaexpress.com/"
      },
      {
        "_name": "Popeyes Louisiana Kitchen",
        "brandId": "SG_BRAND_5725c57624e4d885d0bf718123615aa9",
        "website": "https://www.popeyes.com/"
      },
      {
        "_name": "Shake Shack",
        "brandId": "SG_BRAND_ee06025b756a570f54e52dc67d62a34c",
        "website": "https://www.shakeshack.com/"
      },
      {
        "_name": "Starbucks",
        "brandId": "SG_BRAND_f116acfe9147494063e58da666d1d57e",
        "website": "https://www.starbucks.com/"
      },
      {
        "_name": "Subway",
        "brandId": "SG_BRAND_de80593878cb1673c62a7f338dc7e4e1",
        "website": "https://www.subway.com/en-us"
      },
      {
        "_name": "sweetgreen",
        "brandId": "SG_BRAND_a5ea795ab3769f0392e3658d9e64e003",
        "website": "https://www.sweetgreen.com/"
      },
      {
        "_name": "Taco Bell",
        "brandId": "SG_BRAND_748ba381dd81bb8de615319837ffa350",
        "website": "https://www.tacobell.com/"
      },
      {
        "_name": "Whataburger",
        "brandId": "SG_BRAND_c81e8621fe7764954e8d95f76a8b375c",
        "website": "https://whataburger.com/"
      },
      {
        "_name": "Wingstop",
        "brandId": "SG_BRAND_f0d6a9105f01c1e7fe9b106cfd4b5815",
        "website": "https://www.wingstop.com/"
      },
      {
        "_name": "Zaxby's",
        "brandId": "SG_BRAND_5271fc9e8c38fe53392a2ecabc642130",
        "website": "https://www.zaxbys.com/"
      },
      {
        "_name": "Salad and Go",
        "brandId": "SG_BRAND_ab81e61819c5ba51",
        "website": "https://www.saladandgo.com/"
      }
    ]
  },
  {
    "group": "Electronics Stores",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/devices_white.png",
    "color": "#9463cc",
    "brands": [
      {
        "_name": "Apple Retail Store",
        "brandId": "SG_BRAND_aa55d5b692887ce4ff82e8f98bd569ad",
        "website": "https://www.apple.com/"
      },
      {
        "_name": "Best Buy",
        "brandId": "SG_BRAND_2c648ef84225e10f0499e7d255eacf71",
        "website": "https://www.bestbuy.com/"
      },
      {
        "_name": "Microsoft Store",
        "brandId": "SG_BRAND_5f532a3fc4f1ea403f37070f59a7a53a",
        "website": "https://www.microsoft.com/"
      },
      {
        "_name": "RadioShack",
        "brandId": "SG_BRAND_d4f54b4468cb87ab20e2a2ce6aba8f8e",
        "website": "https://radioshack.com/"
      },
      {
        "_name": "AT&T",
        "brandId": "SG_BRAND_5deb800ce9500e72e355137ab8b48fb6",
        "website": "https://www.att.com/"
      },
      {
        "_name": "Sprint",
        "brandId": "SG_BRAND_1d08fdad0400ce91f60e43105b4df9c1",
        "website": "https://www.sprint.com/"
      },
      {
        "_name": "T-Mobile",
        "brandId": "SG_BRAND_4b82356db1a8f4a2db26dd5b7e30abba",
        "website": "https://www.t-mobile.com/"
      },
      {
        "_name": "Verizon Wireless",
        "brandId": "SG_BRAND_5525ff59adcaac313923ab89d0a618c5",
        "website": "https://www.verizon.com/"
      }
    ]
  },
  {
    "group": "Pharmacies and Drug Stores",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/local_pharmacy_white.png",
    "color": "#ffe466",
    "brands": [
      {
        "_name": "CVS",
        "brandId": "SG_BRAND_72f1a850d966375fa159121c7c8b09a1",
        "website": "https://www.cvs.com/"
      },
      {
        "_name": "CVS Pharmacy",
        "brandId": "SG_BRAND_691685759ac07d6c",
        "website": "https://www.cvs.com/"
      },
      {
        "_name": "Walgreens",
        "brandId": "SG_BRAND_63ea689e38887d2a25b1f57a6f86748f",
        "website": "https://www.walgreens.com/"
      },
      {
        "_name": "Walgreens Pharmacy",
        "brandId": "SG_BRAND_33ef43259b465c12",
        "website": "https://www.walgreens.com/"
      },
      {
        "_name": "Walmart Pharmacy",
        "brandId": "SG_BRAND_db7e289726149f82",
        "website": "https://www.walmart.com/"
      },
      {
        "_name": "Rite Aid",
        "brandId": "SG_BRAND_c7706c9b14f2ca266c2a309dba059031",
        "website": "https://www.riteaid.com/"
      },
      {
        "_name": "Rite Aid Pharmacy",
        "brandId": "SG_BRAND_feb40688b49e4c49",
        "website": "https://www.riteaid.com/"
      },
      {
        "_name": "Kroger Pharmacy",
        "brandId": "SG_BRAND_87a8a4b3d0208c01",
        "website": "https://www.kroger.com/"
      },
      {
        "_name": "Safeway Pharmacy",
        "brandId": "SG_BRAND_2e6163ca2b39e204",
        "website": "https://www.safeway.com/"
      },
      {
        "_name": "Publix Pharmacy",
        "brandId": "SG_BRAND_50d9618b101aa582",
        "website": "https://www.publix.com/"
      }
    ]
  },
  {
    "group": "Commercial Banking",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/account_balance_white.png",
    "color": "#152085",
    "brands": [
      {
        "_name": "Chase",
        "brandId": "SG_BRAND_cd8e7918010a87cc619849e00265c9a6",
        "website": "https://www.chase.com/"
      },
      {
        "_name": "Bank of America",
        "brandId": "SG_BRAND_73971897e96ce01fa107640e730f905a",
        "website": "https://www.bankofamerica.com/"
      },
      {
        "_name": "Wells Fargo",
        "brandId": "SG_BRAND_d8326a9cc6413b12d8df3c617490e882",
        "website": "https://www.wellsfargo.com/"
      },
      {
        "_name": "Citibank",
        "brandId": "SG_BRAND_f18df0996f4a2ce4365c0873ed2c90d3",
        "website": "https://www.citi.com/"
      },
      {
        "_name": "U.S. Bank",
        "brandId": "SG_BRAND_115e6cd3ceacb40f8be99ae3d3f62649",
        "website": "https://www.usbank.com/"
      },
      {
        "_name": "Truist",
        "brandId": "SG_BRAND_31b47efb23a48fca",
        "website": "https://www.truist.com/"
      },
      {
        "_name": "PNC Financial Services",
        "brandId": "SG_BRAND_497acb260184e50118775402a5fd6e23",
        "website": "https://www.pnc.com/"
      },
      {
        "_name": "Capital One",
        "brandId": "SG_BRAND_69d59a17f8e7d182ae0e0368c9eec9ac",
        "website": "https://www.capitalone.com/"
      },
      {
        "_name": "TD Bank",
        "brandId": "SG_BRAND_a270529277e11434ce7a40cff6c21217",
        "website": "https://www.td.com/"
      },
      {
        "_name": "BMO Harris Bank",
        "brandId": "SG_BRAND_0a993a39464394a219350180b62377ab",
        "website": "https://www.bmoharris.com/"
      },
      {
        "_name": "Citizens Bank",
        "brandId": "SG_BRAND_f58dee45923c7d70f0bf747fee3f4b97",
        "website": "https://www.citizensbank.com/"
      },
      {
        "_name": "First Citizens Bancshares",
        "brandId": "SG_BRAND_d4f82fec590aeae82c525da824297f50",
        "website": "https://www.firstcitizens.com/"
      },
      {
        "_name": "Fifth Third Bank",
        "brandId": "SG_BRAND_dfa5d1a8cb415413d7488ee070dca730",
        "website": "https://www.53.com/"
      },
      {
        "_name": "M&T Bank",
        "brandId": "SG_BRAND_528b789fac605691485c939ca81af840",
        "website": "https://www.mtb.com/"
      },
      {
        "_name": "KeyBank",
        "brandId": "SG_BRAND_19f61781f19ddf667e60cc8396365a00",
        "website": "https://www.key.com/"
      },
      {
        "_name": "The Huntington National Bank",
        "brandId": "SG_BRAND_02b0271b467f602df85bda877aaff678",
        "website": "https://www.huntington.com/"
      },
      {
        "_name": "HSBC Bank",
        "brandId": "SG_BRAND_b6a6737ef0ccfbf6d4d1afddc7829b33",
        "website": "https://www.hsbc.com/"
      }
    ]
  },
  {
    "group": "Fitness and Recreational Sports Centers",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/fitness_center_white.png",
    "_name": "b",
    "color": "#c2a384",
    "brands": [
      {
        "_name": "24 Hour Fitness",
        "brandId": "SG_BRAND_1dff6738894adf75487b2c1ba5f54873",
        "website": "https://www.24hourfitness.com/"
      },
      {
        "_name": "[solidcore]",
        "brandId": "SG_BRAND_357cce5297b9923a1f3c28e10884495d",
        "website": "https://www.solidcore.co/"
      },
      {
        "_name": "Barry's Bootcamp",
        "brandId": "SG_BRAND_b39f517061f443562df3f614ef50bae8",
        "website": "https://www.barrysbootcamp.com/"
      },
      {
        "_name": "Club Pilates",
        "brandId": "SG_BRAND_5fa25d11e985b8c72f25a2ac289a93f0",
        "website": "https://www.clubpilates.com/"
      },
      {
        "_name": "CorePower Yoga",
        "brandId": "SG_BRAND_0f2d55b86cd3a2f5e7f1a5643c629ff2",
        "website": "https://www.corepoweryoga.com/"
      },
      {
        "_name": "CrossFit",
        "brandId": "SG_BRAND_ae6abf1aa02c01cfe560b9315db66b07",
        "website": "https://www.crossfit.com/"
      },
      {
        "_name": "Crunch",
        "brandId": "SG_BRAND_29b2c5160da9da73ea41638c0dbd8e57",
        "website": "https://www.crunch.com/"
      },
      {
        "_name": "CycleBar",
        "brandId": "SG_BRAND_46d5853ab178e35cdd230f7b0f439817",
        "website": "https://www.cyclebar.com/"
      },
      {
        "_name": "Equinox Fitness Clubs",
        "brandId": "SG_BRAND_0cf9d52fc56badb790a6c3965f24eb8c",
        "website": "https://www.equinox.com/"
      },
      {
        "_name": "F45 Training",
        "brandId": "SG_BRAND_69e26c2c38d1753",
        "website": "https://f45training.com/"
      },
      {
        "_name": "Gold's Gym",
        "brandId": "SG_BRAND_1ff28a605439972fe65ea61ab6bbd087",
        "website": "https://www.goldsgym.com/"
      },
      {
        "_name": "LA Fitness",
        "brandId": "SG_BRAND_8a028bda2e1a2b9bd1ad429cd59d4cfa",
        "website": "https://www.lafitness.com/"
      },
      {
        "_name": "Lifetime Fitness",
        "brandId": "SG_BRAND_e992d80ce4aa56283b26fea4004c5486",
        "website": "https://www.lifetime.life/"
      },
      {
        "_name": "Orangetheory Fitness",
        "brandId": "SG_BRAND_80987f2551041e24748e1fd78c94adf1",
        "website": "https://www.orangetheory.com/"
      },
      {
        "_name": "Planet Fitness",
        "brandId": "SG_BRAND_c31fc3155fb9ca076972bb72985724fb",
        "website": "https://www.planetfitness.com/"
      },
      {
        "_name": "Pure Barre",
        "brandId": "SG_BRAND_5c009b6db1eacf4a65d273d0f19b3c73",
        "website": "https://www.purebarre.com/"
      },
      {
        "_name": "Rumble",
        "brandId": "SG_BRAND_f489f553f052fde5",
        "website": "https://www.rumbleboxinggym.com/"
      },
      {
        "_name": "SoulCycle",
        "brandId": "SG_BRAND_01e9b7353083bb45c018b3c8ee7612c5",
        "website": "https://www.soul-cycle.com/"
      }
    ]
  },
  {
    "group": "Supermarkets and Other Grocery Stores",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/local_grocery_store_white.png",
    "color": "#387a2a",
    "brands": [
      {
        "_name": "Wegmans Food Markets",
        "brandId": "SG_BRAND_3b19ceabae84acf2746e113cb24bdb0a",
        "website": "https://www.wegmans.com/"
      },
      {
        "_name": "Hy-Vee",
        "brandId": "SG_BRAND_8f8c9465b9550499b0540b26e9470dec",
        "website": "https://www.hy-vee.com/"
      },
      {
        "_name": "Trader Joe's",
        "brandId": "SG_BRAND_88b90c11892dd21eddc97bdb0fc449f0",
        "website": "https://www.traderjoes.com/"
      },
      {
        "_name": "Harvest Supermarket",
        "brandId": "SG_BRAND_7219b820ada53f71",
        "website": "https://www.harvestsupermarkets.com/"
      },
      {
        "_name": "Meijer",
        "brandId": "SG_BRAND_da8801acf8224dcb0fc1554dfaabec5e",
        "website": "https://www.meijer.com/"
      },
      {
        "_name": "Publix Super Markets",
        "brandId": "SG_BRAND_8816cf3aede474ecbdb03d62efaad185",
        "website": "https://www.publix.com/"
      },
      {
        "_name": "Albertsons",
        "brandId": "SG_BRAND_942c238b1dddf5d40a05dbad6851b58b",
        "website": "https://www.albertsons.com/"
      },
      {
        "_name": "ALDI",
        "brandId": "SG_BRAND_41267d9fb3826d417e32077a3b0a35e5",
        "website": "https://www.aldi.us/"
      },
      {
        "_name": "Kroger",
        "brandId": "SG_BRAND_1f852a23da4b7250",
        "website": "https://www.kroger.com/"
      },
      {
        "_name": "Whole Foods Market",
        "brandId": "SG_BRAND_44378a5463f4125c5d84db81855413b8",
        "website": "https://www.wholefoodsmarket.com/"
      },
      {
        "_name": "Sprouts Farmers Market",
        "brandId": "SG_BRAND_5fbd6eabefed0d40",
        "website": "https://www.sprouts.com/"
      },
      {
        "_name": "H-E-B",
        "brandId": "SG_BRAND_9abc7c4d28421b3c6ffd639114e2f8a4",
        "website": "https://www.heb.com/"
      }
    ]
  },
  {
    "group": "Warehouse Clubs and Supercenters",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/store_white.png",
    "color": "#4198ff",
    "brands": [
      {
        "_name": "Costco",
        "brandId": "SG_BRAND_60b7b54d19fca719281e76d485a141ad",
        "website": "https://www.costco.com/"
      },
      {
        "_name": "Sam's Club",
        "brandId": "SG_BRAND_1fdf499b6898e2c4f4c47e72ae249f4e",
        "website": "https://www.samsclub.com/"
      },
      {
        "_name": "Target",
        "brandId": "SG_BRAND_42aefbae01d2dfd981f7da7d823d689e",
        "website": "https://www.target.com/"
      },
      {
        "_name": "Walmart",
        "brandId": "SG_BRAND_04a8ca7bf49e7ecb4a32451676e929f0",
        "website": "https://www.walmart.com/"
      }
    ]
  },
  {
    "group": "Shopping & Retail",
    "icon": "https://plotr-public.s3.amazonaws.com/assets/images/store_white.png",
    "color": "#FF69B4",
    "brands": [
      {
        "_name": "Westfield",
        "brandId": "SG_BRAND_4f881579635210fd",
        "website": "https://www.westfield.com/"
      },
      {
        "_name": "Simon mall",
        "brandId": "SG_BRAND_0a3c99595c9d3fddfece9c4e7607e5b3",
        "website": "https://www.simon.com/"
      },
      {
        "_name": "Westfield Living Centre",
        "brandId": "SG_BRAND_5f08a847a4a83cfa",
        "website": "https://www.westfield.com/"
      },
      {
        "_name": "The Style Outlets",
        "brandId": "SG_BRAND_e7d51a5c88062a23",
        "website": "https://www.thestyleoutlets.com/"
      },
      {
        "_name": "AEON Town",
        "brandId": "SG_BRAND_76e4b3a053e5b525",
        "website": "https://www.aeontown.co.jp/"
      },
      {
        "_name": "Youme",
        "brandId": "SG_BRAND_2acc1cdff6168224",
        "website": "https://www.youmeatsix.com/"
      },
      {
        "_name": "Tanger Outlets",
        "brandId": "SG_BRAND_74b13c1bfa9312a3bfd0caa1da8b35ba",
        "website": "https://www.tanger.com/"
      }
    ],
    "queries": [
      {
        "id": "shopping-centers",
        "name": "Shopping Centers",
        "query": [
          {
            "equals": {
              "path": "naicsCode",
              "value": "531120"
            }
          },
          {
            "notIncludes": {
              "path": "brandId1",
              "value": [
                "SG_BRAND_4f881579635210fd",
                "SG_BRAND_0a3c99595c9d3fddfece9c4e7607e5b3",
                "SG_BRAND_5f08a847a4a83cfa",
                "SG_BRAND_e7d51a5c88062a23",
                "SG_BRAND_76e4b3a053e5b525",
                "SG_BRAND_2acc1cdff6168224",
                "SG_BRAND_74b13c1bfa9312a3bfd0caa1da8b35ba"
              ]
            }
          }
        ]
      }
    ]
  }
]
