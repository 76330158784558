/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Input,
  Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Control, Controller, useController } from 'react-hook-form';
import { EditPinSchema } from './schema';
import { useOverflowingContainer } from '../../hooks/useOverflowingContainer';

interface KeyValueTableProps {
  control: Control<EditPinSchema>;
  isEditing: boolean;
}

const FADE_OUT_STYLES = {
  position: 'absolute',
  left: 0,
  right: 0,
  height: 30,
  pointerEvents: 'none',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  background:
    'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
};

const KeyValueTable: React.FC<KeyValueTableProps> = ({
  control,
  isEditing,
}) => {
  const {
    ref: overflowContainerRef,
    isOverflowing,
    isAtTop,
    isAtBottom,
  } = useOverflowingContainer();

  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const [highlightedKey, setHighlightedKey] = useState<string | null>(null);

  const keyInputRef = useRef<HTMLInputElement>(null);

  const { field: kvPairsToDeleteField } = useController<EditPinSchema>({
    name: 'kvPairsToDelete',
    control,
    defaultValue: [],
  });

  // Handle add key-value pair
  const handleAddKeyValuePair = ({
    prevKvPairs,
    onChange,
  }: {
    prevKvPairs: Record<string, string>;
    onChange: (...event: any[]) => void;
  }) => {
    if (!newKey || !newValue) return;

    // Don't allow adding duplicate keys
    if (prevKvPairs[newKey]) {
      setHighlightedKey(newKey);
      return;
    }

    onChange({ ...prevKvPairs, [newKey]: newValue });

    setNewKey('');
    setNewValue('');
    keyInputRef.current?.focus();
  };

  // Handle checkbox change (to remove key-value pair)
  const handleCheckboxChange = ({
    key,
    checked,
    prevKvPairs,
    onChange,
  }: {
    key: string;
    checked: boolean;
    prevKvPairs: Record<string, string>;
    onChange: (...event: any[]) => void;
  }) => {
    const updatedKvPairs = { ...prevKvPairs };

    const kvPairsToDelete = kvPairsToDeleteField.value as string[];

    if (checked) {
      kvPairsToDeleteField.onChange(kvPairsToDelete.filter((k) => k !== key));
    } else {
      kvPairsToDeleteField.onChange([...kvPairsToDelete, key]);
    }

    onChange(updatedKvPairs);
  };

  // Handle value change in editing mode
  const handleInputChange = ({
    key,
    value,
    prevKvPairs,
    onChange,
  }: {
    key: string;
    value: string;
    prevKvPairs: Record<string, string>;
    onChange: (...event: any[]) => void;
  }) => {
    const updatedKvPairs = { ...prevKvPairs, [key]: value };
    onChange(updatedKvPairs);
  };

  const onKeyDownNewKey = (e: React.KeyboardEvent, action: () => void) => {
    if (e.key !== 'Enter') {
      return;
    }

    e.preventDefault();
    action();
  };

  useEffect(() => {
    const timeout = setTimeout(() => setHighlightedKey(null), 1500);

    return () => clearTimeout(timeout);
  }, [highlightedKey]);

  return (
    <Controller
      name="kvPairs"
      control={control}
      render={({ field: { value, onChange } }) =>
        !isEditing && !Object.entries(value || {}).length ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography color="gray" variant="body2">
              Edit to add properties
            </Typography>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            {isEditing && (
              <Grid
                container
                xs={12}
                display="flex"
                alignItems="center"
                sx={{ padding: 0.5 }}
              >
                <Grid item xs={1}>
                  <IconButton
                    onClick={() =>
                      handleAddKeyValuePair({
                        prevKvPairs: value,
                        onChange,
                      })
                    }
                    disabled={!newKey || !newValue}
                    sx={{ padding: 0 }}
                  >
                    <AddBoxIcon
                      color={newKey && newValue ? 'primary' : 'disabled'}
                      fontSize="small"
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={5} sx={{ pr: 2 }}>
                  <Input
                    type="text"
                    placeholder="Key"
                    value={newKey}
                    onChange={(e) => setNewKey(e.target.value)}
                    onKeyDown={(e) =>
                      onKeyDownNewKey(e, () =>
                        handleAddKeyValuePair({ prevKvPairs: value, onChange })
                      )
                    }
                    size="small"
                    fullWidth
                    inputRef={keyInputRef}
                    sx={{
                      fontSize: 14,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    type="text"
                    placeholder="Value"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    onKeyDown={(e) =>
                      onKeyDownNewKey(e, () =>
                        handleAddKeyValuePair({ prevKvPairs: value, onChange })
                      )
                    }
                    size="small"
                    fullWidth
                    sx={{
                      fontSize: 14,
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Box position="relative">
              <Box
                sx={{
                  ...FADE_OUT_STYLES,
                  top: 0,
                  opacity: isAtTop ? 0 : 1,
                  visibility: isAtTop ? 'hidden' : 'visible',
                  background:
                    'linear-gradient(to top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)', // Top fade
                }}
              />
              <Box
                ref={overflowContainerRef}
                display="flex"
                flexDirection="column"
                gap={0.5}
                maxHeight="250px"
                overflow="auto"
                sx={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {Object.entries(value || {}).map(([key, val]) => (
                  <Grid
                    container
                    xs={12}
                    key={key}
                    display="flex"
                    alignItems="center"
                    sx={{
                      backgroundColor:
                        highlightedKey === key
                          ? 'rgba(255, 0, 0, .2)'
                          : undefined,
                      transition: 'background-color 0.5s',
                      padding: 0.5,
                    }}
                  >
                    {isEditing && (
                      <Grid item xs={1}>
                        <Checkbox
                          size="small"
                          defaultChecked
                          onChange={(e) =>
                            handleCheckboxChange({
                              key,
                              checked: e.target.checked,
                              prevKvPairs: value,
                              onChange,
                            })
                          }
                          sx={{ padding: 0 }}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={isEditing ? 5 : 6}
                      title={key}
                      sx={{ pr: 2 }}
                    >
                      <Typography variant="body2" fontWeight={500} noWrap>
                        {key}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} title={val}>
                      {isEditing ? (
                        <Input
                          type="text"
                          value={val}
                          onChange={(e) =>
                            handleInputChange({
                              key,
                              value: e.target.value,
                              prevKvPairs: value,
                              onChange,
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                          size="small"
                          fullWidth
                          sx={{
                            fontSize: 14,
                          }}
                        />
                      ) : (
                        <Typography variant="body2" noWrap>
                          {val}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Box>
              <Box
                sx={{
                  ...FADE_OUT_STYLES,
                  bottom: 0,
                  opacity: isOverflowing && !isAtBottom ? 1 : 0,
                  visibility:
                    isOverflowing && !isAtBottom ? 'visible' : 'hidden',
                  background:
                    'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)', // Bottom fade
                }}
              />
            </Box>
          </Box>
        )
      }
    />
  );
};

export default KeyValueTable;
