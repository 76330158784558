import { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { CustomPin } from '@plotr/plotr-multiplayer-data';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useRulesets from '~/src/features/dynamic-map/hooks/useRulesets';
import { TEXT_STYLES } from '../styles/constants';
import { getColor, calculatePinScale } from '../utils/pinHelpers';
import getPinURL from '~/src/common/helpers/getPinURL';
import { MapStyle } from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

export function useCustomPinMarker(pin: CustomPin) {
  const rules = useRulesets();
  const zoom = useDynamicMapStore(useCallback((state) => state.zoomLevel, []));

  const { selectCustomPinId, mapStyle, pinLabelsEnabled } = useDynamicMapStore(
    useCallback((state) => ({
      selectCustomPinId: state.selectCustomPinId,
      mapStyle: state.mapStyle,
      pinLabelsEnabled: state.pinLabelsEnabled,
    }), []),
    shallow
  );

  const pinColor = useMemo(
    () => getColor(rules, pin?.keyValuePairs ?? {}, pin?.tags ?? []),
    [rules, pin?.keyValuePairs, pin?.tags]
  );

  const pinURL = useMemo(
    () => getPinURL({
      color: pinColor ?? '#3FB1CE',
      background: '#FFFFFF',
    }),
    [pinColor]
  );

  const scale = useMemo(() => calculatePinScale(zoom), [zoom]);

  const textStyle = useMemo(() => ({
    ...(mapStyle === MapStyle.Satellite || mapStyle === MapStyle.Dark
      ? TEXT_STYLES.dark
      : TEXT_STYLES.light),
    opacity: zoom ? (zoom <= 10 ? Math.max(0, (zoom - 5) / 5) : 1) : 1,
    transition: 'opacity 0.2s ease-in-out',
    marginTop: '0.25rem',
    textAlign: 'center' as const,
    visibility: pinLabelsEnabled ? 'visible' as const : 'hidden' as const,
    fontSize: '2rem',
  }), [mapStyle, zoom, pinLabelsEnabled]);

  const handlePinClick = useCallback(() => {
    selectCustomPinId(pin.id);
  }, [selectCustomPinId, pin.id]);

  return {
    pinURL,
    scale,
    textStyle,
    handlePinClick,
  };
}
