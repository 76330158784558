import getPinURL from '~/src/common/helpers/getPinURL';
import { ReportPinProps } from '../ReportPin';

export function makeReportPinElement({
  isSelected = false,
  isEvaluated = false,
  website,
  color = '#C3C3C3',
  selectedColor = '#3FB1CE',
  selectedBorder,
  label,
  zIndex,
}: Partial<ReportPinProps>): HTMLElement {
  const pinURL = getPinURL({
    color,
    background: '#FFFFFF',
    website,
    crop: true,
  });

  const selectedPinUrl = getPinURL({
    color: selectedColor,
    background: '#FFFFFF',
    border: selectedBorder,
    website,
    crop: true,
  });

  const container = document.createElement('div');
  if (zIndex !== undefined) {
    container.style.zIndex = (zIndex + 1).toString();
  }

  const marker = document.createElement('div');
  marker.style.display = 'flex';
  marker.style.flexDirection = 'column';
  marker.style.alignItems = 'center';
  marker.style.position = 'relative';
  container.appendChild(marker);

  const img = document.createElement('img');
  img.style.width = isEvaluated ? '3rem' : '2rem';
  img.style.height = 'auto';
  img.src = isSelected ? selectedPinUrl : pinURL;
  img.alt = 'custom pin';
  marker.appendChild(img);

  if (label) {
    const labelElement = document.createElement('span');
    labelElement.textContent = label;
    labelElement.style.position = 'absolute';
    labelElement.style.transition = 'opacity 0.2s ease-in-out';
    labelElement.style.bottom = '-1.25rem';
    labelElement.style.textAlign = 'center';
    labelElement.style.fontSize = '.75rem';
    labelElement.style.fontWeight = '500';
    marker.appendChild(labelElement);
  }

  return container;
}
