import { create } from 'zustand';

type TPermission = Record<string, boolean>;

interface PermissionsStore {
  permissions: TPermission;
  setPermission: (permission: string, value: boolean) => void;
  isReadOnly: boolean;
  setIsReadOnly: (isReadOnly: boolean) => void;
}

const usePermissionsStore = create<PermissionsStore>((set) => ({
  permissions: {},
  setPermission: (permission, value) => {
    const newPermissions = {
      ...usePermissionsStore.getState().permissions,
      [permission]: value,
    };

    set(() => ({ permissions: newPermissions }));
  },
  isReadOnly: false,
  setIsReadOnly: (isReadOnly) => {
    set(() => ({ isReadOnly }));
  },
}));

export default usePermissionsStore;
