import { Result } from '@mapbox/mapbox-gl-geocoder';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import { CustomPin, plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import MapGL, {
  MapRef,
  NavigationControl,
  ViewStateChangeEvent,
} from 'react-map-gl';
import { v4 as randomUUID } from 'uuid';
import getPinURL from '~/src/common/helpers/getPinURL';
import useResizeObserver from '~/src/common/hooks/useResizeObserver';
import CustomPins from '~/src/features/custom-pins/CustomPins';
import { MapProvider } from '~/src/features/dynamic-map/hooks/useMapContext';
import { Color } from '~/src/global/constants/colors';
import useCustomPins from '~/src/global/hooks/useCustomPins';

import useSettingsStore from '../../global/hooks/useSettingsStore';
import MapContextMenu from '../context-menu/MapContextMenu';
import ToggleDrawerButton from '../custom-drawer/ToggleDrawerButton';
import CustomDrawnTerritories from '../custom-territories/sources/CustomDrawnTerritories';
import CustomTerritories from '../custom-territories/sources/CustomTerritories';
import DataLayerHovercard from '../data-hover-card/DataLayerHovercard';
import EditPinPopup from '../demographic-point-lookup/components/EditPinPopup';
import DemographicPointLookup from '../demographic-point-lookup/DemographicPointLookup';
import useDemographicSearch from '../demographic-point-lookup/hooks/useDemographicSearch';
import DynamicMapController from '../dynamic-map-controller/DynamicMapController';
import PulseDrawer from '../dynamic-map-controller/PulseDrawer/PulseDrawer';
import PricingModelOutput from '../pricing/components/PricingModelOutput';
import BaseMap from './components/BaseMap';
import ClientGeometrySource from './components/ClientGeometriesSource';
import CustomLayersSource from './components/CustomLayersSource/CustomLayersSource';
import GeocoderControl from './components/GeocoderControl';
import MapLegend from './components/MapLegend';
import { MFHSource } from './components/MFHSource';
import { POISource } from './components/POISource/POISource';
import { ZipCodesSource } from './components/ZipCodesSource';
import './DynamicMap.css';
import coordinatesGeocoder from './helpers/CoordinatesGeocoder';
import useDynamicMapStore from './hooks/useDynamicMapStore';
import useUserResources from './hooks/useUserResources';

const env = parseEnv({
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
  API_V2: process.env.API_V2,
});

export default function DynamicMap() {
  const mapRef = useRef<MapRef | null>(null);
  const mapContainerRef = useRef<HTMLElement | null>(null);
  const {
    drawerMenuOpen,
    setDrawerMenuOpen,
    isDrawingTerritory,
    editingTerritoryBoundaryId,
    showLayerZoom,
    mapStyle,
    selectCustomPinId,
    zoomLevel,
    setZoomLevel,
    lastLocation,
    setLastLocation,
  } = useDynamicMapStore((state) => ({
    drawerMenuOpen: state.drawerMenuOpen,
    setDrawerMenuOpen: state.setDrawerMenuOpen,
    isDrawingTerritory: state.isDrawingTerritory,
    editingTerritoryBoundaryId: state.editingTerritoryBoundaryId,
    showLayerZoom: state.showLayerZoom,
    mapStyle: state.mapStyle,
    selectCustomPinId: state.selectCustomPinId,
    zoomLevel: state.zoomLevel,
    setZoomLevel: state.setZoomLevel,
    lastLocation: state.lastLocation,
    setLastLocation: state.setLastLocation,
  }));

  // user settings are loaded first, in UserDashboard.tsx
  const userSettings = useSettingsStore((state) => state.userSettings);
  const { customPins } = useCustomPins();
  const { isLoading } = useUserResources(userSettings);

  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const resizeCallback = useCallback(() => {
    if (mapRef.current) {
      mapRef.current.resize();
    }
  }, []);

  useResizeObserver(mapContainerRef, resizeCallback, 50);

  // handle searching for demographic data
  useDemographicSearch();

  const toggleDrawer = () => {
    setDrawerMenuOpen(!drawerMenuOpen);
  };

  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const evaluatedPin = useMemo(() => {
    if (evaluatedPinId) {
      return customPins.find((pin) => pin.id === evaluatedPinId);
    }
  }, [evaluatedPinId, customPins]);
  const expandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.expandedMobileDataAccordion
  );

  const handleViewStateChange = useCallback(
    (e: ViewStateChangeEvent) => {
      if (e.type === 'moveend') {
        setLastLocation({
          lat: e.viewState.latitude,
          lng: e.viewState.longitude,
        });
      }

      if (e.type === 'zoomend' && e.viewState.zoom !== zoomLevel) {
        setZoomLevel(e.viewState.zoom);
      }
    },
    [setLastLocation, setZoomLevel, zoomLevel]
  );

  // if evaluatedPinId is set, open the drawer menu to show the demographic info
  useEffect(() => {
    if (evaluatedPinId != null) {
      setDrawerMenuOpen(true);
    }
  }, [evaluatedPinId, setDrawerMenuOpen]);

  //halo around map if in edit mode
  useEffect(() => {
    if (mapContainerRef.current) {
      if (isDrawingTerritory || editingTerritoryBoundaryId) {
        mapContainerRef.current.classList.add('edit-mode-halo');
      } else {
        mapContainerRef.current.classList.remove('edit-mode-halo');
      }
    }
  }, [editingTerritoryBoundaryId, isDrawingTerritory]);

  const updatePointPins = () => {
    if (!mapRef.current) return;

    const map = mapRef.current;
    Object.entries(Color).map(([key, value]) => {
      if (key.includes('transparent')) return null;

      const imageUrl = getPinURL({
        color: value,
        background: '#fff',
        borderColor: '#000',
      });

      if (!map.hasImage(`geometry-point-pin-sdf-${value.toString()}`))
        map.loadImage(imageUrl, (error, image) => {
          if (error || !image) throw error;
          map.addImage(`geometry-point-pin-sdf-${value.toString()}`, image, {
            sdf: false,
          });
        });
    });
  };

  const handleGeocoderResult = useCallback(
    (e: { result: Result }) => {
      if (!customPinMethods || !e.result) return;

      const newPin: CustomPin = {
        id: randomUUID(),
        group: evaluatedPin?.group || 'Default Group',
        label: e.result.text,
        tags: [],
        pos: { lng: e.result.center[0], lat: e.result.center[1] },
        keyValuePairs: {},
      };

      customPinMethods.addPin(newPin);
      selectCustomPinId(newPin.id);
    },
    [customPinMethods, selectCustomPinId]
  );

  return (
    <MapProvider map={mapRef.current}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <PulseDrawer />
        <Box flex={1} maxWidth="100%" ref={mapContainerRef} position="relative">
          <MapGL
            styleDiffing={false}
            ref={mapRef}
            mapboxAccessToken={env.MAPBOX_API_KEY}
            mapStyle={`mapbox://styles/mapbox/${mapStyle}`}
            attributionControl={true}
            initialViewState={{
              zoom: zoomLevel,
              latitude: lastLocation.lat,
              longitude: lastLocation.lng,
            }}
            onZoomEnd={handleViewStateChange}
            onMoveEnd={handleViewStateChange}
            onLoad={(event) => {
              updatePointPins();
              const map = event.target;
              //add default pin image with sdf flag
              map.loadImage(
                env.API_V2 + '/asset/pin?color=%233FB1CE',
                (error, image) => {
                  if (error) {
                    console.error('Error loading image:', error);
                  } else if (image) {
                    map.addImage('geometry-point-pin-sdf', image, {
                      sdf: true,
                    });
                  }
                }
              );
            }}
            preserveDrawingBuffer
          >
            <MapContextMenu />
            {(evaluatedPinId != null ||
              expandedMobileDataAccordion === 'demographic') && (
              <DemographicPointLookup mapContainerRef={mapContainerRef} />
            )}
            <NavigationControl position="bottom-right" />
            {/*
              Wait for customPinMethods to be defined before rendering GeocoderControl.
              This is necessary because GeocoderControl will add a custom pin on address
              search, and it doesn't re-render when onResult dependencies change.
            */}
            {customPinMethods != null && (
              <GeocoderControl
                mapboxAccessToken={env.MAPBOX_API_KEY}
                position="top-left"
                limit={4}
                localGeocoder={coordinatesGeocoder}
                marker={false}
                onResult={handleGeocoderResult}
              />
            )}
            <DataLayerHovercard />
            {userSettings != null && (
              <ZipCodesSource showLayerZoom={showLayerZoom} />
            )}
            <CustomTerritories />
            <CustomDrawnTerritories />
            <CustomLayersSource />
            <ClientGeometrySource
              beforeId={undefined}
              showLayerZoom={0}
              enabled={true}
              opacity={0.8}
            />
            <MFHSource />
            <POISource />
            <CustomPins />
            <EditPinPopup />
            <PricingModelOutput />
          </MapGL>
          <ToggleDrawerButton
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerMenuOpen}
          />
          <MapLegend />
          <BaseMap />
        </Box>
        <DynamicMapController />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </MapProvider>
  );
}
