import { Box, Paper, Typography } from '@mui/material';
import turfBboxPolygon from '@turf/bbox-polygon';
import { Feature, Polygon } from 'geojson';
import numeral from 'numeral';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layer, Popup, Source } from 'react-map-gl';
import formatDayTime from '~/src/common/helpers/formatDayTime';
import { Permissions } from '~/src/constants';
import useLayerIds from '~/src/features/dynamic-map/hooks/useLayerIds';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import { GroupComponentProps } from '../../../hooks/useLayersStore';
import useTrafficStore, {
  trafficSources,
} from '../../../hooks/useTrafficStore';
import { useGetTrafficHighLow } from '../../../services/trafficService';

export const TrafficColor = {
  Light: '#f1c5cc',
  Medium: '#c76bb3',
  Heavy: '#950097',
};

const MIN_ZOOM_FOR_TRAFFIC = 10;

export default function TrafficSource({
  enabled,
  opacity,
}: GroupComponentProps) {
  const { permissions, setPermission } = usePermissionsStore();
  const hasPermission = permissions[Permissions.READ_TRAFFIC];
  const map = useMapContext();

  type PopupInfo = {
    segmentId?: string;
    lngLat: { lng: number; lat: number };
    averageTraffic: number;
  } | null;

  const [popupInfo, setPopupInfo] = useState<PopupInfo>(null);
  const {
    selectedVintage,
    layerInfo,
    selectedLayers,
    trafficBbox,
    setTrafficBbox,
    fetchPermission,
    trafficEnabled,
    setTrafficEnabled,
    setTrafficRange,
  } = useTrafficStore((state) => ({
    selectedVintage: state.selectedVintage,
    layerInfo: state.layerInfo,
    selectedLayers: state.selectedLayers,
    trafficBbox: state.trafficBbox,
    setTrafficBbox: state.setTrafficBbox,
    fetchPermission: state.fetchPermission,
    trafficEnabled: state.trafficEnabled,
    setTrafficEnabled: state.setTrafficEnabled,
    setTrafficRange: state.setTrafficRange,
  }));

  const { max: trafficMax, min: trafficMin } = useTrafficStore(
    (state) => state.trafficRange
  );
  const [viewportBoundary, setViewportBoundary] =
    useState<Feature<Polygon> | null>(null);

  const { selectedDay, selectedHour, direction } = useTrafficStore((state) => ({
    selectedDay: state.trafficSelection.selectedDay,
    selectedHour: state.trafficSelection.selectedHour,
    direction: state.trafficSelection.direction,
  }));

  const [trafficVisExpression, setTrafficVisExpression] = useState<
    mapboxgl.Expression | undefined
  >(undefined);
  const [trafficReady, setTrafficReady] = useState(false);

  const { accessToken } = useAccessToken();

  const mapZoom = map?.getZoom();

  const isTrafficFetchEnabled = useMemo(
    () => Boolean(trafficBbox && mapZoom && mapZoom > MIN_ZOOM_FOR_TRAFFIC),
    [trafficBbox, mapZoom]
  );

  useGetTrafficHighLow(
    selectedDay,
    selectedHour,
    trafficBbox,
    selectedVintage,
    selectedLayers,
    setTrafficRange,
    isTrafficFetchEnabled
  );

  //update viewport boundary when map moves
  useEffect(() => {
    if (enabled && map) {
      const handleMoveEnd = () => {
        if (map.getZoom() >= MIN_ZOOM_FOR_TRAFFIC) {
          const bounds = map.getBounds();
          const bbox: [number, number, number, number] = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
          ];
          const geoJSON: Feature<Polygon> = turfBboxPolygon(bbox);
          setViewportBoundary(geoJSON);
        } else {
          setViewportBoundary(null);
        }
      };

      handleMoveEnd(); // set initial boundary

      map.on('moveend', handleMoveEnd);

      return () => {
        map.off('moveend', handleMoveEnd);
      };
    }
    return;
  }, [enabled, map]);

  useEffect(() => {
    if (trafficEnabled !== enabled) {
      setTrafficEnabled(enabled);
    }
  }, [enabled, setTrafficEnabled, trafficEnabled]);

  useEffect(() => {
    if (map) {
      const bounds = map.getBounds();
      const bbox: [number, number, number, number] = [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ];
      setTrafficBbox(bbox);
    }
  }, [map, setTrafficBbox, viewportBoundary]);

  const trafficLayerIds = useMemo(
    () =>
      layerInfo
        .filter(
          (layer) =>
            layer?.fields &&
            Object.keys(layer.fields).length > 0 &&
            (selectedLayers.length === 0 || selectedLayers.includes(layer.id))
        )
        .map((layer) => `traffic_volume-${layer.id}`),
    [layerInfo, selectedLayers]
  );

  const calculateAverageTrafficForFeature = useCallback(
    (feature: Feature): number => {
      const properties = feature.properties;

      let totalTraffic = 0;

      const hours =
        selectedHour === -1 ? [...Array(24).keys()] : [selectedHour];

      if (selectedDay === 0) {
        for (let i = 1; i <= 7; i++) {
          const dailyTraffic = hours.reduce((acc, hour) => {
            const hourString = hour < 10 ? '0' + hour : hour.toString();
            const key = `${i}-${hourString}-${direction}`;
            return properties?.[key] !== undefined
              ? acc + properties[key]
              : acc;
          }, 0);

          totalTraffic += dailyTraffic;
        }
      } else {
        totalTraffic = hours.reduce((acc, hour) => {
          const hourString = hour < 10 ? '0' + hour : hour.toString();
          const key = `${selectedDay}-${hourString}-${direction}`;
          return properties?.[key] !== undefined ? acc + properties[key] : acc;
        }, 0);
      }

      return selectedDay > 0 ? totalTraffic : totalTraffic / 7;
    },
    [direction, selectedDay, selectedHour]
  );

  const trafficTilesetURL = trafficSources[selectedVintage];

  const generateTrafficAverageExpression = useCallback(
    (
      selectedDay: number,
      selectedHour: number,
      direction: number
    ): mapboxgl.Expression => {
      const dayExpressions: mapboxgl.Expression[] = [];

      const hours =
        selectedHour === -1 ? [...Array(24).keys()] : [selectedHour]; // include 00 hour when ready

      if (selectedDay === 0) {
        for (let i = 1; i <= 7; i++) {
          hours.forEach((hour) => {
            const hourString = hour < 10 ? '0' + hour : hour.toString();
            dayExpressions.push([
              'coalesce',
              ['get', `${i}-${hourString}-${direction}`],
              0,
            ]);
          });
        }
      } else {
        hours.forEach((hour) => {
          const hourString = hour < 10 ? '0' + hour : hour.toString();
          dayExpressions.push([
            'coalesce',
            ['get', `${selectedDay}-${hourString}-${direction}`],
            0,
          ]);
        });
      }

      const divisor = selectedDay === 0 ? 7 : 1;
      const averageExpression = ['/', ['+', ...dayExpressions], divisor];

      const trafficTiers = {
        high: trafficMin + (trafficMax - trafficMin) * 0.95,
        medium: trafficMin + (trafficMax - trafficMin) * 0.5,
        low: trafficMin + (trafficMax - trafficMin) * 0.25,
      };

      const noInterpolation =
        trafficTiers.high === trafficTiers.medium &&
        trafficTiers.medium === trafficTiers.low;

      return [
        'interpolate',
        ['linear'],
        averageExpression,
        ...(noInterpolation
          ? [0, 'transparent']
          : [
              ...[trafficTiers.low, TrafficColor.Light],
              ...[trafficTiers.medium, TrafficColor.Medium],
              ...[trafficTiers.high, TrafficColor.Heavy],
            ]),
      ];
    },
    [trafficMin, trafficMax]
  );

  useEffect(() => {
    if (accessToken != null) {
      fetchPermission(accessToken, setPermission);
    }
  }, [accessToken, fetchPermission, setPermission]);

  useEffect(() => {
    if (trafficMin === 0 && trafficMax === 0 && !trafficBbox) return;
    setTrafficVisExpression(
      generateTrafficAverageExpression(selectedDay, selectedHour, direction)
    );
  }, [
    trafficMin,
    trafficMax,
    generateTrafficAverageExpression,
    selectedDay,
    selectedHour,
    direction,
    trafficBbox,
  ]);

  useEffect(() => {
    if (!trafficLayerIds) return;
    if (!map) return;
    if (!trafficReady || !trafficEnabled) return;
    const onHover = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (!trafficReady) return;
      const features = map.queryRenderedFeatures(event.point, {
        layers: trafficLayerIds,
      });
      if (features && features.length) {
        const feature = features[0];
        const averageTraffic = calculateAverageTrafficForFeature(feature);

        setPopupInfo({
          segmentId: feature.properties?.streetlightId,
          lngLat: event.lngLat,
          averageTraffic: averageTraffic,
        });
      } else {
        setPopupInfo(null);
      }
    };

    map.on('mousemove', onHover);

    return () => {
      map.off('mousemove', onHover);
    };
  }, [
    trafficLayerIds,
    trafficReady,
    selectedDay,
    selectedHour,
    trafficEnabled,
    map,
    calculateAverageTrafficForFeature,
  ]);

  useEffect(() => {
    if (
      trafficBbox &&
      trafficMin >= 0 &&
      trafficMax > 0 &&
      trafficVisExpression
    ) {
      setTrafficReady(true);
    } else {
      setTrafficReady(false);
    }
  }, [trafficBbox, trafficMin, trafficMax, trafficVisExpression]);

  const stateLabelIds = useLayerIds((layer) =>
    layer.id.startsWith('state-label')
  );

  return map != null && hasPermission ? (
    <>
      <Source id={'TRAFFIC_VOLUME'} type="vector" url={trafficTilesetURL}>
        {trafficEnabled &&
          layerInfo
            .filter((layer) => {
              return (
                layer?.fields &&
                Object.keys(layer.fields).length > 0 &&
                (selectedLayers.length === 0 ||
                  selectedLayers.includes(layer.id))
              );
            })
            .map((layer) => {
              const layerId = `traffic_volume-${layer.id}`;
              return (
                <Layer
                  id={layerId}
                  key={layerId}
                  type="line"
                  source={'TRAFFIC_VOLUME'}
                  source-layer={layer.id}
                  minzoom={6}
                  paint={{
                    'line-color': trafficVisExpression ?? 'transparent',
                    'line-width': [
                      'interpolate',
                      ['linear'],
                      ['zoom'],
                      6,
                      2,
                      12,
                      4,
                      18,
                      8,
                    ],
                    'line-opacity': opacity ?? 0,
                  }}
                  beforeId={stateLabelIds[0]}
                />
              );
            })}
      </Source>
      {trafficEnabled && trafficReady && popupInfo && (
        <Popup
          anchor="top"
          longitude={popupInfo.lngLat.lng}
          latitude={popupInfo.lngLat.lat}
          closeButton={false}
        >
          <Paper elevation={0} style={{ padding: '8px' }}>
            <Typography variant="subtitle1" gutterBottom>
              <span role="img" aria-label="car emoji">
                🚙
              </span>{' '}
              Average Traffic <br />
              {selectedDay === 0
                ? 'Daily'
                : formatDayTime(selectedDay, selectedHour)}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="h6">
                {numeral(Math.round(popupInfo.averageTraffic)).format('0,0')}
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                vehicles
              </Typography>
            </Box>
          </Paper>
        </Popup>
      )}
    </>
  ) : null;
}
