import { Feature, MultiPolygon, Polygon } from 'geojson';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { FetchTradeAreaOverlapDataResult } from '../../dynamic-map/services/poiService';

export type DemographicResponse =
  | {
      boundary_type: 'radius';
      boundary_measure: 'miles';
      measure_value: number;
      data: DemographicData[];
    }
  | {
      boundary_type: 'custom';
      data: DemographicData[];
    };

export interface DemographicData {
  name: string;
  description?: string;
  locked?: boolean;
  fields: DemographicField[];
}

export interface DemographicField {
  name: string;
  value: number | null;
  type: 'integer' | 'percent' | 'USD';
}

export type PeopleFractionMap = Map<string, number>;

export interface MobileData {
  location_id: string;
  foot_traffic_poi: FootTrafficData[];
  trendingPercentage: string;
  trendWord: string;
  peopleFractionMap: PeopleFractionMap;
}

export interface FootTrafficData {
  locationId: string;
  observationStartDate: string;
  observationEndDate: string;
  visitsSum: number;
  visitsP50: number;
}

export interface POIData {
  censusCode: string;
  topCategory: string;
  subCategory: string;
  streetAddress: string;
  cityRegion: string;
  postalCode: string;
  openedOn: string | null;
  closedOn: string | null;
  trackingClosedSince: string | null;
  cbsaName: string | null;
}

export interface SonarData {
  date: string;
  id: number;
  safegraphPlacekey: string;
  ntlRanking: number;
  marketRanking: number;
  ntlIndex: number;
  marketIndex: number;
  rankingYear: number;
  likelihood_to_close: string;
  total_number_of_national_ranked_brand_stores: number;
  total_number_of_market_ranked_brand_stores: number;
}

export type Boundary = Feature<Polygon | MultiPolygon>;

export type BoundaryType = 'radius' | 'driving' | 'walking' | 'cycling';

function generateFakeData(type: DemographicField['type']) {
  if (type === 'percent') {
    return Math.random();
  }
  return Math.floor(Math.random() * 500000);
}

function generateSteppedMarkValues({
  min,
  max,
  step,
  displayEvery = 1,
  displayUnit,
}: {
  min: number;
  max: number;
  step: number;
  displayEvery?: number;
  displayUnit?: string;
}) {
  const values: { value: number; label?: string }[] = [];
  for (let i = min; i <= max; i += step) {
    const showLabel = i === min || i % displayEvery === 0;

    values.push({
      value: i,
      ...(showLabel && {
        label: `${i}${displayUnit ? ` ${displayUnit}` : ''}`,
      }),
    });
  }
  return values;
}

export const searchSettings = {
  radius: {
    marks: [
      {
        value: 1,
        label: '1',
      },
      ...generateSteppedMarkValues({
        min: 5,
        max: 25,
        step: 5,
      }),
    ],
    min: 1,
    max: 25,
    step: 0.25,
    numberInputStep: 1,
    displayUnit: 'mi',
  },
  driving: {
    marks: [
      {
        value: 1,
        label: '1',
      },
      ...generateSteppedMarkValues({
        min: 5,
        max: 60,
        step: 5,
      }),
    ],
    min: 1,
    max: 60,
    step: 1,
    numberInputStep: 1,
    displayUnit: 'min',
  },
  walking: {
    marks: [
      {
        value: 1,
        label: '1',
      },
      ...generateSteppedMarkValues({
        min: 5,
        max: 60,
        step: 5,
      }),
    ],
    min: 1,
    max: 60,
    step: 1,
    numberInputStep: 1,
    displayUnit: 'min',
  },
  cycling: {
    marks: [
      {
        value: 1,
        label: '1',
      },
      ...generateSteppedMarkValues({
        min: 5,
        max: 60,
        step: 5,
      }),
    ],
    min: 1,
    max: 60,
    step: 1,
    numberInputStep: 1,
    displayUnit: 'min',
  },
};

interface DemographicStore {
  demographicSummaryData: {
    us: DemographicResponse | null;
    ca: DemographicResponse | null;
  } | null;
  setDemographicSummaryData: (
    data: {
      us: DemographicResponse | null;
      ca: DemographicResponse | null;
    } | null
  ) => void;

  boundaryType: BoundaryType;
  setDemographicBoundaryType: (type: BoundaryType) => void;

  demographicSearchRadius: number;
  setDemographicSearchRadius: (radius: number) => void;

  boundaryData: Boundary | null;
  setBoundaryData: (data: Boundary | null) => void;

  isDemographicDataLoading: boolean;
  setIsDemographicDataLoading: (isLoading: boolean) => void;

  mobileData: MobileData | null;
  setMobileData: (data: MobileData | null) => void;

  mobileDataIsLoading: boolean;
  setMobileDataIsLoading: (isLoading: boolean) => void;

  tradeAreaData: PeopleFractionMap | null;
  setTradeAreaData: (data: PeopleFractionMap | null) => void;

  tradeAreaDataIsLoading: boolean;
  setTradeAreaDataIsLoading: (isLoading: boolean) => void;

  tradeAreaOverlapData: FetchTradeAreaOverlapDataResult | null;
  setTradeAreaOverlapData: (
    data: FetchTradeAreaOverlapDataResult | null
  ) => void;

  tradeAreaOverlapDataIsLoading: boolean;
  setTradeAreaOverlapDataIsLoading: (isLoading: boolean) => void;

  poiData: POIData | null;
  setPOIData: (data: POIData | null) => void;

  poiDataIsLoading: boolean;
  setPoiDataIsLoading: (isLoading: boolean) => void;

  sonarData: SonarData | null;
  setSonarData: (data: SonarData | null) => void;

  sonarDataIsLoading: boolean;
  setSonarDataIsLoading: (isLoading: boolean) => void;

  sonarDataError: string | null;
  setSonarDataError: (error: string | null) => void;

  mobileDataError: string | null;
  setMobileDataError: (error: string | null) => void;

  poiDataError: string | null;
  setPoiDataError: (error: string | null) => void;

  tradeAreaDataError: string | null;
  setTradeAreaDataError: (error: string | null) => void;

  showTradeAreaHeatmap: boolean;
  setShowTradeAreaHeatmap: (toggle: boolean) => void;

  showTradeAreaHeatmapData: boolean;
  setShowTradeAreaHeatmapData: (toggle: boolean) => void;

  showTradeAreaOverlapHeatmapData: boolean;
  setShowTradeAreaOverlapHeatmapData: (toggle: boolean) => void;

  tradeAreaType: 'HOME' | 'WORK' | 'BOTH';
  setTradeAreaType: (type: 'HOME' | 'WORK' | 'BOTH') => void;

  showTradeAreaOverlapHeatmap: boolean;
  setShowTradeAreaOverlapHeatmap: (toggle: boolean) => void;

  tradeAreaRankingYear: number;
  setTradeAreaRankingYear: (rankingYear: number) => void;

  sonarDataYear: number;
  setSonarDataYear: (rankingYear: number) => void;

  mobileDataCalculated: any;
  setMobileDataCalculated: (data: any) => void;

  tradeAreaOverlapDataError: string | null;
  setTradeAreaOverlapDataError: (error: string | null) => void;

  showTradeOverlapAreaHeatmap: boolean;
  setShowTradeOverlapAreaHeatmap: (toggle: boolean) => void;

  tradeAreaOverlapDataCache: Record<string, FetchTradeAreaOverlapDataResult>;
  setTradeAreaOverlapDataCache: (
    key: string,
    data: FetchTradeAreaOverlapDataResult
  ) => void;
}

const useDemographicStore = create(
  persist(
    subscribeWithSelector<DemographicStore>((set) => ({
      demographicSummaryData: null,
      setDemographicSummaryData: (demographicData) =>
        set(() => ({
          demographicSummaryData: {
            ...demographicData,
            us:
              (demographicData?.us?.data?.length ?? 0) < 1
                ? null
                : {
                    data:
                      demographicData?.us?.data.map((data) => {
                        const isLocked = data.locked ?? false;

                        return {
                          ...data,
                          locked: isLocked,
                          fields: data.fields.map((field) => ({
                            ...field,
                            value: isLocked
                              ? generateFakeData(field.type)
                              : field.value,
                          })),
                        };
                      }) ?? null,
                    ...demographicData?.us,
                  },
            ca:
              (demographicData?.ca?.data?.length ?? 0) < 1
                ? null
                : {
                    data:
                      demographicData?.ca?.data.map((data) => {
                        const isLocked = data.locked ?? false;

                        return {
                          ...data,
                          locked: isLocked,
                          fields: data.fields.map((field) => ({
                            ...field,
                            value: isLocked
                              ? generateFakeData(field.type)
                              : field.value,
                          })),
                        };
                      }) ?? null,
                    ...demographicData?.ca,
                  },
          } as {
            us: DemographicResponse | null;
            ca: DemographicResponse | null;
          },
        })),

      boundaryType: 'radius',
      setDemographicBoundaryType: (type: BoundaryType) =>
        set(() => ({ boundaryType: type })),

      demographicSearchRadius: searchSettings.radius.marks[0].value,
      setDemographicSearchRadius: (radius: number) =>
        set(() => ({ demographicSearchRadius: radius })),

      boundaryData: null,
      setBoundaryData: (data: Boundary | null) =>
        set(() => ({ boundaryData: data })),

      isDemographicDataLoading: false,
      setIsDemographicDataLoading: (isLoading: boolean) =>
        set(() => ({ isDemographicDataLoading: isLoading })),

      mobileData: null,
      setMobileData: (data: MobileData | null) =>
        set(() => ({ mobileData: data })),

      mobileDataIsLoading: false,
      setMobileDataIsLoading: (isLoading: boolean) =>
        set(() => ({ mobileDataIsLoading: isLoading })),

      tradeAreaData: null,
      setTradeAreaData: (data: PeopleFractionMap | null) =>
        set(() => ({ tradeAreaData: data })),

      tradeAreaDataIsLoading: false,
      setTradeAreaDataIsLoading: (isLoading: boolean) =>
        set(() => ({ tradeAreaDataIsLoading: isLoading })),

      tradeAreaOverlapData: null,
      setTradeAreaOverlapData: (data: FetchTradeAreaOverlapDataResult | null) =>
        set(() => ({ tradeAreaOverlapData: data })),

      tradeAreaOverlapDataIsLoading: false,
      setTradeAreaOverlapDataIsLoading: (isLoading: boolean) =>
        set(() => ({ tradeAreaOverlapDataIsLoading: isLoading })),

      poiData: null,
      setPOIData: (data: POIData | null) => set(() => ({ poiData: data })),

      poiDataIsLoading: false,
      setPoiDataIsLoading: (isLoading: boolean) =>
        set(() => ({ poiDataIsLoading: isLoading })),

      sonarData: null,
      setSonarData: (data: SonarData | null) =>
        set(() => ({ sonarData: data })),

      sonarDataIsLoading: false,
      setSonarDataIsLoading: (isLoading: boolean) =>
        set(() => ({ sonarDataIsLoading: isLoading })),

      sonarDataError: null,
      setSonarDataError: (error: string | null) =>
        set(() => ({ sonarDataError: error })),

      mobileDataError: null,
      setMobileDataError: (error: string | null) =>
        set(() => ({ mobileDataError: error })),

      tradeAreaDataError: null,
      setTradeAreaDataError: (error: string | null) =>
        set(() => ({ tradeAreaDataError: error })),

      showTradeAreaHeatmap: true,
      setShowTradeAreaHeatmap: (toggle: boolean) =>
        set(() => ({ showTradeAreaHeatmap: toggle })),

      showTradeAreaHeatmapData: true,
      setShowTradeAreaHeatmapData: (toggle: boolean) =>
        set(() => ({ showTradeAreaHeatmapData: toggle })),

      showTradeAreaOverlapHeatmapData: true,
      setShowTradeAreaOverlapHeatmapData: (toggle: boolean) =>
        set(() => ({ showTradeAreaOverlapHeatmapData: toggle })),

      showTradeAreaOverlapHeatmap: true,
      setShowTradeAreaOverlapHeatmap: (toggle: boolean) =>
        set(() => ({ showTradeAreaOverlapHeatmap: toggle })),

      tradeAreaRankingYear: 2023,
      setTradeAreaRankingYear: (rankingYear: number) =>
        set(() => ({ tradeAreaRankingYear: rankingYear })),

      sonarDataYear: 2023,
      setSonarDataYear: (year: number) => set(() => ({ sonarDataYear: year })),

      tradeAreaType: 'HOME',
      setTradeAreaType: (type: 'HOME' | 'WORK' | 'BOTH') =>
        set(() => ({ tradeAreaType: type })),

      mobileDataCalculated: {
        visits_sum: 0,
        visits_p50: 0,
      },
      setMobileDataCalculated: (data: {
        visits_sum: number;
        visits_p50: number;
      }) => set(() => ({ mobileDataCalculated: data })),

      poiDataError: null,
      setPoiDataError: (error: string | null) =>
        set(() => ({ poiDataError: error })),

      tradeAreaOverlapDataError: null,
      setTradeAreaOverlapDataError: (error: string | null) =>
        set(() => ({ tradeAreaOverlapDataError: error })),

      showTradeOverlapAreaHeatmap: true,
      setShowTradeOverlapAreaHeatmap: (toggle: boolean) =>
        set(() => ({ showTradeOverlapAreaHeatmap: toggle })),

      tradeAreaOverlapDataCache: {},
      setTradeAreaOverlapDataCache: (
        key: string,
        data: FetchTradeAreaOverlapDataResult
      ) =>
        set((state) => ({
          tradeAreaOverlapDataCache: {
            ...state.tradeAreaOverlapDataCache,
            [key]: data,
          },
        })),
    })),
    {
      name: 'demographic-state',
    }
  )
);
// Resets boundary, summary data, and search radius when boundary type changes
useDemographicStore.subscribe(
  (state) => ({
    boundaryType: state.boundaryType,
    searchRadius: state.demographicSearchRadius,
    setDemographicSummaryData: state.setDemographicSummaryData,
    setBoundaryData: state.setBoundaryData,
    setRadius: state.setDemographicSearchRadius,
  }),
  (
    {
      boundaryType,
      searchRadius,
      setDemographicSummaryData,
      setBoundaryData,
      setRadius,
    },
    { boundaryType: prevBoundaryType }
  ) => {
    if (searchRadius == null) {
      setRadius(searchSettings[boundaryType].marks[0].value);
    }

    if (boundaryType !== prevBoundaryType) {
      setBoundaryData(null);
      setDemographicSummaryData(null);
      setRadius(searchSettings[boundaryType].marks[0].value);
    }
  },
  { equalityFn: shallow }
);

export default useDemographicStore;
