import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { useQuery } from 'react-query';
import { TrafficVintages } from '../hooks/useTrafficStore';

export const GET_HIGH_LOW_TRAFFIC_KEY = 'GETHIGHLOWTRAFFIC';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

export interface IGetTrafficHighLow {
  selectedDay: number;
  selectedHour: number;
  trafficBbox: [number, number, number, number] | null;
  selectedVintage: TrafficVintages;
  highways: string[];
}

const getTrafficHighLow = async ({
  selectedDay,
  selectedHour,
  trafficBbox,
  selectedVintage,
  highways,
}: IGetTrafficHighLow) => {
  try {
    const { data } = await axios.post(
      `${env.API_V2}/vehicles/get_high_low_traffic`,
      {
        dayType: selectedDay,
        dayPart: selectedHour,
        bbox: trafficBbox,
        vintage: selectedVintage,
        highways,
      }
    );

    const summary = data.aggregatedData;
    return { min: summary.minVolume, max: summary.maxVolume };
  } catch (error) {
    console.error('Error fetching high low traffic:', error);
    return { max: 0, min: 0 };
  }
};

export const useGetTrafficHighLow = (
  selectedDay: number,
  selectedHour: number,
  trafficBbox: [number, number, number, number] | null,
  selectedVintage: TrafficVintages,
  highways: string[],
  setTrafficRange: ({ min, max }: { min: number; max: number }) => void,
  enabled: boolean
) => {
  return useQuery({
    queryKey: [
      GET_HIGH_LOW_TRAFFIC_KEY,
      selectedDay,
      selectedHour,
      trafficBbox,
      selectedVintage,
      highways,
    ],
    queryFn: () => {
      highways.length < 1 && setTrafficRange({ min: 0, max: 0 });

      return getTrafficHighLow({
        selectedDay,
        selectedHour,
        trafficBbox,
        selectedVintage,
        highways,
      });
    },
    onSuccess: (data) => {
      setTrafficRange(data);
    },
    onError: () => {
      setTrafficRange({ min: 0, max: 0 });
    },
    enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });
};
