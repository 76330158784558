import {
  LayerConfig,
  LayerType,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu/';
import { Color } from '~/src/global/constants/colors';
import { squareMetersPerSquareMile } from '~/src/global/constants/measurementSystems';

export const mccombsLayers: Record<string, LayerConfig> = {
  mccombs_hospitality_market: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Hospitality Markets',
    category: 'McCombs.Markets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Hospitality Markets',
      source: 'luketruitt1.mccombs',
      source_layer: 'hospitalitymarket',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['concat', ['get', 'RMName'], ' (', ['get', 'GeogCode'], ')'],
      fields: [
        { 'Market Type': 'MarketType' },
        { 'Geography Name': 'GeogName' },
        { 'Geography ID': 'GeogID' },
        { 'RM ID': 'RMID' },
        { 'Geography Type ID': 'GeogTypeID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },

  mccombs_hospitality_submarkets: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Hospitality Submarkets',
    category: 'McCombs.Submarkets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Hospitality Submarkets',
      source: 'luketruitt1.mccombs',
      source_layer: 'hospitalitysubmarkets',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'SubName'],
      fields: [
        { Market: 'RMName' },
        { Cluster: 'ClstrName' },
        { 'Property Type': 'PrpType' },
        { CBSA: 'CBSAName' },
        { 'Sub ID': 'SubID' },
        { 'Cluster ID': 'ClstrID' },
        { 'RM ID': 'RMID' },
        { 'Geography Code': 'GeogCode' },
        { 'Geography ID': 'GeogID' },
        { 'Geography Name': 'GeogName' },
        { 'Property Type ID': 'PrpTypeID' },
        { 'Geography Type': 'GeogTyp' },
        { 'Geography Type ID': 'GeogTypID' },
        { 'CBSA ID': 'CBSAID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },

  mccombs_industrial_submarkets: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Industrial Submarkets',
    category: 'McCombs.Submarkets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Industrial Submarkets',
      source: 'luketruitt1.mccombs',
      source_layer: 'industrialsubmarkets',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'SubName'],
      fields: [
        { Market: 'RMName' },
        { Cluster: 'ClstrName' },
        { 'Property Type': 'PrpType' },
        { 'Location Type': 'LocType' },
        { CBSA: 'CBSAName' },
        { 'Sub ID': 'SubID' },
        { 'Cluster ID': 'ClstrID' },
        { 'RM ID': 'RMID' },
        { 'Geography Code': 'GeogCode' },
        { 'Geography ID': 'GeogID' },
        { 'Geography Name': 'GeogName' },
        { 'Property Type ID': 'PrpTypeID' },
        { 'Geography Type': 'GeogTyp' },
        { 'Geography Type ID': 'GeogTypID' },
        { 'CBSA ID': 'CBSAID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },

  mccombs_multifamily_submarkets: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Multifamily Submarkets',
    category: 'McCombs.Submarkets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Multifamily Submarkets',
      source: 'luketruitt1.mccombs',
      source_layer: 'multifamilysubmarkets',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'SubName'],
      fields: [
        { Market: 'RMName' },
        { 'Property Type': 'PrpType' },
        { CBSA: 'CBSAName' },
        { 'Sub ID': 'SubID' },
        { 'RM ID': 'RMID' },
        { 'Geography Code': 'GeogCode' },
        { 'Geography ID': 'GeogID' },
        { 'Geography Name': 'GeogName' },
        { 'Property Type ID': 'PrpTypeID' },
        { 'Geography Type': 'GeogTyp' },
        { 'Geography Type ID': 'GeogTypID' },
        { 'CBSA ID': 'CBSAID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },

  mccombs_office_submarkets: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Office Submarkets',
    category: 'McCombs.Submarkets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Office Submarkets',
      source: 'luketruitt1.mccombs',
      source_layer: 'officesubmarkets',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'SubName'],
      fields: [
        { Market: 'RMName' },
        { Cluster: 'ClstrName' },
        { 'Property Type': 'PrpType' },
        { 'Location Type': 'LocType' },
        { CBSA: 'CBSAName' },
        { 'Sub ID': 'SubID' },
        { 'Cluster ID': 'ClstrID' },
        { 'RM ID': 'RMID' },
        { 'Geography Code': 'GeogCode' },
        { 'Geography ID': 'GeogID' },
        { 'Geography Name': 'GeogName' },
        { 'Property Type ID': 'PrpTypeID' },
        { 'Geography Type': 'GeogTyp' },
        { 'Geography Type ID': 'GeogTypID' },
        { 'CBSA ID': 'CBSAID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },

  mccombs_research_market: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Research Markets',
    category: 'McCombs.Markets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Research Markets',
      source: 'luketruitt1.mccombs',
      source_layer: 'researchmarket',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'RMName'],
      fields: [
        { 'RM Name': 'RMName' },
        { 'RM ID': 'RMID' },
        { 'Geography Code': 'GeogCode' },
        { 'Geography ID': 'GeogID' },
        { 'Geography Type ID': 'GeogTypeID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },

  mccombs_retail_submarkets: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Retail Submarkets',
    category: 'McCombs.Submarkets',
    groupName: 'boundary_layers',
    isBoundary: true,
    sourceTileset: {
      label: 'Retail Submarkets',
      source: 'luketruitt1.mccombs',
      source_layer: 'retailsubmarkets',
    },
    styleConfig: {
      colors: [Color.transparent],
    },
    dataConfig: {
      header: ['get', 'SubName'],
      fields: [
        { Market: 'RMName' },
        { Cluster: 'ClstrName' },
        { 'Property Type': 'PrpType' },
        { 'Location Type': 'LocType' },
        { CBSA: 'CBSAName' },
        { 'Sub ID': 'SubID' },
        { 'Cluster ID': 'ClstrID' },
        { 'RM ID': 'RMID' },
        { 'Geography Code': 'GeogCode' },
        { 'Geography ID': 'GeogID' },
        { 'Geography Name': 'GeogName' },
        { 'Property Type ID': 'PrpTypeID' },
        { 'Geography Type': 'GeogTyp' },
        { 'Geography Type ID': 'GeogTypID' },
        { 'CBSA ID': 'CBSAID' },
        { 'Country Code': 'CntryCode' },
        {
          'Area (sq mi)': {
            key: 'Area',
            expression: ['/', ['get', 'Area'], squareMetersPerSquareMile],
            format: 'integer',
          },
        },
      ],
    },
  },
};
