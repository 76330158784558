import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

export interface CBSAData {
  cbsa_id: number;
  geo_id: string;
  id: number;
  name: string;
  state_id: number;
}

export async function fetchCBSANameFromCensusCode(
  countyGeoId: string,
  stateGeoId: string,
  accessToken: string
): Promise<CBSAData | null> {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.get(`${env.API_V2}/county/county-by-geoids`, {
      headers,
      params: { 'county-geo-id': countyGeoId, 'state-geo-id': stateGeoId },
    });

    return response.data ?? null;
  } catch (error) {
    console.error('Error fetching CBSA data:', error);
    return null;
  }
}
