import {
  Bolt as BoltIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  GpsFixed as GpsFixedIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import {
  CustomPin,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { useCallback, useEffect } from 'react';
import { copyToClipboard } from '~/src/common/helpers/copyToClipboard';
import { getPointLocation } from '~/src/common/helpers/getPointPlace';
import {
  ModalNames,
  useModalDataContext,
} from '~/src/common/hooks/useModalDataContext';
import useContextMenuStore, {
  ContextMenuOption,
} from '~/src/features/context-menu/useContextMenuStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';

export const usePinsContextMenu = (pin: CustomPin) => {
  const { accessToken } = useAccessToken();

  const {
    evaluatedPinId,
    selectCustomPinId,
    setEvaluatedDemographicEntity,
    setEvaluatedPinId,
    setSelectedPinGroup,
    setPinToCopy,
    setClickedPOiFeature,
  } = useDynamicMapStore((state) => ({
    evaluatedPinId: state.evaluatedPinId,
    setEvaluatedPinId: state.setEvaluatedPinId,
    setEvaluatedDemographicEntity: state.setEvaluatedDemographicEntity,
    setSelectedPinGroup: state.setSelectedPinGroup,
    selectCustomPinId: state.selectCustomPinId,
    setPinToCopy: state.setPinToCopy,
    setClickedPOiFeature: state.setClickedPOiFeature,
  }));

  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);

  const { setContextMenu, position } = useContextMenuStore((state) => ({
    setContextMenu: state.setContextMenu,
    position: state.position,
  }));

  const { onOpenChange, onDataChange } = useModalDataContext();

  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const map = useMapContext();

  const centerMapOnPin = useCallback(
    (position: { lng: number; lat: number }) => {
      if (map != null) {
        map.flyTo({
          center: [position.lng, position.lat],
          essential: true,
        });
      }
    },
    [map]
  );

  const generateContextMenuOptions = useCallback(() => {
    if (customPinMethods == null) return [];
    const options: ContextMenuOption[] = [
      {
        icon: <BoltIcon fontSize="small" />,
        label: 'Get Pulse',
        onClick: () => {
          setClickedPOiFeature(null);
          setEvaluatedPinId(pin.id);
          setSelectedPinGroup(pin.group);
          selectCustomPinId(null);
          setEvaluatedDemographicEntity({
            type: 'point',
            pos: pin.pos,
          });
        },
      },
      ...(!isReadOnly
        ? [
            {
              icon: <EditIcon fontSize="small" />,
              label: 'Edit Pin',
              onClick: () => {
                selectCustomPinId(pin.id);
              },
            },
          ]
        : []),
      {
        icon: <GpsFixedIcon fontSize="small" />,
        label: 'Center on Pin',
        onClick: () => centerMapOnPin(pin.pos),
      },
      {
        icon: <ContentCopyIcon fontSize="small" />,
        label: `${pin.pos.lat.toFixed(5)}, ${pin.pos.lng.toFixed(5)}`,
        onClick: () => copyToClipboard(`${pin.pos.lat}, ${pin.pos.lng}`),
      },
      {
        icon: <ContentCopyIcon fontSize="small" />,
        label: 'Copy Address',
        onClick: async () => {
          const location = await getPointLocation(pin.pos.lng, pin.pos.lat);
          location.place && copyToClipboard(location.place.place_name);
        },
      },
      ...(!isReadOnly
        ? [
            {
              icon: <ContentCopyIcon fontSize="small" />,
              label: 'Duplicate Pin',
              onClick: () => setPinToCopy(pin.id),
            },
            {
              icon: <InventoryIcon fontSize="small" />,
              label: 'Create a template',
              onClick: () => {
                onOpenChange(ModalNames.CREATE_TEMPLATE, true);
                onDataChange(ModalNames.CREATE_TEMPLATE, {
                  template: {
                    id: crypto.randomUUID(),
                    group: pin.group,
                    keyValuePairs: pin.keyValuePairs,
                    tags: pin.tags,
                    label: `Template ${pin.label}`,
                  },
                });
              },
            },
            {
              icon: <DeleteIcon fontSize="small" />,
              label: 'Remove Pin',
              onClick: () => {
                customPinMethods.removePin(pin.id);
                selectCustomPinId(null);
                if (evaluatedPinId === pin.id) {
                  setEvaluatedPinId(null);
                }
              },
            },
          ]
        : []),
    ];
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customPinMethods,
    accessToken,
    setEvaluatedDemographicEntity,
    pin.pos,
    pin.id,
    pin.group,
    pin.keyValuePairs,
    pin.tags,
    pin.label,
    setEvaluatedPinId,
    setSelectedPinGroup,
    selectCustomPinId,
    setPinToCopy,
    onOpenChange,
    onDataChange,
    centerMapOnPin,
    evaluatedPinId,
  ]);

  //update context menu options if the array changes
  useEffect(() => {
    //don't open the context menu if it's closed
    //make sure the context menu is for the current pin
    if (
      position !== null &&
      position.lng === pin.pos.lng &&
      position.lat === pin.pos.lat
    ) {
      const options = generateContextMenuOptions();
      setContextMenu({
        position: pin.pos,
        offset: [0, -50],
        options,
      });
    }
  }, [generateContextMenuOptions, pin.pos, setContextMenu, position]);

  const handleContextMenu = useCallback(
    (e: MouseEvent) => {
      if (customPinMethods == null) return;

      e.preventDefault();
      e.stopPropagation();

      selectCustomPinId(null);
      const options = generateContextMenuOptions();

      setContextMenu({
        position: pin.pos,
        offset: [0, -50],
        options,
      });
    },
    [
      generateContextMenuOptions,
      pin.pos,
      selectCustomPinId,
      setContextMenu,
      customPinMethods,
    ]
  );

  return handleContextMenu;
};
