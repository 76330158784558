import { Box, Button } from '@mui/material';
import { TargetType } from '@plotr/plotr-multiplayer-data/src';
import { useState } from 'react';
import useRulesets from '~/src/features/dynamic-map/hooks/useRulesets';
import AddRuleForm from '~/src/features/rules-collapse/AddRule.form';
import RulesList from '~/src/features/rules-collapse/RulesList';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';

type RuleCollapseSection = {
  targetType: TargetType;
};

const RulesCollapseSection = ({ targetType }: RuleCollapseSection) => {
  const [isCreating, setIsCreating] = useState(false);
  const [editingRuleId, setEditingRuleId] = useState<string | null>(null);
  const rules = useRulesets()
    .filter((rule) => rule.targetType === targetType)
    ?.sort((a, b) => a?.sourceType.localeCompare(b?.sourceType));
  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);

  const editingRule = rules.find((rule) => rule.id === editingRuleId);

  const startEditing = (id: string) => {
    setEditingRuleId(id);
  };

  const closeForm = () => {
    if (isCreating) {
      setIsCreating(false);
    }

    if (editingRuleId) {
      setEditingRuleId(null);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight={'30rem'}
      sx={{ overflowY: 'auto' }}
    >
      {!isCreating && !editingRule ? (
        <>
          <Button
            disabled={isReadOnly}
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => setIsCreating((prev) => !prev)}
          >
            Create a new rule
          </Button>
          <RulesList rules={rules} startEditing={startEditing} />
        </>
      ) : (
        <AddRuleForm
          targetType={targetType}
          editingRule={editingRule}
          closeForm={closeForm}
        />
      )}
    </Box>
  );
};

export default RulesCollapseSection;
