import { Alert, AlertProps, Box } from '@mui/material';
import KeyValueTable from '../KeyValueTable';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import { useEffect } from 'react';
import { Control } from 'react-hook-form';
import { EditPinSchema } from '../schema';

type PropertiesTabProps = {
  control: Control<EditPinSchema>;
  isEditing: boolean;
};

const PropertiesTab = ({ isEditing, control }: PropertiesTabProps) => {
  const {
    setAlertMessagePin,
    alertMessagePin,
    alertSeverityPin: alertSeverity,
  } = useDynamicMapStore((state) => ({
    setAlertMessagePin: state.setAlertMessagePin,
    alertMessagePin: state.alertMessagePin,
    alertSeverityPin: state.alertSeverityPin,
  }));
  const clearAlert = () => setAlertMessagePin('');

  const stopPropagation = (e: React.WheelEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (alertMessagePin) {
      const timeout = setTimeout(() => {
        setAlertMessagePin('');
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return;
  }, [alertMessagePin, setAlertMessagePin]);

  return (
    <Box onWheel={stopPropagation}>
      <KeyValueTable isEditing={isEditing} control={control} />
      {alertMessagePin && (
        <Alert
          severity={alertSeverity as AlertProps['severity']}
          onClose={clearAlert}
          sx={{ margin: 2 }}
        >
          {alertMessagePin}
        </Alert>
      )}
    </Box>
  );
};

export default PropertiesTab;
