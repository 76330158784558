import { Grid } from '@mui/material';
import { parseEnv } from '@plotr/common-utils/src';
import {
  CustomTerritory,
  TerritoryType,
} from '@plotr/plotr-multiplayer-data/src';
import { featureCollection, polygon } from '@turf/helpers';
import axios from 'axios';
import {
  Feature,
  Geometry,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'geojson';
import { useEffect } from 'react';
import { MapRef } from 'react-map-gl';
import useGeometryCache, {
  GeometryCacheEntry,
} from '~/src/common/hooks/useGeometryCache';
import { getBoundingBoxFromBoundaries } from '~/src/features/custom-territories/helpers/BoundariesExtermePoints';
import fitMapToBoundary from '~/src/features/dynamic-map/helpers/fitMapToBoundary';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import { BoundaryCard } from './BoundaryCard';

const env = parseEnv({ API_V2: process.env.API_V2 });

type BoundaryCardsProps = {
  removeAllBoundaries: () => void;
  removeSingleBoundary: (boundaryId: string) => void;
  editSingleBoundary: (boundaryId: string | null) => void;
  activeEditBoundaryId: string | null;
  disableActions?: boolean;
  isEditEnabled: boolean;
};

export const flyToBoundary = async (
  boundaryId: string,
  territory: CustomTerritory,
  map: MapRef | null,
  geometryCache: {
    get: (key: string) => GeometryCacheEntry | null;
    set: (
      key: string,
      geometry: Geometry,
      metadata?: Record<string, unknown>
    ) => void;
  }
) => {
  //return if not map ready or territory has no boundaries
  if (map == null || !boundaryId) return;

  const boundary = territory?.boundaries[boundaryId];

  let data: Feature<Polygon> = {} as Feature<Polygon>;
  if (territory.type !== TerritoryType.Custom) {
    // First check if we have cached geometry for this territory
    const zoomRangeFourToSixteen = new Array(12).fill(0).map((_, i) => i + 4);
    const potentialCacheKeys = zoomRangeFourToSixteen.map(
      (zoom) => `${territory.id}::${zoom}`
    );
    const cachedTerritories = potentialCacheKeys.map((key) =>
      geometryCache.get(key)
    );
    const isGeometryWithCoordinates = (
      geometry: Geometry
    ): geometry is
      | Point
      | LineString
      | Polygon
      | MultiPoint
      | MultiLineString
      | MultiPolygon => {
      return 'coordinates' in geometry;
    };

    const highestQualityCached =
      cachedTerritories.reduce<GeometryCacheEntry | null>((acc, curr) => {
        if (
          curr &&
          isGeometryWithCoordinates(curr.data) &&
          acc &&
          isGeometryWithCoordinates(acc?.data)
        ) {
          if (
            !acc ||
            curr.data.coordinates.length > (acc.data.coordinates?.length ?? 0)
          ) {
            return curr;
          }
        }
        return acc;
      }, null);
    // If we have cached geometry, use it to fit the map
    if (highestQualityCached != null) {
      fitMapToBoundary(
        map,
        highestQualityCached.data as unknown as Feature<Polygon>
      );
      return;
    }

    // If we don't have cached geometry, fetch it from the API
    const zipcodes = Object.values(territory.boundaries)
      ?.filter((item) => item?.id === boundaryId)
      .map((boundary) => boundary.id.padStart(5, '0'));

    try {
      const response = await axios.post(`${env.API_V2}/merge`, {
        zipcodes,
        bbox: true,
      });
      data = response.data;
    } catch (err) {
      console.error('Error fetching merged boundaries:', err);
    }
  } else {
    const boundingBoxCoordinates = getBoundingBoxFromBoundaries([boundary!]);
    data = featureCollection([
      polygon(boundingBoxCoordinates),
    ]) as unknown as Feature<Polygon>;
  }

  fitMapToBoundary(map, data);
};

export const BoundaryCards = ({
  activeEditBoundaryId,
  removeSingleBoundary,
  editSingleBoundary,
  disableActions,
  isEditEnabled = false,
}: BoundaryCardsProps) => {
  const { customTerritories } = useCustomTerritories();
  const evaluatedTerritoryId = useDynamicMapStore(
    (state) => state.evaluatedTerritoryId
  );

  const territory = customTerritories.find(
    (territory: CustomTerritory) => territory.id === evaluatedTerritoryId
  )!;
  const geometryCache = useGeometryCache();

  const map = useMapContext();

  useEffect(() => {
    return () => {
      if (activeEditBoundaryId) {
        editSingleBoundary(null);
      }
    };
  }, [activeEditBoundaryId, editSingleBoundary]);

  const handleBoundaryCardHover = (boundaryId: string, isHovering: boolean) => {
    if (map == null) return;

    const filteredFeatures = map
      .querySourceFeatures('custom-drawn-territories', {
        sourceLayer: 'custom-drawn-territories-fill',
      })
      ?.filter((feature) => {
        const boundary = JSON.parse(feature?.properties?.boundary ?? '{}');
        return boundary?.id === boundaryId;
      });

    const zipCodesFeatures =
      map
        .querySourceFeatures('custom-territories', {
          sourceLayer: 'insights_zipcode',
        })
        ?.filter((feature) =>
          Object.keys(territory?.boundaries ?? {}).includes(`${feature?.id}`)
        ) ?? [];

    if (zipCodesFeatures?.length > 0) {
      map.setFeatureState(
        {
          source: 'defined-territories',
          id: territory?.id,
        },
        { hover: isHovering }
      );
    }

    filteredFeatures?.forEach((feature) => {
      map.setFeatureState(
        { source: 'custom-drawn-territories', id: feature.id },
        { hover: isHovering }
      );
    });
  };

  return (
    <Grid container spacing={1} flexShrink={1} overflow="auto">
      {territory != null &&
        Object.values(territory.boundaries ?? {}).map((boundary) => (
          <Grid item xs={12} key={boundary.id}>
            <BoundaryCard
              boundaryId={boundary.id}
              onRemove={() => removeSingleBoundary(boundary.id)}
              onEdit={() => editSingleBoundary(boundary.id)}
              activeEditBoundaryId={activeEditBoundaryId}
              disableActions={disableActions}
              isEditEnabled={isEditEnabled}
              onFlyTo={(boundaryId: string) =>
                flyToBoundary(boundaryId, territory, map, geometryCache)
              }
              onHover={handleBoundaryCardHover}
            />
          </Grid>
        ))}
    </Grid>
  );
};
