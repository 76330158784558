import { Box, Typography } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import turfBbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Layer, Map, MapRef, NavigationControl, Source } from 'react-map-gl';
import { MobileDataConfig, PulseReportConfig } from '~/src/constants';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useDemographicStore from '../demographic-point-lookup/hooks/useDemographicStore';
import { filterSurroundingPins } from '../dynamic-map-controller/helpers/filterSurroundingPins';
import { RecenterControl } from '../dynamic-map/helpers/RecenterControl';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import { ReportPin, ReportPinProps } from './ReportPin';
import usePulseReportStore, { DataSections } from './hooks/usePulseReportStore';

const env = parseEnv({
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
});

const mapStyle = 'mapbox://styles/mapbox/streets-v12';
const { MAX_SURROUNDING_PIN_DISTANCE } = MobileDataConfig;
const { EVALUATED_PIN_COLOR } = PulseReportConfig;

export const TradeAreaMap = ({ onMapLoad }: { onMapLoad?: () => void }) => {
  const { setMapRef, setMapInitialViewState, setMapPins } = usePulseReportStore(
    (state) => ({
      setMapRef: state.setMapRef,
      setMapInitialViewState: state.setMapInitialViewState,
      setMapPins: state.setMapPins,
    })
  );
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const { customPins: pins } = useCustomPins();
  const { boundaryData, boundaryType } = useDemographicStore((state) => ({
    boundaryData: state.boundaryData,
    boundaryType: state.boundaryType,
  }));

  const evaluatedPin = pins.find((pin) => pin.id === evaluatedPinId);

  const mapRef = useRef<MapRef>(null);

  const [zoomLevel, setZoomLevel] = useState(10);
  const [lastLocation, setLastLocation] = useState({
    lat: evaluatedPin?.pos.lat ?? 0,
    lng: evaluatedPin?.pos.lng ?? 0,
  });

  const bbox = useMemo(() => {
    if (!boundaryData) return null;
    return turfBbox(boundaryData);
  }, [boundaryData]);

  const mapPins: ReportPinProps[] = useMemo(() => {
    if (!evaluatedPin) return [];

    const evaluatedPinMarker = {
      pos: evaluatedPin.pos,
      isSelected: false,
      isEvaluated: true,
      onClick: () => {},
      color: EVALUATED_PIN_COLOR,
      zIndex: 1,
    };

    const surroundingPins = filterSurroundingPins({
      evaluatedPin,
      surroundingPins: pins.filter((pin) => pin.id !== evaluatedPin.id),
      maxDistance: MAX_SURROUNDING_PIN_DISTANCE,
    });

    const surroundingPinsMarkers = surroundingPins.map((pin) => ({
      pos: pin.pos,
      isSelected: false,
      onClick: () => {},
    }));

    return [evaluatedPinMarker, ...surroundingPinsMarkers];
  }, [pins, evaluatedPin]);

  useEffect(() => {
    if (bbox && mapRef.current) {
      mapRef.current.fitBounds(
        [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ],
        { padding: 10 }
      );
    }
  }, [bbox]);

  const handleRecenter = () => {
    if (mapRef.current && bbox) {
      mapRef.current.fitBounds(
        [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ],
        { padding: 10 }
      );
    }
  };

  const initialViewState = useMemo(() => {
    if (bbox) {
      return {
        bounds: [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[3]],
        ] as [[number, number], [number, number]],
        fitBoundsOptions: { padding: 10 },
      };
    }
    return {
      zoom: zoomLevel,
      latitude: lastLocation.lat,
      longitude: lastLocation.lng,
      fitBoundsOptions: { padding: 10 },
    };
  }, [bbox, zoomLevel, lastLocation]);

  useEffect(() => {
    setMapRef(DataSections.TradeArea, mapRef);
  }, [mapRef, setMapRef]);

  useEffect(() => {
    setMapInitialViewState(DataSections.TradeArea, initialViewState);
  }, [initialViewState, setMapInitialViewState]);

  useEffect(() => {
    setMapPins(DataSections.TradeArea, mapPins);
  }, [mapPins, setMapPins]);

  return (
    <Box
      data-section="trade-area"
      flex={1}
      width="100%"
      p={2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" fontWeight={500} mb={2}>
        Trade Area
      </Typography>
      <Box
        flex={1}
        width="100%"
        pl={2}
        sx={{
          minHeight: '35rem',
          maxHeight: '35rem',
        }}
      >
        {initialViewState && (
          <Map
            scrollZoom={false}
            styleDiffing={false}
            ref={mapRef}
            mapboxAccessToken={env.MAPBOX_API_KEY}
            mapStyle={mapStyle}
            attributionControl={true}
            initialViewState={initialViewState}
            onLoad={() => onMapLoad?.()}
            style={{ width: '100%', height: '100%' }}
            preserveDrawingBuffer={true}
          >
            <RecenterControl onRecenter={handleRecenter} />
            <NavigationControl
              position="bottom-right"
              showCompass={true}
              showZoom={true}
              visualizePitch={false}
            />
            {boundaryData && (
              <Source
                id="search-radius"
                type="geojson"
                data={
                  boundaryType === 'radius'
                    ? boundaryData
                    : turfBuffer(boundaryData, 0.3, {
                        units: 'kilometers',
                      })
                }
              >
                <Layer
                  id="custom-drawn-territories-line"
                  type="line"
                  source="custom-drawn-territories"
                  paint={{
                    'line-color': [
                      'case',
                      ['boolean', ['feature-state', 'hover'], false],
                      ['get', 'hoverColor'], // Use hover color when hovered
                      ['get', 'color'], // Normal color otherwise
                    ],
                    'line-width': 2,
                  }}
                  layout={{
                    visibility: 'visible',
                  }}
                />
              </Source>
            )}
            {mapPins.map((props, index) => (
              <ReportPin key={index} {...props} />
            ))}
          </Map>
        )}
      </Box>
    </Box>
  );
};
