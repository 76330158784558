import { Player } from '@lottiefiles/react-lottie-player';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { memo } from 'react';

interface LogoAnimationProps {
  src: string | Record<string, unknown>;
  size?: number;
  sx?: SxProps<Theme>;
  label?: string;
}

const LogoAnimation = memo(function LogoAnimation({
  src,
  size = 60,
  sx,
  label,
}: LogoAnimationProps) {
  return (
    <Box
      {...(label && {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <Box sx={{ width: `${size}px`, height: `${size}px`, ...sx }}>
        <Player src={src} loop autoplay />
      </Box>
      {label && <Typography variant="caption">{label}</Typography>}
    </Box>
  );
});

LogoAnimation.displayName = 'LogoAnimation';
export default LogoAnimation;
