import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import { ReportItem, useReportMatrixes } from './services/pulse-service';
import { getOrderComparator } from './helpers/getOrderComparator';
import { HeadCell, Order, ReportWithDriveTime } from './types/reportTable';

const HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'driveTime',
    label: 'Drive Time Minutes',
  },
  {
    id: 'driveDistance',
    label: 'Radial Miles',
  },
];

const ReportRow = ({
  report,
  index,
  isSelected,
  driveTime,
  driveDistance,
  onClick,
}: {
  report: ReportItem;
  index: number;
  isSelected: boolean;
  driveTime: number | null;
  driveDistance: number | null;
  onClick: () => void;
}) => {
  return (
    <TableRow
      hover
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      <TableCell
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isSelected ? '#ff9900' : '#C3C3C3',
        }}
        width={8}
      >
        {index + 1}
      </TableCell>
      <TableCell>{report.name}</TableCell>
      <TableCell>{report.address}</TableCell>
      <TableCell>{driveTime ? Math.round(driveTime) : 'N/A'}</TableCell>
      <TableCell>{driveDistance ? driveDistance.toFixed(2) : 'N/A'}</TableCell>
    </TableRow>
  );
};

export const ReportTable = ({
  reports,
  selectedPinId = -1,
  isGroupConfigured = true,
  groupName = 'competitors',
  onRowClick,
}: {
  reports: ReportItem[];
  selectedPinId: number;
  isGroupConfigured?: boolean;
  groupName?: string;
  onRowClick: (index: number) => void;
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof ReportWithDriveTime>('driveDistance');

  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const { customPins: pins } = useCustomPins();
  const evaluatedPin = useMemo(
    () => pins.find((pin) => pin.id === evaluatedPinId),
    [pins, evaluatedPinId]
  );

  const { data: matrixes } = useReportMatrixes(
    reports,
    evaluatedPin?.pos ?? null
  );

  const reportsWithDriveTime: ReportWithDriveTime[] = useMemo(() => {
    return reports.map((report, index) => ({
      ...report,
      driveTime: matrixes
        ? matrixes[0]?.durations?.[0]?.[index + 1] / 60
        : null,
      driveDistance: matrixes
        ? matrixes[0]?.distances?.[0]?.[index + 1] * 0.000621371
        : null,
      // save the index so it's not affected by sorting
      index,
    }));
  }, [reports, matrixes]);

  const rows = useMemo(
    () => [...reportsWithDriveTime].sort(getOrderComparator(order, orderBy)),
    [reportsWithDriveTime, order, orderBy]
  );

  const handleRequestSort = (property: keyof ReportWithDriveTime) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box
      sx={{
        minHeight: '100px', // Set a minimum height for the table container
        maxHeight: '400px', // Set a maximum height for the table container
        overflow: 'auto', // Enable scrolling for the table container
      }}
    >
      <Table stickyHeader data-section="table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 0 }} />
            {HEAD_CELLS.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            rows.map((report) => (
              <ReportRow
                key={report.id + report.index}
                report={report}
                index={report.index}
                isSelected={selectedPinId === report.index}
                driveTime={report.driveTime}
                driveDistance={report.driveDistance}
                onClick={() => onRowClick(report.index)}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="body1" textAlign="center">
                  {isGroupConfigured
                    ? 'No reports found for this point'
                    : `No ${groupName} grouping found. This insight requires a ${groupName} points of interest grouping`}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
