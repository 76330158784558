import { AddCircleOutline, Bolt as BoltIcon } from '@mui/icons-material';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  CustomTerritory,
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as randomUUID } from 'uuid';

import usePrevious from '~/src/common/hooks/usePrevious';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import RemoveAssociationModal from '../../custom-pins/RemoveAssociationModal';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useFlyToTerritory from '../../dynamic-map/hooks/useFlyToTerritory';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import AddEditTerritoryDialog from '../AddEditTerritoryDialog';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';
import { BoundaryCards } from '../territory-cards/BoundaryCards';
import TerritoryCard from '../territory-cards/TerritoryCard';
import CustomTerritoryKVTable from './CustomTerritoryKVTable';

const CustomTerritoryCardView = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
  const [selectedTerritory, setSelectedTerritory] =
    useState<CustomTerritory | null>(null);
  const [boundaryToRemove, setBoundaryToRemove] = useState<string | null>(null);
  const [removingAllBoundaries, setRemovingAllBoundaries] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [removeAssociationOpen, setRemoveAssociationModal] = useState(false);

  const { customTerritories } = useCustomTerritories();
  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;

  const map = useMapContext();

  const {
    selectedTerritoryGroup,
    evaluatedTerritoryId,
    setEvaluatedTerritoryId,
    setEvaluatedDemographicEntity,
    isDrawingTerritory,
    setIsDrawingTerritory,
    editingTerritoryBoundaryId,
    setEditingTerritoryBoundaryId,
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    evaluatedPinId,
    setEvaluatedPinId,
  } = useDynamicMapStore((state) => ({
    selectedTerritoryGroup: state.selectedTerritoryGroup,
    evaluatedTerritoryId: state.evaluatedTerritoryId,
    setEvaluatedTerritoryId: state.setEvaluatedTerritoryId,
    setEvaluatedDemographicEntity: state.setEvaluatedDemographicEntity,
    isDrawingTerritory: state.isDrawingTerritory,
    setIsDrawingTerritory: state.setIsDrawingTerritory,
    editingTerritoryBoundaryId: state.editingTerritoryBoundaryId,
    setEditingTerritoryBoundaryId: state.setEditingTerritoryBoundaryId,
    alertMessage: state.alertMessageTerritory,
    setAlertMessage: state.setAlertMessageTerritory,
    alertSeverity: state.alertSeverityTerritory,
    setAlertSeverity: state.setAlertSeverityTerritory,
    evaluatedPinId: state.evaluatedPinId,
    setEvaluatedPinId: state.setEvaluatedPinId,
  }));

  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);

  const territoriesInGroup = useMemo(
    () =>
      customTerritories.filter(
        (territory: CustomTerritory) =>
          territory.group === selectedTerritoryGroup
      ),
    [customTerritories, selectedTerritoryGroup]
  );

  const previousTerritoriesInGroup = usePrevious(territoriesInGroup);

  const newTerritoryId = territoriesInGroup.find(
    (territory) =>
      !previousTerritoriesInGroup?.some(
        (prevTerritory) => prevTerritory.id === territory.id
      )
  )?.id;

  const newTerritoryRef = useRef<HTMLDivElement | null>(null);

  // Scroll to newly created territory
  useEffect(() => {
    if (newTerritoryId && newTerritoryRef.current) {
      newTerritoryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newTerritoryId]);

  const handleTerritorySelect = useCallback(
    ({
      territory,
      mode,
    }: {
      territory?: CustomTerritory;
      mode: 'add' | 'edit';
    }) => {
      setModalMode(mode);
      if (mode === 'add') {
        setOpen(true);
        const newTerritory = {
          id: randomUUID(),
          label: '',
          group: selectedTerritoryGroup,
          boundaries: {},
          tags: [],
          type: selectedTerritory?.type,
          keyValuePairs: {},
        };

        setSelectedTerritory(newTerritory as CustomTerritory);
        setEvaluatedTerritoryId(newTerritory.id);
      } else if (mode === 'edit' && territory) {
        setOpen(true);
        setSelectedTerritory(territory);
        setEvaluatedTerritoryId(territory.id);
      }
    },
    [selectedTerritoryGroup, setEvaluatedTerritoryId, selectedTerritory?.type]
  );

  const handleAddEditTerritoryClose = () => {
    setOpen(false);
    setSelectedTerritory(null);
    setModalMode('add');
  };

  const handleSaveTerritory = (territory: CustomTerritory) => {
    if (customTerritoryMethods) {
      // Update or add the territory
      const existingTerritory = customTerritories.find(
        (t) => t.id === territory.id
      );
      if (existingTerritory) {
        customTerritoryMethods.setLabel(territory.id, territory.label);
        customTerritoryMethods.setGroup(territory.id, territory.group);
        if (territory.type) {
          customTerritoryMethods.setType(territory.id, territory.type);
        }
      } else {
        customTerritoryMethods.addTerritory(territory);
      }
    }
    handleAddEditTerritoryClose();
  };

  const confirmRemoval = () => {
    if (removingAllBoundaries) {
      // Remove all boundaries
      if (evaluatedTerritoryId && customTerritoryMethods) {
        customTerritoryMethods.removeAllBoundaries(evaluatedTerritoryId);
      }
    } else if (
      boundaryToRemove &&
      evaluatedTerritoryId &&
      customTerritoryMethods
    ) {
      // Remove single boundary
      customTerritoryMethods.removeBoundary(
        evaluatedTerritoryId,
        boundaryToRemove
      );
    }

    // Reset modal state
    setDeleteOpen(false);
    setBoundaryToRemove(null);
    setRemovingAllBoundaries(false);
  };

  const handleRemoveBoundary = (boundaryId: string) => {
    // Set state to trigger modal and pass the boundary ID
    setBoundaryToRemove(boundaryId);
    setDeleteOpen(true);
  };

  const handleEditBoundary = useCallback(
    (boundaryId: string | null) => {
      if (editingTerritoryBoundaryId) {
        setEditingTerritoryBoundaryId(null);
      } else {
        setEditingTerritoryBoundaryId(boundaryId);
      }
    },
    [editingTerritoryBoundaryId, setEditingTerritoryBoundaryId]
  );

  const removeAllBoundaries = () => {
    // Set state to trigger modal for removing all boundaries
    setRemovingAllBoundaries(true);
    setDeleteOpen(true);
  };

  const evaluatedTerritory = customTerritories.find(
    (t) => t.id === evaluatedTerritoryId
  );

  const isCustomEvaluatedTerritory =
    evaluatedTerritory?.type === TerritoryType.Custom;

  const toggleEditMode = () => {
    evaluatedPinId && setEvaluatedPinId(null);
    evaluatedTerritoryId &&
      setEvaluatedDemographicEntity({
        type: 'territory',
        id: evaluatedTerritoryId,
      });
    setIsDrawingTerritory(!isDrawingTerritory);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const clearAlert = () => setAlertMessage('');

  const [tagInput, setTagInput] = useState('');

  useEffect(() => {
    if (alertMessage) {
      const timeout = setTimeout(() => {
        setAlertMessage('');
      }, 1500);
      return () => clearTimeout(timeout);
    }
    return;
  }, [alertMessage, setAlertMessage]);

  //when the component mounts, if there is a territory in the selected group without a type, go straight into editing that territory
  useEffect(() => {
    if (
      selectedTerritoryGroup &&
      territoriesInGroup.length > 0 &&
      territoriesInGroup.some((t) => !t.type) // Check for any territory missing a type
    ) {
      if (isReadOnly) return;
      const territoryToEdit = territoriesInGroup.find((t) => !t.type); // Find the first territory without a type
      handleTerritorySelect({
        mode: 'edit',
        territory: territoryToEdit,
      });
    }
  }, [
    selectedTerritoryGroup,
    territoriesInGroup,
    handleTerritorySelect,
    isReadOnly,
  ]);

  useEffect(() => {
    if (isReadOnly) return;
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isDrawingTerritory) {
          setIsDrawingTerritory(false);
        }
        if (editingTerritoryBoundaryId) {
          setEditingTerritoryBoundaryId(null);
        }
      }
    };

    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault();
      if (isDrawingTerritory) {
        setIsDrawingTerritory(false);
      }
      if (editingTerritoryBoundaryId) {
        setEditingTerritoryBoundaryId(null);
      }
    };

    window.addEventListener('keydown', handleEsc);
    window.addEventListener('contextmenu', handleRightClick);

    return () => {
      window.removeEventListener('keydown', handleEsc);
      window.removeEventListener('contextmenu', handleRightClick);
    };
  }, [
    editingTerritoryBoundaryId,
    isDrawingTerritory,
    isReadOnly,
    setEditingTerritoryBoundaryId,
    setIsDrawingTerritory,
  ]);

  useEffect(() => {
    return () => {
      if (isDrawingTerritory && !evaluatedTerritory) {
        setIsDrawingTerritory(false);
      }
    };
  }, [evaluatedTerritory, isDrawingTerritory, setIsDrawingTerritory]);

  const flyToTerritory = useFlyToTerritory();

  const getPulse = () => {
    if (evaluatedTerritory == null) return;

    flyToTerritory(evaluatedTerritory);
    setEvaluatedDemographicEntity({
      type: 'territory',
      id: evaluatedTerritory.id,
    });
  };

  const handleHoverTerritoryCard = (
    territory: CustomTerritory,
    isHovering: boolean
  ) => {
    if (map == null) return;

    const drawnFeatures =
      map.querySourceFeatures('custom-drawn-territories', {
        sourceLayer: 'custom-drawn-territories-fill',
      }) ?? [];
    const zipCodesFeatures =
      map
        .querySourceFeatures('custom-territories', {
          sourceLayer: 'insights_zipcode',
        })
        ?.filter((feature) =>
          Object.keys(territory?.boundaries ?? {}).includes(`${feature?.id}`)
        ) ?? [];

    const filteredFeatures = drawnFeatures?.filter((feature) => {
      return feature?.properties?.territoryId === territory?.id;
    });

    filteredFeatures?.forEach((feature) => {
      map.setFeatureState(
        { source: 'custom-drawn-territories', id: feature.id },
        { hover: isHovering }
      );
    });

    if (zipCodesFeatures?.length > 0) {
      map.setFeatureState(
        {
          source: 'defined-territories',
          id: territory?.id,
        },
        { hover: isHovering }
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={1}>
      <AddEditTerritoryDialog
        open={open}
        territory={selectedTerritory}
        mode={modalMode}
        onClose={handleAddEditTerritoryClose}
        onSave={handleSaveTerritory}
        group={selectedTerritoryGroup || 'Default Group'}
      />
      <DeleteConfirmationDialog
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={confirmRemoval}
        itemName={
          removingAllBoundaries
            ? 'all boundaries'
            : boundaryToRemove
              ? `boundary ${boundaryToRemove}`
              : undefined
        }
      />
      {evaluatedTerritory != null ? (
        <Box display="flex" flexDirection="column" height="100%" gap={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <TerritoryCard
              territory={evaluatedTerritory}
              onEdit={() =>
                handleTerritorySelect({
                  mode: 'edit',
                  territory: evaluatedTerritory,
                })
              }
              onHover={handleHoverTerritoryCard}
            />
          </Box>
          <Box display="flex" justifyContent="space-evenly" padding={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<BoltIcon />}
              onClick={getPulse}
              disabled={
                !!editingTerritoryBoundaryId ||
                isDrawingTerritory ||
                Object.keys(evaluatedTerritory?.boundaries ?? {}).length < 1
              }
            >
              Get Pulse
            </Button>
            <Button
              variant={isDrawingTerritory ? 'contained' : 'outlined'}
              onClick={toggleEditMode}
              color={
                isDrawingTerritory && isCustomEvaluatedTerritory
                  ? 'error'
                  : 'secondary'
              }
              disabled={!!editingTerritoryBoundaryId || isReadOnly}
            >
              {isDrawingTerritory
                ? isCustomEvaluatedTerritory
                  ? 'Cancel Drawing'
                  : 'Save Territory'
                : isCustomEvaluatedTerritory
                  ? 'Draw Territory'
                  : 'Edit Territory'}
            </Button>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="territory tabs"
            //align tabs center using prop
            centered
          >
            <Tab label="Boundaries" />
            <Tab label="Properties" />
            <Tab label="Tags" />
          </Tabs>

          {selectedTab === 0 && (
            <>
              <Box
                sx={{
                  overflowY: 'auto',
                  marginTop: 0.5,
                  flexShrink: 1,
                  flexGrow: 1,
                }}
              >
                <BoundaryCards
                  removeAllBoundaries={removeAllBoundaries}
                  removeSingleBoundary={handleRemoveBoundary}
                  editSingleBoundary={handleEditBoundary}
                  activeEditBoundaryId={editingTerritoryBoundaryId}
                  disableActions={isDrawingTerritory || isReadOnly}
                  isEditEnabled={isCustomEvaluatedTerritory}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 2,
                  flexShrink: 0,
                }}
              >
                <Button
                  onClick={removeAllBoundaries}
                  disabled={
                    !!editingTerritoryBoundaryId ||
                    isDrawingTerritory ||
                    isReadOnly
                  }
                  sx={{
                    minWidth: 'auto',
                    padding: '10px',
                    color: 'red',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'red',
                      color: 'white',
                    },
                  }}
                >
                  Delete all boundaries
                </Button>
              </Box>
            </>
          )}

          {selectedTab === 1 && (
            <>
              {alertMessage && (
                <Alert
                  severity={alertSeverity as AlertProps['severity']}
                  onClose={clearAlert}
                  sx={{ margin: 2 }}
                >
                  {alertMessage}
                </Alert>
              )}
              <CustomTerritoryKVTable territory={evaluatedTerritory} />
            </>
          )}

          {selectedTab === 2 &&
            evaluatedTerritory &&
            customTerritoryMethods !== null && (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4} mt={1}>
                  <TextField
                    id="tag"
                    label="Add Tag"
                    variant="outlined"
                    size="small"
                    value={tagInput}
                    disabled={isReadOnly}
                    onChange={(e) => setTagInput(e.target.value)}
                    inputProps={{ maxLength: 30 }}
                    onKeyDown={(keyEvent) => {
                      const finalValue = tagInput.toLowerCase().trim();
                      if (keyEvent.key === 'Enter' && finalValue.length > 0) {
                        if (
                          evaluatedTerritory.tags &&
                          evaluatedTerritory.tags.includes(finalValue)
                        ) {
                          setAlertMessage(
                            'A tag with this name already exists! Please choose another name.'
                          );
                          setAlertSeverity('error');
                        } else {
                          customTerritoryMethods?.addTag(
                            evaluatedTerritory.id,
                            finalValue
                          );
                        }
                        setTagInput('');
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {evaluatedTerritory.tags &&
                  evaluatedTerritory.tags.map((tag: string) => (
                    <Grid item key={tag}>
                      <Chip
                        size="small"
                        label={tag}
                        color="primary"
                        onDelete={() => {
                          customTerritoryMethods?.removeTag(
                            evaluatedTerritory.id,
                            tag
                          );
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              overflowY: 'auto',
              marginTop: 0.5,
              flexShrink: 1,
            }}
          >
            <Grid container spacing={1}>
              {territoriesInGroup
                .map((territory) => (
                  <Grid item xs={isMd ? 12 : 6} key={territory.id}>
                    <TerritoryCard
                      cardRef={
                        territory.id === newTerritoryId ? newTerritoryRef : null
                      }
                      key={territory.id}
                      territory={territory}
                      onEdit={() => {
                        handleTerritorySelect({ mode: 'edit', territory });
                      }}
                      onHover={handleHoverTerritoryCard}
                    />
                  </Grid>
                ))
                .sort((a, b) =>
                  a.props.territory?.label.localeCompare(
                    b.props.territory?.label
                  )
                )}
            </Grid>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <Tooltip title="Add New Territory">
              <span>
                <IconButton
                  disabled={isReadOnly}
                  onClick={() => handleTerritorySelect({ mode: 'add' })}
                  sx={{
                    minWidth: 'auto',
                    color: 'primary.main',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: 'primary.dark',
                    },
                  }}
                >
                  <AddCircleOutline sx={{ fontSize: '2rem' }} />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
        </>
      )}
      {evaluatedTerritoryId && removeAssociationOpen && (
        <RemoveAssociationModal
          territoryId={evaluatedTerritoryId}
          onClose={() => {
            setRemoveAssociationModal(false);
          }}
          open={removeAssociationOpen}
        />
      )}
    </Box>
  );
};

export default CustomTerritoryCardView;
