import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Close as CloseIcon,
  DragIndicator as DragIndicatorIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import formatNumber from '~/src/common/helpers/formatNumber';
import { colorToRGBA } from '~/src/common/helpers/hexToRGBA';
import { customerLayers } from '~/src/global/constants/customerLayersConfig';
import { getEnterpriseLayers } from '~/src/global/constants/enterpriseLayersConfig';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { LayerConfig } from '../../dynamic-map-controller/components/MapLayersMenu';
import getMapLayerDefinitions from '../../dynamic-map-controller/data/getMapLayerDefinitions';
import useCustomTerritories from '../hooks/useCustomTerritories';
import useLayersStore, {
  LayerCard,
  formatValue,
} from '../hooks/useLayersStore';
import useTrafficStore from '../hooks/useTrafficStore';

const CUSTOM_LAYERS_COLOR_BOX = '#FFF';

function generateSharpGradient(colors: string[]): string {
  const step = 100 / colors.length;
  const gradientParts = colors.map((color, index) => {
    return `${colorToRGBA(color)} ${index * step}%, ${colorToRGBA(color)} ${(index + 1) * step}%`;
  });
  return `linear-gradient(to right, ${gradientParts.join(', ')})`;
}

const MapLegendContainer = (props: { children: React.ReactNode }) => {
  const [expanded, setExpanded] = useState(true);
  const { showData, setShowData } = useLayersStore((state) => ({
    showData: state.showData,
    setShowData: state.setShowData,
  }));

  const { layers, setLayers } = useLayersStore((state) => ({
    layers: state.layers,
    setLayers: state.setLayers,
  }));

  const isAllHidden = useMemo(
    () => layers.every((layer) => layer.opacity === 0),
    [layers]
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleToggleAll = (state: boolean) => {
    setLayers(layers.map((layer) => ({ ...layer, opacity: state ? 0 : 1 })));
  };

  return (
    <Box
      position="absolute"
      right="4rem"
      bottom="2rem"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        transition: 'all 0.5s', // Smooth transition for the whole container
      }}
    >
      <Card raised className="custom-scrollbar" sx={{ maxHeight: '75vh' }}>
        <CardContent
          sx={{
            padding: expanded ? '16px' : '8px',
            '&:last-child': {
              paddingBottom: expanded ? '16px' : '8px',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: expanded ? 1 : 0,
            }}
          >
            <Typography variant="h6">Legend</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Divider or additional spacing here if needed */}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: '24px', mx: 2 }}
              />

              <Tooltip
                title={showData ? 'Hide Data on Hover' : 'Show Data on Hover'}
              >
                <IconButton onClick={() => setShowData(!showData)}>
                  {showData ? (
                    <InfoIcon fontSize="small" color="primary" />
                  ) : (
                    <InfoIcon fontSize="small" color="action" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title={isAllHidden ? 'Show All' : 'Hide All'}>
                <span>
                  <IconButton
                    disabled={layers.length === 0}
                    onClick={() => handleToggleAll(!isAllHidden)}
                  >
                    {isAllHidden ? (
                      <VisibilityOffIcon fontSize="small" />
                    ) : (
                      <VisibilityIcon fontSize="small" />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Clear All">
                <span>
                  <IconButton
                    disabled={layers.length === 0}
                    onClick={() => setLayers([])}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <IconButton size="small" onClick={handleExpandClick}>
                {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {props.children}
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
};

interface BaseLayerProps {
  id: string;
  title: string;
  uniform: boolean;
  opacity: number;
  isCustom?: boolean;
}

interface UniformLayerProps extends BaseLayerProps {
  colors: string; // Single color for uniform layers
  uniform: true;
}

interface NonUniformLayerProps extends BaseLayerProps {
  boundLabels?: string[];
  lowerBoundValue?: number | string;
  upperBoundValue?: number | string;
  unit?: string;
  colors: string[]; // Array of colors for non-uniform layers
  threshold?: number[] | string[];
  opacityStops?: number[];
  uniform: false;
  hideBoundValues?: boolean;
}

interface CustomLayerProps extends BaseLayerProps {
  isCustom: true;
  colors: string;
  opacityToggle: () => void;
}

type MapLegendProps =
  | UniformLayerProps
  | NonUniformLayerProps
  | CustomLayerProps;

interface LayerWrapperProps extends BaseLayerProps {
  unit?: string;
  children: React.ReactNode;
  opacityToggle?: () => void;
}

const LayerWrapper = ({
  id,
  title,
  opacity,
  unit,
  children,
  opacityToggle,
  isCustom,
}: LayerWrapperProps) => {
  const { updateLayer, deleteLayer } = useLayersStore((state) => ({
    updateLayer: state.updateLayer,
    deleteLayer: state.deleteLayer,
  }));

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        width: '100%',
        display: 'flex',
        paddingTop: 1.5,
        alignItems: 'center',
        justifyContent: 'space-between',
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.25 : 1,
      }}
    >
      <Box display="flex" alignItems="center" marginRight={1} flex={1}>
        <IconButton
          sx={{
            padding: 0,
            marginLeft: -0.5,
            backgroundColor: 'transparent !important',
            visibility: isCustom ? 'hidden' : 'visible',
          }}
          {...listeners}
          {...attributes}
        >
          <DragIndicatorIcon />
        </IconButton>
        <IconButton
          sx={{ marginX: 0.5 }}
          onClick={() =>
            opacityToggle
              ? opacityToggle()
              : updateLayer(id, { opacity: opacity ? 0 : 1 })
          }
        >
          {opacity ? (
            <VisibilityIcon fontSize="small" />
          ) : (
            <VisibilityOffIcon fontSize="small" />
          )}
        </IconButton>

        <Typography
          variant="body2"
          sx={{
            maxWidth: '150px', // Set a maximum width for the title
            whiteSpace: 'normal', // Allow wrapping
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '20px',
          }}
        >
          {title}
          <br />
          {unit && <Typography variant="caption">({unit})</Typography>}
        </Typography>
      </Box>
      <Box flex={1}>{children}</Box>

      {!isCustom && (
        <Box marginLeft={1}>
          <IconButton onClick={() => deleteLayer(id)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const SingleMapLegend = (props: MapLegendProps) => {
  const { isCustom, uniform, colors } = props;

  if (isCustom) {
    return (
      <LayerWrapper {...props} isCustom>
        {/* Color block representation */}
        <Box width={200} height={10} bgcolor={colors} borderRadius={4} />
      </LayerWrapper>
    );
  }

  if (uniform) {
    return (
      <LayerWrapper {...props}>
        {/* Color block representation */}
        <Box width={200} height={10} bgcolor={colors} borderRadius={4} />
      </LayerWrapper>
    );
  }

  const {
    boundLabels,
    lowerBoundValue,
    upperBoundValue,
    opacityStops = [],
    threshold = [],
    hideBoundValues = false,
  } = props;

  const isNumericThreshold = typeof threshold[0] === 'number';
  const isWithoutThreshold = threshold.length === 0;

  // scale color widths based on threshold
  const thresholdRatios = isNumericThreshold
    ? (threshold as number[]).map(
        (t, _, arr) => (t - arr[0]) / (arr[arr.length - 1] - arr[0])
      )
    : [];

  const linearColorGradient = isNumericThreshold
    ? colors.map((color, index) => {
        const rgba = colorToRGBA(color, opacityStops[index]);
        return `${rgba} ${Math.round(thresholdRatios[index] * 100)}%`;
      })
    : [];

  return (
    <LayerWrapper {...props}>
      {/* Fixed width color scale and bounds */}
      <Box sx={{ width: 200 }}>
        <div
          style={{
            height: 10,
            backgroundImage: isNumericThreshold
              ? `linear-gradient(to right, ${linearColorGradient})`
              : isWithoutThreshold
                ? `linear-gradient(to right, ${colors.toString()})`
                : generateSharpGradient(colors),
            borderRadius: 5,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 1,
          }}
        >
          <Typography variant="caption">
            {boundLabels ? boundLabels[0] : null}
            {!hideBoundValues && lowerBoundValue !== undefined
              ? ` (${
                  typeof lowerBoundValue === 'number'
                    ? formatNumber(lowerBoundValue, 0)
                    : lowerBoundValue
                })`
              : ''}
          </Typography>
          <Typography variant="caption">
            {boundLabels ? boundLabels[1] : null}
            {!hideBoundValues && upperBoundValue !== undefined
              ? ` (${
                  typeof upperBoundValue === 'number'
                    ? formatNumber(upperBoundValue, 0)
                    : upperBoundValue
                })`
              : ''}
          </Typography>
        </Box>
      </Box>
    </LayerWrapper>
  );
};

export default function MapLegend() {
  const { layers, setLayers } = useLayersStore((state) => ({
    layers: state.layers,
    setLayers: state.setLayers,
  }));
  const trafficRange = useTrafficStore((state) => state.trafficRange);
  const isLoading = useTrafficStore((state) => state.isLoading);

  const {
    customTerritories,
    territoriesEnabled,
    toggleAllTerritoriesVisibility,
  } = useCustomTerritories();

  const { customPins, pinsEnabled, toggleAllPinsVisibility } = useCustomPins();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    // Handle situations when we update definitions and the user receive the old definitions from the store

    const insights = {
      ...customerLayers,
      ...getEnterpriseLayers([], []),
      ...getMapLayerDefinitions([]),
    } as Record<string, LayerConfig>;

    const existingInsights = layers.filter((layer) => {
      return Object.keys(insights).some((insight) => insight === layer.insight);
    });

    setLayers(
      existingInsights.map((insight) => {
        const definition = insights[insight.id] as LayerConfig;

        return {
          ...insight,
          ...definition,
        } as LayerCard;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDragEnd(event: {
    active: { id: string | number };
    over: { id: string | number } | null;
  }) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = layers.findIndex((layer) => layer.id === active.id);
      const newIndex = layers.findIndex((layer) => layer.id === over.id);

      const newLayers = arrayMove(layers, oldIndex, newIndex);
      setLayers(newLayers);
    }
  }

  const renderLegend = useMemo(
    () =>
      (!isLoading && layers.length > 0) ||
      customPins.length > 0 ||
      customTerritories.length > 0,
    [isLoading, layers, customPins, customTerritories]
  );

  return renderLegend ? (
    <MapLegendContainer>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[
          restrictToVerticalAxis,
          restrictToWindowEdges,
          restrictToParentElement,
        ]}
      >
        <SortableContext items={layers} strategy={verticalListSortingStrategy}>
          <SingleMapLegend
            uniform
            id="custom-pins"
            title="Custom Pins"
            colors={CUSTOM_LAYERS_COLOR_BOX}
            opacity={Number(pinsEnabled)}
            opacityToggle={toggleAllPinsVisibility}
            isCustom
          />
          <SingleMapLegend
            uniform
            id="custom-territories"
            title="Custom Territories"
            colors={CUSTOM_LAYERS_COLOR_BOX}
            opacity={Number(territoriesEnabled)}
            opacityToggle={toggleAllTerritoriesVisibility}
            isCustom
          />
          {layers.map((layer) => {
            const isUniform = layer.styleConfig.colors.length === 1;

            if (isUniform) {
              return (
                <SingleMapLegend
                  key={layer.id}
                  id={layer.id}
                  uniform={isUniform}
                  title={layer.displayName}
                  colors={layer.styleConfig.colors[0]}
                  opacity={layer.opacity}
                />
              );
            } else {
              const lowerBoundValue = layer.styleConfig.threshold?.[0];
              const upperBoundValue =
                layer.styleConfig.threshold?.[
                  layer.styleConfig.threshold?.length - 1
                ];
              const format = layer.styleConfig.format;

              return (
                <SingleMapLegend
                  key={layer.id}
                  id={layer.id}
                  uniform={isUniform}
                  title={layer.displayName}
                  boundLabels={layer.styleConfig.boundLabels}
                  colors={layer.styleConfig.colors}
                  threshold={layer.styleConfig.threshold}
                  opacityStops={layer.styleConfig.opacityStops}
                  lowerBoundValue={
                    layer.insight === 'traffic_volume'
                      ? trafficRange.min
                      : isUniform || lowerBoundValue == null
                        ? undefined
                        : (formatValue(lowerBoundValue, format) as
                            | string
                            | number)
                  }
                  upperBoundValue={
                    layer.insight === 'traffic_volume'
                      ? trafficRange.max
                      : isUniform || upperBoundValue == null
                        ? undefined
                        : (formatValue(upperBoundValue, format) as
                            | string
                            | number)
                  }
                  unit={layer.styleConfig.unit}
                  hideBoundValues={layer.styleConfig.hideBoundValues}
                  opacity={layer.opacity}
                />
              );
            }
          })}
        </SortableContext>
      </DndContext>
    </MapLegendContainer>
  ) : null;
}
