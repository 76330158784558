import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from '@mui/material';

interface AccordionSectionProps {
  title: string;
  children: React.ReactNode;
  isExpanded: boolean;
  handleChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

const heightStyle = {
  height: '100%',
};

// material ui adds a bunch of intermediate divs in between the accordion and the actual accordion content
// this is to make sure we inherit the height on those elements, making he overflow scrollable
const StyledAccordion = styled(Accordion)(() => ({
  '& > .MuiCollapse-root': {
    '& > .MuiCollapse-wrapper': {
      ...heightStyle,
      '& > .MuiCollapse-wrapperInner': {
        ...heightStyle,
        '& > .MuiAccordion-region': {
          ...heightStyle,
        },
      },
    },
  },
}));

const AccordionSection = ({
  title,
  children,
  isExpanded,
  handleChange,
}: AccordionSectionProps) => (
  <StyledAccordion
    expanded={isExpanded}
    onChange={handleChange}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: 1,
      boxShadow: 'none',
      overflow: 'hidden',
      minHeight: '48px',
    }}
    className="layers-accordion"
    disableGutters
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        border: '1px solid #ddd',
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#00000008',
      }}
    >
      <Typography fontWeight={500}>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        height: '100%',
        overflow: 'hidden',
        border: '1px solid #ddd',
        borderRadius: '0 0 4px 4px',
        borderTop: 0,
      }}
    >
      {children}
    </AccordionDetails>
  </StyledAccordion>
);

export default AccordionSection;
