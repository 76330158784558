import { Box, Typography } from '@mui/material';
import turfBuffer from '@turf/buffer';
import { bbox } from '@turf/turf';
import { useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import LogoAnimation from '~/src/common/components/animations/LogoAnimation';
import { PulseReportConfig } from '~/src/constants';
import loading from '~/src/assets/animations/logo-unpacking.json';
import useDemographicStore from '../../demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import { ReportMap } from '../ReportHeatmapMap';
import { ReportTable } from '../ReportTable';
import { ReportItem } from '../services/pulse-service';

const { LOADING_LABEL } = PulseReportConfig;

export const CompetitorsSection = ({
  competitors = [],
  isLoading,
}: {
  competitors?: ReportItem[];
  isLoading: boolean;
}) => {
  const boundaryData = useDemographicStore((state) => state.boundaryData);
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const mapStyle = useDynamicMapStore((state) => state.mapStyle);
  const { customPins: pins } = useCustomPins();
  const evaluatedPin = useMemo(
    () => pins.find((pin) => pin.id === evaluatedPinId),
    [pins, evaluatedPinId]
  );

  const reportsWithIndexes = useMemo(
    () => competitors.map((report, index) => ({ ...report, index })),
    [competitors]
  );

  const zoomBbox = useMemo(
    () => (boundaryData ? bbox(boundaryData) : undefined),
    [boundaryData]
  );

  const [selectedPinId, setSelectedPinId] = useState<number>(0);
  const userSettings = useSettingsStore((state) => state.userSettings);
  const competitorPoi = userSettings?.poiGroups?.find((poiGroup) => {
    return poiGroup.group.toLowerCase().includes('competitors');
  });

  return (
    <Box
      flex={1}
      width="100%"
      position="relative"
      p={2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5" fontWeight={500} mb={2}>
        Competitors
      </Typography>
      <ReportMap
        context="competitors"
        reports={reportsWithIndexes}
        selectedPinId={selectedPinId}
        setSelectedPinId={setSelectedPinId}
        zoomBbox={zoomBbox as [number, number, number, number] | undefined}
        preparedLocation={
          reportsWithIndexes[0]
            ? reportsWithIndexes[0]
            : evaluatedPin?.pos
              ? { lat: evaluatedPin.pos.lat, lng: evaluatedPin.pos.lng }
              : undefined
        }
        displayDensity={true}
        additionalLayers={
          boundaryData && (
            <>
              <Source
                id="competitors-boundary"
                type="geojson"
                data={turfBuffer(boundaryData, 0.3, { units: 'kilometers' })}
              >
                <Layer
                  id="competitors-boundary-halo"
                  type="line"
                  paint={{
                    'line-color': mapStyle === 'streets-v12' ? '#fff' : '#000',
                    'line-opacity': mapStyle === 'streets-v12' ? 0.5 : 0.9,
                    'line-width': 8,
                  }}
                />
                <Layer
                  id="competitors-boundary-line"
                  type="line"
                  paint={{
                    'line-color': mapStyle === 'streets-v12' ? '#000' : '#fff',
                    'line-opacity': mapStyle === 'streets-v12' ? 0.5 : 0.9,
                    'line-width': 4,
                  }}
                />
              </Source>
            </>
          )
        }
      />
      {!isLoading ? (
        <ReportTable
          onRowClick={(index: number) => setSelectedPinId(index)}
          reports={reportsWithIndexes}
          selectedPinId={selectedPinId}
          isGroupConfigured={!!competitorPoi}
          groupName="competitors"
        />
      ) : (
        <Box width="100%" display="flex" justifyContent="center" mt={4}>
          <LogoAnimation src={loading} label={LOADING_LABEL} />
        </Box>
      )}
    </Box>
  );
};
