import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import {
  CustomPin,
  CustomTerritory,
  plotrMultiplayerData,
  TerritoryType,
} from '@plotr/plotr-multiplayer-data/src';
import turfBuffer from '@turf/buffer';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import CreateTerritorySchema, {
  CreateTerritorySchemaType,
} from '~/src/validation/createTerritory.schema';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreatePulseTerritoryModal = ({
  selectedPin,
}: {
  selectedPin: CustomPin | null;
}) => {
  const [open, setOpen] = useState(false);
  const { boundaryData: storeBoundaryData, boundaryType } = useDemographicStore(
    (state) => ({
      boundaryData: state.boundaryData,
      boundaryType: state.boundaryType,
    })
  );
  const customTerritoriesMethods = plotrMultiplayerData.methods?.territories;
  const { customTerritories } = useCustomTerritories();

  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);

  const territoryGroups = useMemo(
    () => [...new Set(customTerritories.map((t) => t.group))],
    [customTerritories]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateTerritorySchemaType>({
    resolver: zodResolver(CreateTerritorySchema),
    defaultValues: {
      AssignToPin: true,
      Name: selectedPin ? `${selectedPin.label} Territory` : '',
    },
  });

  const handleCreateTerritory = useCallback(
    (data: CreateTerritorySchemaType) => {
      if (!storeBoundaryData || isReadOnly) return;

      const boundaryData =
        boundaryType === 'radius'
          ? storeBoundaryData
          : turfBuffer(storeBoundaryData, 0.3, {
              units: 'kilometers',
            });

      const coordinates = boundaryData?.geometry.coordinates[0];

      const boundaryName = `Pulse-${crypto.randomUUID()}`;

      const territory: CustomTerritory = {
        id: crypto.randomUUID(),
        label: data.Name,
        group: data.Group,
        boundaries: {
          [boundaryName]: {
            id: boundaryName,
            coordinates:
              boundaryData?.geometry.type === 'Polygon'
                ? (coordinates as number[][])
                : undefined,
          },
        },
        type: TerritoryType.Custom,
        pins: data.AssignToPin && selectedPin ? [selectedPin.id] : [],
      };

      customTerritoriesMethods?.addTerritory(territory);
      setOpen(false);
      reset();
    },
    [
      boundaryType,
      customTerritoriesMethods,
      reset,
      selectedPin,
      storeBoundaryData,
      isReadOnly,
    ]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={handleOpen}
        color="secondary"
      >
        Save as Territory
      </Button>
      <Modal
        aria-labelledby="Create template"
        aria-describedby="Create a new pin template"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} display="flex" flexDirection="column" gap={1}>
            <Box>
              <Typography variant="h6" component={'h2'}>
                Create a territory from Pulse
              </Typography>
            </Box>
            <Box
              component={'form'}
              onSubmit={handleSubmit(handleCreateTerritory)}
            >
              <Controller
                name="Name"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={!!errors.Name}
                    label="Territory Display Name"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <Typography color="red">
                {errors.Name?.message && errors.Name.message}
              </Typography>
              <Controller
                name="Group"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    id="territory-group"
                    fullWidth
                    freeSolo
                    options={territoryGroups}
                    inputValue={field.value}
                    autoSelect
                    onInputChange={(_e, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.Group}
                        inputProps={{ ...params.inputProps }}
                        label="Territory Group"
                        variant="outlined"
                        margin="normal"
                      />
                    )}
                    {...field}
                    onChange={() => {}}
                    onBlur={() => {}}
                  />
                )}
              />
              <Typography color="red">
                {errors.Group?.message && errors.Group.message}
              </Typography>
              <Controller
                name="AssignToPin"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label={`Link new territory to pin ${selectedPin?.label}`}
                  />
                )}
              />
            </Box>

            <Box width={'w-fit'} ml="auto">
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                onClick={handleSubmit(handleCreateTerritory)}
              >
                Save
              </Button>
              <Button
                sx={{ marginLeft: 1 }}
                onClick={handleClose}
                color="secondary"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CreatePulseTerritoryModal;
