import { memo, useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import useCustomPins from '~/src/global/hooks/useCustomPins';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import CustomPinMarker from './CustomPinMarker';

const CustomPins = memo(function CustomPins() {
  const { customPins } = useCustomPins();

  const { hiddenPinGroups } = useDynamicMapStore(
    useCallback(
      (state) => ({
        hiddenPinGroups: state.hiddenPinGroups,
      }),
      []
    ),
    shallow
  );

  const filteredPins = useMemo(() => {
    return customPins.filter((pin) => !hiddenPinGroups.includes(pin.group));
  }, [customPins, hiddenPinGroups]);

  if (filteredPins.length === 0) {
    return null;
  }

  return (
    <>
      {filteredPins.map((pin) => (
        <CustomPinMarker pin={pin} key={pin.id} />
      ))}
    </>
  );
});

export default CustomPins;
