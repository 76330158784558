import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import usePricingDataStore from '../../dynamic-map/hooks/usePricingDataStore';
import { PRICING_LAYERS } from '../constants/pricingLayers';

const PricingDataMenu = () => {
  const { toggleBrandVisibility, hiddenBrands } = usePricingDataStore();

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={1}
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      mb={2}
    >
      <Typography variant="h6" mb={2}>
        Price Layers
      </Typography>
      {Object.values(PRICING_LAYERS).map(({ id, displayName }, index) => (
        <React.Fragment key={id}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            sx={{
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.04)',
              },
              borderRadius: 1,
              p: 1,
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {displayName}
            </Typography>
            <IconButton
              onClick={() => toggleBrandVisibility(id)}
              size="small"
              color="primary"
            >
              {hiddenBrands.includes(id) ? (
                <VisibilityOff fontSize="small" />
              ) : (
                <Visibility fontSize="small" />
              )}
            </IconButton>
          </Box>
          {index === 0 && <Divider />}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default PricingDataMenu;
