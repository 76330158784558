import { parseEnv } from '@plotr/common-utils';
import { ExpressionSpecification } from 'mapbox-gl';
import getPinURL from '~/src/common/helpers/getPinURL';

import axios from 'axios';
import {
  Boundary,
  MobileData,
  PeopleFractionMap,
  POIData,
  SonarData,
} from '../../demographic-point-lookup/hooks/useDemographicStore';
import { fetchCBSANameFromCensusCode } from '../../demographic-point-lookup/services/getCBSANameFromCensusCode';

const env = parseEnv({
  PLOTR_API: process.env.PLOTR_API,
  API_V2: process.env.API_V2,
});

type PlaceQueryCondition = {
  [k in PlaceQueryOperator]?: {
    path: string;
    value: string | string[];
  };
};

function serializeQuery(queryConditions: PlaceQueryCondition[]): string {
  return queryConditions
    .map((condition, index) => {
      return Object.entries(condition)
        .map(([operator, details]) => {
          if (!details) return '';
          const value = Array.isArray(details.value)
            ? details.value.join(',')
            : details.value;
          return `query[${index}][operator]=${encodeURIComponent(
            operator
          )}&query[${index}][path]=${encodeURIComponent(
            details.path
          )}&query[${index}][value]=${encodeURIComponent(value)}`;
        })
        .join('&');
    })
    .join('&');
}

export interface POIBrand {
  id: string;
  name: string;
  naicsCode: string;
  parentId: string | null;
  website: string | null;
  group: string;
}

export type ExtendedBrandResult = POIBrand & {
  group: string;
  badge?: Badge;
  pinImage: string;
};

export interface POIIndustry {
  naicsCode: string;
  topCategory: string;
  subCategory: string | null;
}

export type POIGroupRequest = {
  group: string;
  icon?: string;
  color?: string;
  badge?: Badge;
  brands?: BrandRequest[];
  queries?: PlacesRequest[];
};

export type POIGroupResults = {
  group: string;
  icon?: string;
  color?: string;
  badge?: Badge;
  brandResults: ExtendedBrandResult[];
  queryResults: ExtendedPlacesResult[];
};

export interface Badge {
  id: string;
  src: string;
  offset: [number, number];
  size: number;
}

export interface BrandRequest {
  brandId: string;
  website?: string;
}

export type PlaceQueryOperator =
  | 'equals'
  | 'includes'
  | 'notIncludes'
  | 'startsWith'
  | 'greaterThan'
  | 'lessThan'
  | 'greaterThanOrEqualTo'
  | 'lessThanOrEqualTo'
  | 'ilike';
export interface PlacesRequest {
  id: string;
  name: string;
  query: Array<{
    [k in PlaceQueryOperator]: {
      path: string;
      value: string | string[];
    };
  }>;
  website?: string;
  boundary?: Boundary;
}

export type PlacesResult = {
  id: string;
  name: string;
  website?: string;
} & (
  | {
      placeIds: string[];
    }
  | {
      localFilter: ExpressionSpecification;
    }
);

export type ExtendedPlacesResult = PlacesResult & {
  group: string;
  badge?: Badge;
  pinImage: string;
};

async function fetchBrandsById(
  ids: string[]
): Promise<Omit<POIBrand, 'group'>[]> {
  if (ids.length === 0) return [];

  // Batch requests into groups of 50
  const batchSize = 50;
  const batches = [];
  for (let i = 0; i < ids.length; i += batchSize) {
    batches.push(ids.slice(i, i + batchSize));
  }

  const results = await Promise.all(
    batches.map(async (batchIds) => {
      console.debug(`Fetching batch of ${batchIds.length} brands...`);
      const response = await axios.post(`${env.PLOTR_API}/poi-brands-search`, {
        brandIds: batchIds,
      });

      if (response.status !== 200) {
        throw new Error(
          `HTTP ${response.status} ${response.statusText}: ${response.data}`
        );
      }

      return response.data;
    })
  );

  const brands = results.flat();
  console.debug(`Brands by id fetched! ${brands.length} brands found.`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return brands.map((brand: any) => ({
    id: brand.safegraphBrandId,
    name: brand.brandName,
    naicsCode: brand.naicsCode,
    parentId: brand.parentSafegraphBrandId,
    website: brand.websiteUrl,
  }));
}

async function queryPlaces(
  placeQueries: PlacesRequest[]
): Promise<PlacesResult[]> {
  if (placeQueries.length === 0) return [];

  console.debug('Querying places...');

  const results = await Promise.all(
    placeQueries.map(async (placeQuery) => {
      try {
        const response = await axios.post(
          `${env.PLOTR_API}/poi-place-search-boundary`,
          {
            industries: placeQuery.query
              .filter((q) => 'naicsCode' in q)
              .map((q) => q.naicsCode),
            brands: placeQuery.query
              .filter((q) => 'brandId' in q)
              .map((q) => q.brandId),
            queries: placeQuery.query.map((condition) => {
              const [operator, details] = Object.entries(condition)[0];
              return {
                operator,
                path: details.path,
                value: details.value,
              };
            }),
            returnData: true,
            boundary: placeQuery.boundary,
          }
        );

        const places = response.data.data;
        if (!places || places.length === 0) {
          console.warn(`No places found for query ${placeQuery.name}.`);
          return null;
        }

        const result: PlacesResult = {
          id: placeQuery.id,
          name: placeQuery.name,
          ...(placeQuery.website && { website: placeQuery.website }),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          placeIds: places.map((place: any) => place.placekey),
        };

        return result;
      } catch (error) {
        console.warn(`Could not query places for ${placeQuery.name}.`, error);
        return null;
      }
    })
  );

  return results.filter(
    (result): result is NonNullable<typeof result> => result !== null
  );
}

async function fetchBrandMapPin(
  color: string,
  website?: string
): Promise<string> {
  const defaultPin = getPinURL({
    background: '#ffffff',
    color,
    crop: true,
  });

  const faviconPinURL =
    website != null
      ? getPinURL({
          website,
          background: '#ffffff',
          color,
          crop: true,
        })
      : defaultPin;

  return new Promise((resolve) => {
    fetch(faviconPinURL)
      .then((response) => response.blob())
      // Convert the blob to a data URL for easy serialization
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (ctx == null) throw new Error('Could not get canvas context');
          ctx.drawImage(img, 0, 0);
          URL.revokeObjectURL(blobURL); // Clean up the blob URL in browser memory
          const dataURL = canvas.toDataURL();
          resolve(dataURL);
        };
        img.onerror = () => {
          console.warn(
            `Could not fetch favicon for ${website ?? 'default pin'}.`
          );
          resolve(defaultPin);
        };
        img.src = blobURL;
      })
      .catch((_) => {
        console.warn(
          `Could not fetch favicon for ${website ?? 'default pin'}.`
        );
        resolve(defaultPin);
      });
  });
}

export async function fetchPOIGroup(
  poiGroup: POIGroupRequest
): Promise<POIGroupResults> {
  const brandIds = (poiGroup.brands ?? []).map((brand) => brand.brandId);
  const websiteByBrandId = new Map(
    (poiGroup.brands ?? []).map((brand) => [brand.brandId, brand.website])
  );

  // divide queries by what can be completed locally with a Mapbox data expression and what needs to be sent to the server
  const [localPOIQueries, remotePOIQueries] = (poiGroup.queries ?? []).reduce<
    [PlacesRequest[], PlacesRequest[]]
  >(
    (acc, query) => {
      const isLocalQuery = query.query.every((condition) =>
        Object.keys(condition).every(
          (conditionKey) =>
            conditionKey === 'equals' ||
            conditionKey === 'greaterThan' ||
            conditionKey === 'lessThan' ||
            conditionKey === 'greaterThanOrEqualTo' ||
            conditionKey === 'lessThanOrEqualTo' ||
            conditionKey === 'notIncludes'
        )
      );
      if (isLocalQuery) {
        acc[0].push(query);
      } else {
        acc[1].push(query);
      }
      return acc;
    },
    [[], []]
  );

  const [brandResults, queryResults] = await Promise.all([
    fetchBrandsById(brandIds),
    queryPlaces(remotePOIQueries),
  ]);

  const combinedQueryResults = [
    ...queryResults,
    ...localPOIQueries.map((poiQuery) => {
      const localFilter: ExpressionSpecification =
        poiQuery.query.reduce<ExpressionSpecification>((acc, condition) => {
          const [_operator, { path, value }] = Object.entries(condition)[0];

          if (_operator === 'equals' && path === 'name') {
            // For name fields, use case-insensitive partial match
            return acc.length === 0
              ? [
                  'all',
                  [
                    'match',
                    ['downcase', ['get', path]],
                    ['downcase', value],
                    true,
                    false,
                  ],
                ]
              : [
                  ...acc,
                  [
                    'match',
                    ['downcase', ['get', path]],
                    ['downcase', value],
                    true,
                    false,
                  ],
                ];
          }

          const operatorMap: { [key: string]: string } = {
            equals: '==',
            greaterThan: '>',
            lessThan: '<',
            greaterThanOrEqualTo: '>=',
            lessThanOrEqualTo: '<=',
          };

          if (_operator === 'notIncludes') {
            return acc.length === 0
              ? ['all', ['!', ['in', ['get', path], ['literal', value]]]]
              : [...acc, ['!', ['in', ['get', path], ['literal', value]]]];
          }

          const operator = operatorMap[_operator] || '==';

          return acc.length === 0
            ? ['all', [operator, ['get', path], value]]
            : [...acc, [operator, ['get', path], value]];
        }, []);

      return {
        id: poiQuery.id,
        name: poiQuery.name,
        website: poiQuery.website,
        localFilter,
      };
    }),
  ];

  const brandsWithPinsPromises = brandResults.map(async (brandResult) => {
    const brand = {
      ...brandResult,
      website: websiteByBrandId.get(brandResult.id) ?? null,
    };
    const pinImage = await fetchBrandMapPin(
      poiGroup.color ?? '#757575',
      brand.website ?? undefined
    );
    return {
      ...brand,
      group: poiGroup.group,
      badge: poiGroup.badge,
      pinImage,
    };
  });

  const queryResultsWithPinsPromises = combinedQueryResults.map(
    async (queryResult) => {
      const pinImage = await fetchBrandMapPin(
        poiGroup.color ?? '#757575',
        queryResult.website ?? undefined
      );
      return {
        ...queryResult,
        group: poiGroup.group,
        pinImage,
        badge: poiGroup.badge,
      };
    }
  );

  const [brandsWithPins, queryResultsWithPins] = await Promise.all([
    Promise.all(brandsWithPinsPromises),
    Promise.all(queryResultsWithPinsPromises),
  ]);

  const poiGroupResults: POIGroupResults = {
    group: poiGroup.group,
    icon: poiGroup.icon,
    color: poiGroup.color,
    badge: poiGroup.badge,
    brandResults: brandsWithPins,
    queryResults: queryResultsWithPins,
  };

  return poiGroupResults;
}

export interface FetchPOIDataParams {
  industries?: string[];
  brands?: string[];
  page?: number;
  limit?: number;
  returnData?: boolean;
  boundary?: Boundary;
  placeIds?: string[];
  accessToken: string;
}

export async function fetchPOIData(
  params: FetchPOIDataParams
): Promise<POIData[]> {
  try {
    const { data } = await axios.post(
      `${env.PLOTR_API}/poi-place-search-boundary`,
      params
    );

    const places = Object.values(data);

    // Fetch all CBSA names in parallel
    const promises = places
      .filter((_, index) => index % 2 === 0)
      .map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async ([place]: any) => {
          const stateGeoId = (place.censusCode as string).substring(0, 2);
          const countyGeoId = (place.censusCode as string).substring(2, 5);

          const cbsaName = await fetchCBSANameFromCensusCode(
            countyGeoId,
            stateGeoId,
            params.accessToken
          );

          return {
            censusCode: place.censusCode,
            topCategory: place.topCategory,
            subCategory: place.subCategory,
            streetAddress: place.streetAddress,
            cityRegion: `${place.city}, ${place.region}`,
            postalCode: place.postalCode,
            openedOn: place.openedOn,
            closedOn: place.closedOn,
            trackingClosedSince: place.trackingClosedSince,
            cbsaName: cbsaName?.name ?? null,
          };
        }
      );

    // Wait for all promises to resolve
    return await Promise.all(promises);
  } catch (error) {
    console.error('Error fetching POI data:', error);
    throw error;
  }
}

export interface FetchMobileDataParams {
  id: string;
  trend_year: string;
  access_token: string;
  // trade_area_type: 'HOME' | 'WORK';
}

export async function fetchMobileData(
  params: FetchMobileDataParams
): Promise<MobileData | string> {
  const endpoint = `${env.PLOTR_API}/mobile-data`;

  const { data } = await axios.post(endpoint, {
    id: params.id,
    trend_year: params.trend_year,
    token: params.access_token,
  });

  return data;
}

export interface FetchTradeAreaDataParams {
  id: string;
  startDate: string;
  endDate: string;
  trend_year: string;
  trade_area_type: 'HOME' | 'WORK' | 'BOTH';
}

export async function fetchTradeAreaDataFromBackend(
  params: FetchTradeAreaDataParams
): Promise<PeopleFractionMap | undefined> {
  const tradeAreaEndpoint = `${env.PLOTR_API}/mobile-trade-area`;

  const { data } = await axios.post(tradeAreaEndpoint, {
    place_key: params.id,
    observation_start_date: params.startDate,
    observation_end_date: params.endDate,
  });

  return data;
}

export interface FetchSonarDataParams {
  id: string;
  brand_id: string;
  access_token: string;
  ranking_year: number;
}

export async function fetchSonarData(
  params: FetchSonarDataParams
): Promise<SonarData> {
  const endpoint = `${env.PLOTR_API}/sonar-data`;

  const brandIdWithoutPOIPrefix = params.brand_id.slice(4);

  try {
    const { data } = await axios.post(endpoint, {
      id: params.id,
      brand_id: brandIdWithoutPOIPrefix,
      token: params.access_token,
      ranking_year: params.ranking_year,
    });

    return data;
  } catch (error) {
    // Re-throwing the error to be handled by the calling function
    console.error('Error fetching sonar data thrown:', error);
    throw error instanceof Error
      ? error
      : new Error('Unexpected error occurred');
  }
}

export interface FetchTradeAreaOverlapDataPin {
  id: string;
  lat: number;
  lng: number;
}
export interface FetchTradeAreaOverlapDataParams {
  evaluatedPin: FetchTradeAreaOverlapDataPin;
  surroundingPins: FetchTradeAreaOverlapDataPin[];
  startDate: string;
  endDate: string;
  tradeAreaType?: 'HOME' | 'WORK';
}

export type OverlapPerPinId = Record<
  string,
  {
    percentage: number;
    visits: number;
  }
>;

export interface FetchTradeAreaOverlapDataResult {
  nonOverlappingLocationsVisits: Record<string, number>;
  overallOverlap: number;
  overlapPerLocationId: Record<
    string,
    {
      evaluatedVisits: number;
      totalOverlapPercentage: number;
      overlapPerPinId: OverlapPerPinId;
    }
  >;
  overlapPerPinId: OverlapPerPinId;
  startDate: string;
  endDate: string;
}

export async function fetchTradeAreaOverlapDataFromBackend(
  params: FetchTradeAreaOverlapDataParams,
  abortControllerSignal: AbortSignal
): Promise<FetchTradeAreaOverlapDataResult | undefined> {
  const tradeAreaOverlapEndpoint = `${env.API_V2}/mobile/overlap-trade-area`;

  const response = await axios.post<FetchTradeAreaOverlapDataResult>(
    tradeAreaOverlapEndpoint,
    {
      evaluatedPin: params.evaluatedPin,
      surroundingPins: params.surroundingPins,
      observationStartDate: params.startDate,
      observationEndDate: params.endDate,
      tradeAreaType: params.tradeAreaType,
    },
    {
      signal: abortControllerSignal,
    }
  );

  return response.data;
}
