import {
  TerritoryType,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';
import turfBooleanContains from '@turf/boolean-contains';
import turfBuffer from '@turf/buffer';
import * as turfHelper from '@turf/helpers';
import { Feature, Polygon } from 'geojson';
import isEqual from 'lodash.isequal';
import mapboxgl from 'mapbox-gl';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layer, MapMouseEvent, Source } from 'react-map-gl';
import { v4 as uuid } from 'uuid';

import tinycolor from 'tinycolor2';
import useGeometryCache from '~/src/common/hooks/useGeometryCache';
import usePrevious from '~/src/common/hooks/usePrevious';
import useWebWorker from '~/src/common/hooks/useWebWorker';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';
import useRulesets from '../../dynamic-map/hooks/useRulesets';
import { getColor } from '../menu/GlobalTerritorySettingsMenu';

interface UnionPolygonsOptions {
  buffer?: {
    distance: number;
    units: turfHelper.Units;
  };
  simplifyTolerance?: number;
}

interface UnionPolygonsInput {
  id: string;
  polygons: Feature<Polygon>[];
  options?: UnionPolygonsOptions;
}

const getSimplifyTolerance = (zoom: number): number => {
  if (zoom <= 6) return 5000;
  if (zoom <= 8) return 2000;
  if (zoom <= 10) return 1000;
  if (zoom <= 12) return 500;
  if (zoom <= 14) return 200;
  return 0;
};

// This hook is used to union polygons in a web worker to avoid blocking the main thread
// It tracks requests and resolves promises when the worker returns a result
function useUnionPolygons() {
  const unionPolygonsWorker = useWebWorker<
    UnionPolygonsInput,
    Feature<Polygon>
  >('unionPolygons');

  const requestMap = useRef(new Map());

  useEffect(() => {
    unionPolygonsWorker.onMessage((message) => {
      const resolve = requestMap.current.get(message.id);

      if (resolve) {
        if (message.success) {
          resolve(message.data);
        } else {
          console.error(message.error);
          resolve(null);
        }
        requestMap.current.delete(message.id);
      }
    });
  }, [unionPolygonsWorker]);

  const unionPolygons = useCallback(
    async (polygons: Feature<Polygon>[], options?: UnionPolygonsOptions) => {
      return new Promise<Feature<Polygon> | null>((resolve) => {
        const id = uuid();
        requestMap.current.set(id, resolve);

        unionPolygonsWorker.postMessage({
          id,
          polygons,
          options,
        });
      });
    },
    [unionPolygonsWorker]
  );

  return unionPolygons;
}

const CustomTerritoriesLayer: React.FC = () => {
  const map = useMapContext();

  const { customTerritories, territoriesEnabled } = useCustomTerritories();
  const territoryMethods = plotrMultiplayerData.methods?.territories;

  const customTerritoryById = useMemo(() => {
    return new Map(
      customTerritories.map((territory) => [territory.id, territory])
    );
  }, [customTerritories]);
  const prevCustomTerritoryById = usePrevious(customTerritoryById);

  const {
    evaluatedTerritoryId,
    territoryFillEnabled,
    hiddenTerritoryGroups,
    isDrawingTerritory,
    territoryLabelsEnabled,
  } = useDynamicMapStore((state) => ({
    evaluatedTerritoryId: state.evaluatedTerritoryId,
    territoryFillEnabled: state.territoryFillEnabled,
    hiddenTerritoryGroups: state.hiddenTerritoryGroups,
    isDrawingTerritory: state.isDrawingTerritory,
    territoryLabelsEnabled: state.territoryLabelsEnabled,
  }));

  const evaluatedTerritory = customTerritories.find(
    (territory) => territory.id === evaluatedTerritoryId
  );

  const unionPolygons = useUnionPolygons();

  const [mapChanges, setMapChanges] = useState(0);

  const rulesets = useRulesets();

  // Track map changes for forcing re-renders
  useEffect(() => {
    if (map == null) return;

    const mapMoveHandler = () => {
      setMapChanges((prev) => prev + 1);
    };

    map.on('moveend', mapMoveHandler);
    map.on('load', mapMoveHandler);

    return () => {
      map.off('moveend', mapMoveHandler);
      map.off('load', mapMoveHandler);
    };
  }, [map, evaluatedTerritory?.type]);

  const [hoveredZip, setHoveredZip] = useState<number | null>(null);
  const prevHoveredZip = usePrevious(hoveredZip);

  const [hoveredTerritory, setHoveredTerritory] = useState<string | null>(null);
  const prevHoveredTerritory = usePrevious(hoveredTerritory);

  // Manage mouse handlers:
  //   click -> add or remove boundary for territory
  //   move  -> track hovered boundary for highlighting
  useEffect(() => {
    if (
      map == null ||
      evaluatedTerritoryId == null ||
      evaluatedTerritory?.type === TerritoryType.Custom ||
      !isDrawingTerritory
    )
      return;

    // handle click events for adding or removing boundaries
    const mouseClickHandler = (e: MapMouseEvent) => {
      if (map == null) return;

      const zipFeature = map.queryRenderedFeatures(e.point, {
        layers: ['custom-territories-fill'],
      })?.[0];

      const zipCode = zipFeature?.id?.toString();

      if (zipCode != null && territoryMethods != null) {
        const territory = customTerritories.find(
          (territory) => territory.id === evaluatedTerritoryId
        );

        if (territory && territory.boundaries[zipCode]) {
          territoryMethods.removeBoundary(evaluatedTerritoryId, zipCode);
        } else {
          territoryMethods.addOrUpdateBoundary({
            territoryId: evaluatedTerritoryId,
            boundaryId: zipCode,
          });
        }
      }
    };

    // handle hover events for highlighting boundaries
    const mouseMoveBoundaryHandler = (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData
    ) => {
      const zipId = e.features?.[0].id;

      if (map == null || typeof zipId !== 'number') return;

      setHoveredZip(zipId);
    };

    const mouseOutHandler = () => {
      setHoveredZip(null);
    };

    map.on('click', mouseClickHandler);
    map.on('mousemove', 'custom-territories-fill', mouseMoveBoundaryHandler);
    map.on('mouseout', 'custom-territories-fill', mouseOutHandler);

    return () => {
      map.off('click', mouseClickHandler);
      map.off('mousemove', 'custom-territories-fill', mouseMoveBoundaryHandler);
      map.off('mouseout', 'custom-territories-fill', mouseOutHandler);
    };
  }, [
    map,
    customTerritories,
    evaluatedTerritoryId,
    territoryMethods,
    isDrawingTerritory,
    evaluatedTerritory?.type,
  ]);

  // Manage mouse move handler for territory hover highlighting
  useEffect(() => {
    if (map == null) return;

    const mouseMoveTerritoryHandler = (
      e: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
      } & mapboxgl.EventData
    ) => {
      const territoryId = e.features?.[0].id;

      if (map == null || typeof territoryId !== 'string') return;

      setHoveredTerritory(territoryId);
    };

    const mouseOutHandler = () => {
      setHoveredTerritory(null);
    };

    map.on('mousemove', 'defined-territories-fill', mouseMoveTerritoryHandler);
    map.on('mouseout', 'defined-territories-fill', mouseOutHandler);

    return () => {
      map.off(
        'mousemove',
        'defined-territories-fill',
        mouseMoveTerritoryHandler
      );
      map.off('mouseout', mouseOutHandler);
    };
  }, [map, evaluatedTerritory?.type]);

  // Manage hovered zip-code & territory highlighting
  useEffect(() => {
    if (map == null) return;
    if (prevHoveredZip != null) {
      map.setFeatureState(
        {
          source: 'custom-territories',
          sourceLayer: 'insights_zipcode',
          id: prevHoveredZip,
        },
        { hover: false }
      );
    }

    if (hoveredZip != null) {
      map.setFeatureState(
        {
          source: 'custom-territories',
          sourceLayer: 'insights_zipcode',
          id: hoveredZip,
        },
        { hover: true }
      );
    }

    if (prevHoveredTerritory != null) {
      map.setFeatureState(
        {
          source: 'defined-territories',
          id: prevHoveredTerritory,
        },
        { hover: false }
      );
    }

    if (hoveredTerritory != null) {
      map.setFeatureState(
        {
          source: 'defined-territories',
          id: hoveredTerritory,
        },
        { hover: true }
      );
    }
  }, [map, prevHoveredZip, hoveredZip, prevHoveredTerritory, hoveredTerritory]);

  const zoomLevel = useDynamicMapStore((state) => Math.floor(state.zoomLevel));

  // Cache to prevent slow turf.union and turfBuffer calls, where possible
  const territoryGeometryCache = useGeometryCache();

  // The buffer ensures that adjacent boundaries overlap slightly during union
  // and helps with knowing when to use a cached geometry or not
  const bufferDistance = 20;
  const bufferUnits = 'meters';

  const [featureByTerritoryId, setFeatureByTerritoryId] = useState<
    Map<string, Feature<Polygon>>
  >(new Map());

  // Make sure that featureByTerritoryId only represents current customTerritories and their boundaries
  // Diff against previous customTerritories to remove any territories that have changed, so they can be re-rendered
  useEffect(() => {
    if (map == null || prevCustomTerritoryById == null) return;

    // handle changed territories
    const changedTerritories = customTerritories.filter((territory) => {
      const prevTerritory = prevCustomTerritoryById.get(territory.id);
      return !isEqual(prevTerritory, territory);
    });

    // handle deleted territories, pushing to changedTerritories
    prevCustomTerritoryById.forEach((prevTerritory, id) => {
      if (!customTerritoryById.has(id)) {
        changedTerritories.push(prevTerritory);
      }
    });

    if (changedTerritories.length === 0) return;

    setFeatureByTerritoryId((prev) => {
      const updated = new Map(prev);
      changedTerritories.forEach(({ id }) => {
        updated.delete(id);
      });
      return updated;
    });
  }, [
    customTerritories,
    customTerritoryById,
    prevCustomTerritoryById,
    map,
    evaluatedTerritory?.type,
  ]);

  // Update the feature used for each territory as the map or territories change
  useEffect(() => {
    if (map == null) return;

    const layer = map.getLayer('custom-territories-fill');

    const allRenderedFeatures =
      layer != null
        ? map.queryRenderedFeatures(undefined, {
            layers: ['custom-territories-fill'],
          })
        : [];

    const renderedFeaturesByFeatureId = allRenderedFeatures.reduce(
      (map, feature) => {
        const featureId = feature.id?.toString();

        if (featureId == null) {
          return map;
        }

        const existingFeatures = map.get(featureId);

        if (existingFeatures != null) {
          existingFeatures.push(feature);
        } else {
          map.set(featureId, [feature]);
        }

        return map;
      },
      new Map<string, mapboxgl.MapboxGeoJSONFeature[]>()
    );

    const boundaryIdSetByTerritoryId = customTerritories.reduce(
      (map, territory) => {
        map.set(territory.id, new Set(Object.keys(territory.boundaries)));
        return map;
      },
      new Map<string, Set<string>>()
    );

    const renderedFeaturesByTerritoryId = customTerritories.reduce(
      (map, territory) => {
        const boundaryIdSet = boundaryIdSetByTerritoryId.get(territory.id);

        if (boundaryIdSet == null) {
          return map;
        }

        const renderedBoundaryFeatures = [...boundaryIdSet].reduce(
          (features, boundaryId) => {
            const rendered = renderedFeaturesByFeatureId.get(boundaryId);

            if (rendered != null) {
              features.push(...rendered);
            }

            return features;
          },
          [] as mapboxgl.MapboxGeoJSONFeature[]
        );

        map.set(territory.id, renderedBoundaryFeatures);
        return map;
      },
      new Map<string, mapboxgl.MapboxGeoJSONFeature[]>()
    );

    const onScreenTerritoryFeaturePromises = customTerritories
      .map((territory) => {
        const boundaryIdSet = boundaryIdSetByTerritoryId.get(territory.id);

        if (boundaryIdSet == null) {
          return null;
        }

        const territoryRenderedFeatures =
          renderedFeaturesByTerritoryId.get(territory.id) ?? [];

        if (territoryRenderedFeatures.length === 0) {
          return null;
        }

        const simplifyTolerance = getSimplifyTolerance(zoomLevel);
        const cacheKey = `${territory.id}::${zoomLevel}::${simplifyTolerance}`;
        const cached = territoryGeometryCache.get(cacheKey);
        const cachedBoundaryIdSet = new Set(
          Array.isArray(cached?.metadata?.boundaryIds)
            ? cached.metadata.boundaryIds
            : []
        );
        const color = getColor(rulesets, {
          ...territory.keyValuePairs,
          tags: territory.tags ?? [],
        });
        const hoverColor = tinycolor(color).darken(20).toString();
        const cachedFeature =
          cached != null
            ? turfHelper.feature(cached.data, {
                id: territory.id,
                territory_label: territory.label,
                hidden: hiddenTerritoryGroups.includes(territory.group),
                color,
                hoverColor,
              })
            : null;

        const cachedBBox =
          cachedFeature != null
            ? turfBuffer(
                turfBboxPolygon(turfBbox(cachedFeature)),
                bufferDistance,
                { units: bufferUnits }
              )
            : null;

        const renderedBBox = turfBboxPolygon(
          turfBbox(turfHelper.featureCollection(territoryRenderedFeatures))
        );

        const renderedBBoxWithinCached =
          cachedBBox != null && turfBooleanContains(cachedBBox, renderedBBox);

        const cachedMatchesTerritory =
          cached != null &&
          boundaryIdSet.size === cachedBoundaryIdSet.size &&
          isEqual(boundaryIdSet, cachedBoundaryIdSet);

        if (cachedMatchesTerritory && renderedBBoxWithinCached) {
          return { [territory.id]: cachedFeature };
        }

        // use Set to remove duplicates
        const renderedFeatureIds = Array.from(
          new Set(
            territoryRenderedFeatures.reduce((ids: string[], feature) => {
              const featureId = feature.id?.toString();
              if (featureId != null) {
                ids.push(featureId);
              }
              return ids;
            }, [])
          )
        );

        const renderedIsSubsetOfCached =
          cached != null &&
          renderedFeatureIds.every((id) => cachedBoundaryIdSet.has(id)) &&
          [...cachedBoundaryIdSet].every((id) => boundaryIdSet.has(id));

        if (renderedIsSubsetOfCached && renderedBBoxWithinCached) {
          return { [territory.id]: cachedFeature };
        }

        if (territoryRenderedFeatures.length > 0) {
          const zipCodePolygonFeatures = territoryRenderedFeatures.map(
            (feature) => {
              // HACK: geometry is only included in the feature if it's accessed first
              const withGeometry = { ...feature, geometry: feature.geometry };
              return feature.type !== 'Feature'
                ? turfHelper.feature(withGeometry as unknown as Polygon)
                : withGeometry;
            }
          ) as Feature<Polygon>[];

          return unionPolygons(zipCodePolygonFeatures, {
            buffer: {
              distance: bufferDistance,
              units: bufferUnits,
            },
            simplifyTolerance,
          }).then((unionedPolygonFeature) => {
            if (unionedPolygonFeature == null) {
              console.error(
                `Failed to union polygons for territory ${territory.id}`
              );
              return null;
            }

            // Cache the result
            territoryGeometryCache.set(
              cacheKey,
              unionedPolygonFeature.geometry,
              {
                boundaryIds: renderedFeatureIds,
              }
            );
            const color = getColor(rulesets, {
              ...territory.keyValuePairs,
              tags: territory.tags ?? [],
            });

            const hoverColor = tinycolor(color).darken(20).toString();
            return {
              [territory.id]: turfHelper.feature(
                unionedPolygonFeature.geometry,
                {
                  id: territory.id,
                  territory_label: territory.label,
                  hidden: hiddenTerritoryGroups.includes(territory.group),
                  color,
                  hoverColor,
                }
              ),
            };
          });
        }

        return null;
      })
      .filter(Boolean);

    if (onScreenTerritoryFeaturePromises.length === 0) {
      return;
    }

    // by handling unioning async (with a web worker), we can avoid blocking the main thread
    Promise.all(onScreenTerritoryFeaturePromises).then((featureByIds) => {
      const validFeatures = featureByIds.filter(Boolean) as {
        [id: string]: Feature<Polygon>;
      }[];

      const changedFeatures = validFeatures.filter((featureById) => {
        const [id, feature] = Object.entries(featureById)[0];
        const prevFeature = featureByTerritoryId.get(id);
        return !isEqual(prevFeature, feature);
      });

      if (changedFeatures.length > 0) {
        setFeatureByTerritoryId((prev) => {
          const updated = new Map(prev);
          changedFeatures.forEach((featureById) => {
            const [id, feature] = Object.entries(featureById)[0];
            updated.set(id, feature);
          });
          return updated;
        });
      }
    });
  }, [
    map,
    zoomLevel,
    mapChanges,
    unionPolygons,
    customTerritories,
    territoriesEnabled,
    featureByTerritoryId,
    hiddenTerritoryGroups,
    territoryGeometryCache,
    rulesets,
    evaluatedTerritory?.type,
  ]);

  const territoryFeatureCollection = useMemo(
    () =>
      turfHelper.featureCollection(Array.from(featureByTerritoryId.values())),
    [featureByTerritoryId]
  );

  // Click handlers for adding or removing boundaries to territories
  useEffect(() => {
    if (
      map == null ||
      evaluatedTerritoryId == null ||
      !isDrawingTerritory ||
      evaluatedTerritory?.type === TerritoryType.Custom
    )
      return;

    const mouseClickHandler = (e: MapMouseEvent) => {
      if (map == null) return;

      const zipFeature = map.queryRenderedFeatures(e.point, {
        layers: ['custom-territories-fill'],
      })?.[0];

      const zipCode = zipFeature?.id?.toString();

      if (zipCode != null && territoryMethods != null) {
        const territory = customTerritories.find(
          (territory) => territory.id === evaluatedTerritoryId
        );

        if (territory && territory.boundaries[zipCode]) {
          territoryMethods.removeBoundary(evaluatedTerritoryId, zipCode);
        } else {
          territoryMethods.addOrUpdateBoundary({
            territoryId: evaluatedTerritoryId,
            boundaryId: zipCode,
          });
        }
      }
    };

    map.on('click', mouseClickHandler);

    return () => {
      map.off('click', mouseClickHandler);
    };
  }, [
    map,
    customTerritories,
    evaluatedTerritoryId,
    territoryMethods,
    isDrawingTerritory,
    evaluatedTerritory?.type,
  ]);

  return (
    <>
      <Source
        id="custom-territories"
        type="vector"
        url="mapbox://luketruitt1.insights_zipcode"
        minzoom={4}
      >
        {/* This layer needs to stay mounted to support both viewing and editing territories */}
        <Layer
          id="custom-territories-fill"
          type="fill"
          source="custom-territories"
          source-layer="insights_zipcode"
          paint={{ 'fill-opacity': 0 }}
          layout={{
            visibility: territoriesEnabled ? 'visible' : 'none',
          }}
        />
        {evaluatedTerritoryId != null &&
          evaluatedTerritory?.type !== TerritoryType.Custom && (
            <Layer
              id="custom-territories-label"
              type="symbol"
              source="custom-territories"
              source-layer="insights_zipcode"
              minzoom={10}
              layout={{
                visibility: territoriesEnabled ? 'visible' : 'none',
                'text-field': ['to-string', ['id']],
                'text-font': ['Arial Unicode MS Regular'],
                'text-size': 16,
              }}
              paint={{
                'text-color': 'black',
                'text-halo-color': 'rgba(255, 255, 255, 0.5)',
                'text-halo-width': 2,
                'text-opacity': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  10,
                  0,
                  11,
                  1,
                ],
              }}
            />
          )}
      </Source>
      <Source
        id="defined-territories"
        type="geojson"
        promoteId="id"
        data={territoryFeatureCollection}
      >
        <Layer
          id="defined-territories-fill"
          type="fill"
          source="defined-territories"
          layout={{
            visibility: territoriesEnabled ? 'visible' : 'none',
          }}
          paint={{
            'fill-color': [
              'case',
              ['get', 'hidden'],
              '#cccccc', // Neutral color for hidden territories
              [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                ['get', 'hoverColor'], // Use the precomputed darker color on hover
                ['get', 'color'], // Normal color
              ],
            ],
            'fill-opacity': territoryFillEnabled
              ? ['case', ['get', 'hidden'], 0, 0.5]
              : 0,
          }}
        />
        <Layer
          id="defined-territories-line"
          type="line"
          source="defined-territories"
          layout={{
            visibility: territoriesEnabled ? 'visible' : 'none',
          }}
          paint={{
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              ['get', 'hoverColor'], // Use the precomputed darker color on hover
              ['get', 'color'], // Normal color
            ],
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              ['case', ['boolean', territoryFillEnabled], 0.1, 0.5],
              10,
              ['case', ['boolean', territoryFillEnabled], 2, 4],
            ],
            'line-opacity': ['case', ['get', 'hidden'], 0, 1],
          }}
        />

        <Layer
          id="defined-territories-label"
          type="symbol"
          source="defined-territories"
          minzoom={1}
          layout={{
            visibility:
              territoriesEnabled && territoryLabelsEnabled ? 'visible' : 'none',
            'text-field': ['get', 'territory_label'],
            'text-font': ['Arial Unicode MS Regular'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 8, 16, 10, 20],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          }}
          paint={{
            'text-color': 'black',
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 2,
            'text-opacity': ['case', ['get', 'hidden'], 0, 1],
          }}
        />
      </Source>
      <Source
        id="custom-territories"
        type="vector"
        url="mapbox://luketruitt1.insights_zipcode"
      >
        {evaluatedTerritoryId != null &&
          isDrawingTerritory &&
          evaluatedTerritory?.type !== TerritoryType.Custom && (
            <Layer
              id="custom-territories-line"
              type="line"
              source="custom-territories"
              source-layer="insights_zipcode"
              layout={{
                visibility: territoriesEnabled ? 'visible' : 'none',
              }}
              paint={{
                'line-color': '#333',
                'line-width': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.5,
                    0.1,
                  ],
                  10,
                  [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    3,
                    0.5,
                  ],
                  22,
                  [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    6,
                    1,
                  ],
                ],
              }}
            />
          )}
      </Source>
    </>
  );
};

export default CustomTerritoriesLayer;
