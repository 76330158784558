import { territorySchema } from '@plotr/plotr-multiplayer-data/src';
import { z } from 'zod';

export const editPinSchema = z
  .object({
    label: z.string().min(1, 'Label is required'),
    pinGroup: z.string().optional(),
    pinTerritories: z.array(territorySchema),
    tags: z.array(z.string()),
    kvPairs: z.record(z.string()),
    kvPairsToDelete: z.array(z.string()),
  })
  .passthrough();

export type EditPinSchema = z.infer<typeof editPinSchema>;
