import { parseEnv } from '@plotr/common-utils';
import { CustomTerritory } from '@plotr/plotr-multiplayer-data';
import { featureCollection, multiPolygon, polygon } from '@turf/helpers';
import { union } from '@turf/union';
import axios from 'axios';
import { Feature, FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import isEmpty from 'lodash.isempty';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

async function getTerritoryZipBoundary(
  zipcodes: string[]
): Promise<Feature<Polygon | MultiPolygon> | null> {
  const { data } = await axios.post<FeatureCollection<
    MultiPolygon | Polygon
  > | null>(
    `${env.API_V2}/merge`,
    {
      zipcodes,
      bbox: false,
    },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

  if (data?.features != null) {
    const coordinates = data.features.reduce<MultiPolygon['coordinates']>(
      (acc, feature) => {
        if (feature.geometry.type === 'Polygon') {
          acc.push(feature.geometry.coordinates);
        } else if (feature.geometry.type === 'MultiPolygon') {
          acc.push(...feature.geometry.coordinates);
        }
        return acc;
      },
      []
    );

    return multiPolygon(coordinates);
  }

  return null;
}

function getTerritoryCustomBoundary(
  territory: CustomTerritory
): Feature<MultiPolygon | Polygon> | null {
  if (isEmpty(territory.boundaries)) return null;

  const allBoundaries = Object.values(territory.boundaries)
    .filter((boundary) => boundary.coordinates)
    .map((boundary) => polygon([boundary.coordinates ?? []]));

  return allBoundaries.length === 1
    ? allBoundaries[0]
    : union(featureCollection(allBoundaries));
}

export interface GetTerritoryParams {
  accessToken: string;
  territory: CustomTerritory;
}

export default async function getTerritoryBoundary(params: GetTerritoryParams) {
  const { accessToken, territory } = params;

  if (isEmpty(territory.boundaries) || accessToken == null) return null;

  const zipcodes = Array.from(
    Object.values(territory.boundaries).map((boundary) =>
      boundary.id.padStart(5, '0')
    )
  );

  if (territory.type === 'ZIP' && zipcodes.length === 0) return null;

  try {
    if (territory.type === 'ZIP') {
      return getTerritoryZipBoundary(zipcodes);
    } else {
      return getTerritoryCustomBoundary(territory);
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}
