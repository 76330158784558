import type { AnyLayer } from 'mapbox-gl';
import { useEffect, useState } from 'react';
import useMapContext from './useMapContext';

export default function useLayerIds(filterFn: (layer: AnyLayer) => boolean) {
  const map = useMapContext();

  const [layerIds, setLayerIds] = useState<string[]>([]);

  useEffect(() => {
    if (map == null) return;

    const styleDataHandler = () => {
      if (map == null) return;

      setLayerIds(
        map
          .getStyle()
          .layers?.map((layer) => (filterFn(layer) ? layer.id : null))
          .filter((id): id is string => id != null)
      );
    };

    const styleDataLoadingHandler = () => {
      setLayerIds([]);
    };

    map.on('styledata', styleDataHandler);
    map.on('styledataloading', styleDataLoadingHandler);

    return () => {
      map.off('styledata', styleDataHandler);
      map.off('styledataloading', styleDataLoadingHandler);
    };
  }, [filterFn, map]);

  return layerIds;
}
