import { useCallback, useEffect, useMemo, useRef } from 'react';

export type WebWorkerMessage<T> = {
  id?: string;
  success: boolean;
} & (
  | {
      success: true;
      data: T;
    }
  | {
      success: false;
      error: string;
    }
);

export interface WebWorker<T, K> {
  postMessage: (message: T) => void;
  onMessage: (handler: (message: WebWorkerMessage<K>) => void) => void;
}

const workerByName: Record<string, Worker> = {
  unionPolygons: new Worker(
    new URL('~/src/assets/workers/unionPolygons.worker.js', import.meta.url),
    {
      name: 'unionPolygons',
    }
  ),
};

export default function useWebWorker<T = null, K = null>(
  workerName: string
): WebWorker<T, K> {
  const workerRef = useRef<Worker | null>(null);
  const messageHandlerRef = useRef<
    ((message: WebWorkerMessage<K>) => void) | null
  >(null);

  const worker = workerByName[workerName];

  useEffect(() => {
    if (worker == null) {
      console.error(`Worker not found: ${workerName}`);
      return;
    }

    workerRef.current = worker;

    // Handle messages from the worker
    workerRef.current.onmessage = (event) => {
      messageHandlerRef.current?.(event.data);
    };

    // Handle any error that occurs inside the worker
    workerRef.current.onerror = (error) => {
      console.error('Worker error:', error);
      messageHandlerRef.current?.({
        success: false,
        error: error.message,
      });
    };

    // Handle message errors, for example, when receiving non-clonable data
    workerRef.current.onmessageerror = (error) => {
      console.error('Message error:', error);
      messageHandlerRef.current?.({
        success: false,
        error: 'Message error',
      });
    };

    return () => {
      if (workerRef.current != null) {
        workerRef.current = null;
      }
    };
  }, [workerName, worker]);

  const postMessage = useCallback((message: T) => {
    workerRef.current?.postMessage(message);
  }, []);

  const onMessage = useCallback(
    (handler: (message: WebWorkerMessage<K>) => void) => {
      messageHandlerRef.current = handler;
    },
    []
  );

  const webWorkerMethods = useMemo(
    () => ({
      postMessage,
      onMessage,
    }),
    [postMessage, onMessage]
  );

  return webWorkerMethods;
}
