import { Box } from '@mui/material';
import {
  CustomTerritory,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import KeyValueTable from '../../demographic-point-lookup/components/KeyValueTable';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';

interface CustomTerritoryKVTableProps {
  territory: CustomTerritory;
}

const CustomTerritoryKVTable = ({ territory }: CustomTerritoryKVTableProps) => {
  const customTerritoryMethods = plotrMultiplayerData.methods?.territories;
  const setAlertMessage = useDynamicMapStore(
    (state) => state.setAlertMessageTerritory
  );
  const setAlertSeverity = useDynamicMapStore(
    (state) => state.setAlertSeverityTerritory
  );

  const alertMessage = (
    message: string,
    severity: 'success' | 'info' | 'warning' | 'error'
  ) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <KeyValueTable
        data={new Map(Object.entries(territory?.keyValuePairs || {}))}
        addKeyValuePair={(key, value) =>
          customTerritoryMethods?.addKeyValuePair(territory.id, key, value)
        }
        updateKeyValuePair={(key, value) =>
          customTerritoryMethods?.updateKeyValuePair(territory.id, key, value)
        }
        removeKeyValuePair={(key) =>
          customTerritoryMethods?.removeKeyValuePair(territory.id, key)
        }
        alertMessage={alertMessage}
      />
    </Box>
  );
};

export default CustomTerritoryKVTable;
