import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { Box, Typography } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import {
  CustomPin,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import axios from 'axios';
import { FC, useEffect, useRef, useState } from 'react';
import coordinatesGeocoder from '~/src/features/dynamic-map/helpers/CoordinatesGeocoder';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';

const env = parseEnv({
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
});

interface LocationSearchProps {
  pin: CustomPin;
  isEditing: boolean;
}

const LocationSearch: FC<LocationSearchProps> = ({ pin, isEditing }) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentAddress, setCurrentAddress] = useState<string | null>(null);
  const map = useMapContext();

  useEffect(() => {
    axios
      .get<{ features: { place_name: string }[] }>(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${pin.pos.lng},${pin.pos.lat}.json?access_token=${env.MAPBOX_API_KEY}`
      )
      .then(({ data }) => {
        const address = data.features?.[0]?.place_name;
        setCurrentAddress(address || null);
      })
      .catch((error) => {
        console.error('Error fetching address:', error);
        setCurrentAddress(null);
      });
  }, [pin.pos]);

  useEffect(() => {
    if (!isEditing || !containerRef.current) return;

    const geocoder = new MapboxGeocoder({
      marker: false,
      accessToken: env.MAPBOX_API_KEY || '',
      localGeocoder: coordinatesGeocoder,
      placeholder: 'Search address or coordinates',
    });

    geocoder.on('result', (evt) => {
      const { result } = evt;
      const location =
        result &&
        (result.center ||
          (result.geometry?.type === 'Point' && result.geometry.coordinates));

      if (location && customPinMethods) {
        customPinMethods.setPos(pin.id, {
          lng: location[0],
          lat: location[1],
        });

        map?.flyTo({
          center: [location[0], location[1]],
          zoom: 15,
        });
      }
    });

    // Use a timeout to ensure the DOM is ready
    setTimeout(() => {
      if (containerRef.current) {
        geocoder.addTo(containerRef.current);
      }
    }, 0);

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [customPinMethods, isEditing, pin.id, map]);

  const displayLocation =
    currentAddress || `${pin.pos.lat.toFixed(6)}, ${pin.pos.lng.toFixed(6)}`;

  if (!isEditing) {
    return (
      <Box>
        <Typography variant="body2">{displayLocation}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Current location: {displayLocation}
      </Typography>
      <div ref={containerRef} />
    </Box>
  );
};

export default LocationSearch;
