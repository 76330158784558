import { Box } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Map, {
  Layer,
  LngLatLike,
  MapRef,
  NavigationControl,
  Source,
} from 'react-map-gl';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { RecenterControl } from '../dynamic-map/helpers/RecenterControl';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import { heatmapLayer } from './heatmapLayer';
import { formatReportsToHeatmap } from './helpers/formatReportsToHeatmap';
import { ReportPin, ReportPinProps } from './ReportPin';
import { ReportItem } from './services/pulse-service';
import { filterSurroundingPins } from '../dynamic-map-controller/helpers/filterSurroundingPins';
import { MobileDataConfig, PulseReportConfig } from '~/src/constants';
import usePulseReportStore, { DataSections } from './hooks/usePulseReportStore';

const env = parseEnv({
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
  API_V2: process.env.API_V2,
});

const mapStyle = 'mapbox://styles/mapbox/streets-v12';
const { MAX_SURROUNDING_PIN_DISTANCE } = MobileDataConfig;
const { EVALUATED_PIN_COLOR } = PulseReportConfig;

export const ReportMap = ({
  reports,
  selectedPinId = -1, // Set default to -1 instead of 0
  setSelectedPinId,
  zoomBbox,
  preparedLocation,
  displayDensity = false,
  additionalLayers,
  context,
}: {
  reports: ReportItem[];
  selectedPinId: number;
  setSelectedPinId: (index: number) => void;
  zoomBbox?: [number, number, number, number];
  preparedLocation?: {
    lat: number;
    lng: number;
  };
  displayDensity?: boolean;
  additionalLayers?: React.ReactNode;
  context: 'competitors' | 'generators';
}) => {
  const { setMapRef, setMapInitialViewState, setMapPins } = usePulseReportStore(
    (state) => ({
      setMapRef: state.setMapRef,
      setMapInitialViewState: state.setMapInitialViewState,
      setMapPins: state.setMapPins,
    })
  );
  const mapRef = useRef<MapRef>(null);
  const [, setZoomLevel] = useState(10);
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const { customPins: pins } = useCustomPins();
  const evaluatedPin = useMemo(
    () => pins.find((pin) => pin.id === evaluatedPinId),
    [pins, evaluatedPinId]
  );
  const surroundingPins = useMemo(() => {
    if (!evaluatedPin) return [];
    return filterSurroundingPins({
      evaluatedPin,
      surroundingPins: pins.filter((pin) => pin.id !== evaluatedPin.id),
      maxDistance: MAX_SURROUNDING_PIN_DISTANCE,
    });
  }, [pins, evaluatedPin]);

  const [, setLastLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(preparedLocation ?? null);

  const mapPins: ReportPinProps[] = useMemo(() => {
    const evaluatedPinMarker = evaluatedPin
      ? [
          {
            pos: evaluatedPin.pos,
            isSelected: false,
            isEvaluated: true,
            onClick: () => {},
            color: EVALUATED_PIN_COLOR,
            zIndex: 2,
          },
        ]
      : [];

    const reportPinsMarkers = reports.map((report, index) => {
      const isSelected = selectedPinId === index;

      return {
        key: report.id + index,
        pos: report,
        color: '#ffdba6',
        website: report.websiteUrl ?? undefined,
        selectedColor: '#ff9900',
        selectedBorder: 'black',
        isSelected: isSelected,
        onClick: () => setSelectedPinId(index),
        label: String(index + 1),
        zIndex: isSelected ? 3 : 1,
      };
    });

    const surroundingPinsMarkers = surroundingPins.map((pin) => ({
      pos: pin.pos,
      isSelected: false,
    }));

    return [
      ...evaluatedPinMarker,
      ...reportPinsMarkers,
      ...surroundingPinsMarkers,
    ];
  }, [evaluatedPin, reports, selectedPinId, setSelectedPinId, surroundingPins]);

  const handleRecenter = (
    map: MapRef | null,
    zoomBbox?: [number, number, number, number]
  ) => {
    if (map && zoomBbox) {
      map.fitBounds(
        [
          [zoomBbox[0], zoomBbox[1]],
          [zoomBbox[2], zoomBbox[3]],
        ],
        { padding: 10 }
      );
    }
  };

  const initialViewState = useMemo(
    () =>
      zoomBbox
        ? {
            bounds: [
              [zoomBbox[0], zoomBbox[1]],
              [zoomBbox[2], zoomBbox[3]],
            ] as [LngLatLike, LngLatLike],
            fitBoundsOptions: { padding: 10 },
          }
        : undefined,
    [zoomBbox]
  );

  const getDataSection = useCallback(
    () =>
      context === 'competitors'
        ? DataSections.Competitors
        : DataSections.Generators,
    [context]
  );

  useEffect(() => {
    setMapRef(getDataSection(), mapRef);
  }, [mapRef, setMapRef, getDataSection]);

  useEffect(() => {
    setMapInitialViewState(getDataSection(), initialViewState);
  }, [initialViewState, setMapInitialViewState, getDataSection]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMapPins(getDataSection(), mapPins);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [mapPins, setMapPins, getDataSection]);

  return (
    <Box
      flex={1}
      width="100%"
      pl={2}
      sx={{
        minHeight: '560px',
        maxHeight: '560px',
      }}
    >
      {zoomBbox && (
        <Map
          preserveDrawingBuffer={true}
          scrollZoom={false}
          styleDiffing={false}
          ref={mapRef}
          mapboxAccessToken={env.MAPBOX_API_KEY}
          mapStyle={mapStyle}
          attributionControl={true}
          initialViewState={initialViewState}
          onMoveEnd={(e) => {
            setZoomLevel(e.viewState.zoom);
            setLastLocation({
              lat: e.viewState.latitude,
              lng: e.viewState.longitude,
            });
          }}
        >
          <RecenterControl
            onRecenter={() => handleRecenter(mapRef.current, zoomBbox)}
          />
          <NavigationControl
            position="bottom-right"
            showCompass={true}
            showZoom={true}
            visualizePitch={false}
          />
          {mapPins.map((props, index) => (
            <ReportPin key={index} {...props} />
          ))}
          {additionalLayers}
          {reports.length > 0 && displayDensity && (
            <Source
              id="heatmap-source"
              type="geojson"
              data={formatReportsToHeatmap(reports)}
            >
              <Layer {...heatmapLayer} />
            </Source>
          )}
        </Map>
      )}
    </Box>
  );
};
