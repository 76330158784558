import { Feature, Point } from 'geojson';
import { useEffect } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import getPinURL from '~/src/common/helpers/getPinURL';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';

import {
  defaultIconSize,
  defaultZoomStops,
  exponentialIconRatio,
} from '../../../dynamic-map/components/POISource/POIHelpers';
import useLayerIds from '../../../dynamic-map/hooks/useLayerIds';
import useMapContext from '../../../dynamic-map/hooks/useMapContext';
import useMapImage from '../../../dynamic-map/hooks/useMapImage';

interface PricingItemValue {
  semantic_key: string;
  Value: string | number;
}

interface PricingItem {
  item_group?: PricingItemValue;
  category?: PricingItemValue;
  sub_category?: PricingItemValue;
  promo_code?: PricingItemValue;
  predicted_price?: PricingItemValue;
  [key: string]: PricingItemValue | undefined;
  price_action?: PricingItemValue;
  price_change?: PricingItemValue;
  price_change_percentage?: PricingItemValue;
  national_index_group?: PricingItemValue;
  market_index_group?: PricingItemValue;
}

export interface FormattedFranchiseData {
  facilityNumber: string;
  facilityName: string;
  territory: string;
  cbsa: string;
  urbanizationType: string;
  radius: number;
  taidId: number;
  address: string;
  coordinates: string;
  pulseTier: number;
  tierChange: number;
  tierAction: string;
  avgNationalIndex: number;
  avgMarketIndex: number;
  datesCollected: string;
  offerings: Array<{
    name: string;
    category: string;
    subCategory: string;
    promoCode: string;
    price: number;
    actualPrice: number | null;
    tierRange: string;
    priceAction: string;
    priceChange: number;
    priceChangePercentage: number;
  }>;
  popupCoordinates: {
    longitude: number;
    latitude: number;
  };
}

interface PulsePriceFranchisesSourceProps {
  sourceId?: string;
  layerId?: string;
  data: GeoJSON.FeatureCollection;
  onFranchiseClick?: (location: FormattedFranchiseData) => void;
}

const PulsePriceFranchisesSource: React.FC<PulsePriceFranchisesSourceProps> = ({
  sourceId = 'franchise-source',
  layerId = 'franchise-layer',
  data,
  onFranchiseClick,
}: PulsePriceFranchisesSourceProps) => {
  const map = useMapContext();
  const roadsLayerId = useLayerIds((layer) => layer.id === 'roads-layer')[0];
  const { userSettings } = useSettingsStore();
  const client = userSettings?.client;

  // Create pins for each state
  const increasePinURL = getPinURL({
    color: 'rgba(255, 0, 0, 1)', // Red for positive tier change
    background: '#FFFFFF',
    borderColor: '#000000',
  });
  const decreasePinURL = getPinURL({
    color: 'rgba(0, 255, 0, 1)', // Green for negative tier change
    background: '#FFFFFF',
    borderColor: '#000000',
  });
  const defaultPinURL = getPinURL({
    color: 'rgba(128, 128, 128, 1)', // Grey for no change
    background: '#FFFFFF',
    borderColor: '#000000',
  });

  // Load all pin images
  const isIncreasePinReady = useMapImage({
    id: `${layerId}-increase`,
    src: increasePinURL,
  });
  const isDecreasePinReady = useMapImage({
    id: `${layerId}-decrease`,
    src: decreasePinURL,
  });
  const isDefaultPinReady = useMapImage({
    id: `${layerId}-default`,
    src: defaultPinURL,
  });

  // Only proceed when all images are loaded
  const areImagesReady =
    isIncreasePinReady && isDecreasePinReady && isDefaultPinReady;

  // Create expression for icon-image based on tier change
  const iconImageExpression: Expression = [
    'case',
    ['>', ['get', 'tier_change'], 0],
    `${layerId}-increase`,
    ['<', ['get', 'tier_change'], 0],
    `${layerId}-decrease`,
    `${layerId}-default`,
  ];

  function transformFranchiseData(
    feature: Feature<Point>,
    clickCoords: { lng: number; lat: number }
  ): FormattedFranchiseData {
    const p = feature.properties;
    if (!p) throw new Error('No properties found in feature');

    const dataArray = typeof p.data === 'string' ? JSON.parse(p.data) : p.data;

    const offerings = Array.isArray(dataArray)
      ? dataArray.map((item: PricingItem) => {
          const clientPrice = item[`${client}_price`];
          return {
            name: String(item.item_group?.Value || ''),
            category: String(item.category?.Value || ''),
            subCategory: String(item.sub_category?.Value || ''),
            promoCode: String(item.promo_code?.Value || ''),
            price: Number(item.predicted_price?.Value || 0),
            actualPrice: clientPrice?.Value ? Number(clientPrice.Value) : null,
            tierRange: String(item.tier_range?.Value || ''),
            priceAction: String(item.price_action?.Value || ''),
            priceChange: Number(item.price_change?.Value || 0),
            priceChangePercentage: Number(
              item.price_change_percentage?.Value || 0
            ),
          };
        })
      : [];

    // Calculate average indices
    const avgNationalIndex = Array.isArray(dataArray)
      ? Math.round(
          dataArray.reduce(
            (acc: number, item: PricingItem) =>
              acc + Number(item.national_index_group?.Value || 0),
            0
          ) / dataArray.length
        )
      : 0;

    const avgMarketIndex = Array.isArray(dataArray)
      ? Math.round(
          dataArray.reduce(
            (acc: number, item: PricingItem) =>
              acc + Number(item.market_index_group?.Value || 0),
            0
          ) / dataArray.length
        )
      : 0;

    const [longitude, latitude] = feature.geometry.coordinates;

    return {
      facilityNumber: String(p.facility_nbr || ''),
      facilityName: String(p.facility_nm || ''),
      territory: String(p.territory || ''),
      cbsa: String(p.cbsa || ''),
      urbanizationType: String(p.urbanization_type || ''),
      radius: Number(p.radius || 0),
      taidId: Number(p.taid_id || 0),
      address: String(p.address || ''),
      coordinates: `${latitude}, ${longitude}`,
      pulseTier: Number(p.pulse_tier || 0),
      tierChange: Number(p.tier_change || 0),
      tierAction: String(p.tier_action || ''),
      avgNationalIndex,
      avgMarketIndex,
      datesCollected: p.dates_collected || '',
      offerings,
      popupCoordinates: {
        longitude: clickCoords.lng,
        latitude: clickCoords.lat,
      },
    };
  }

  useEffect(() => {
    if (!map) return;

    const handleClick = (event: MapLayerMouseEvent) => {
      event.preventDefault();
      const feature = event.features?.[0];
      if (feature?.geometry?.type === 'Point' && feature.properties) {
        const formattedLocation = transformFranchiseData(
          feature as Feature<Point>,
          event.lngLat
        );
        onFranchiseClick?.(formattedLocation);
      }
    };

    map.on('click', layerId, handleClick);
    return () => {
      map.off('click', layerId, handleClick);
    };
  }, [map, layerId, onFranchiseClick, client]);

  return (
    <Source id={sourceId} type="geojson" data={data}>
      <Layer
        id={layerId}
        type="symbol"
        layout={{
          'icon-image': areImagesReady ? iconImageExpression : '',
          'icon-anchor': 'bottom',
          'icon-allow-overlap': true,
          'icon-size': {
            type: 'exponential',
            stops: [
              [defaultZoomStops[0], defaultIconSize * exponentialIconRatio],
              [defaultZoomStops[1], defaultIconSize],
            ],
          },
        }}
        beforeId={roadsLayerId}
      />
    </Source>
  );
};

export default PulsePriceFranchisesSource;
