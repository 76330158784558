import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import { useMemo } from 'react';
import useCustomPins from '~/src/global/hooks/useCustomPins';

interface DeletePinGroupDialogProps {
  group: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function DeletePinGroupDialog({
  group,
  isOpen,
  onClose,
}: DeletePinGroupDialogProps) {
  const { customPins } = useCustomPins();
  const pinMethods = plotrMultiplayerData.methods?.pins;

  const pinIds = useMemo(() => {
    return customPins.filter((pin) => pin.group === group).map((pin) => pin.id);
  }, [customPins, group]);

  const handleDelete = () => {
    pinMethods?.removePins(pinIds);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText display="flex" gap={0.5}>
          <Typography>
            Are you sure you want to delete the <strong>{group}</strong> pin
            group?
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography>
            This will permanently delete {pinIds.length} pin
            {pinIds.length > 1 ? 's' : ''}.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="info">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
