export const formatDateRange = (dateRange: string) => {
  if (!dateRange) return '-';

  // Convert from "8.9.24-9.3.24" to "Aug 9, 2024 - Sep 3, 2024"
  const [start, end] = dateRange.split('-');
  if (!start || !end) return dateRange; // Return original if format doesn't match

  const formatDate = (date: string) => {
    const [month, day, year] = date.split('.');
    if (!month || !day || !year) return date; // Return original if format doesn't match

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthIndex = parseInt(month) - 1;
    if (monthIndex < 0 || monthIndex >= 12) return date;

    return `${monthNames[monthIndex]} ${parseInt(day)}, 20${year}`;
  };

  return `${formatDate(start)} - ${formatDate(end)}`;
};

export const formatTierRange = (tierRange: string | null | undefined): string => {
  if (!tierRange) return '-';

  // Handle ranges like "208-325"
  const parts = tierRange.split('-');
  if (parts.length !== 2) return tierRange;

  const [start, end] = parts;
  const formatNumber = (num: string) => {
    const parsed = parseInt(num.trim());
    if (isNaN(parsed)) return num.trim();
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(parsed);
  };

  return `${formatNumber(start)} - ${formatNumber(end)}`;
};
