export const TEXT_STYLES = {
  light: {
    color: 'black',
    textShadow: `
      1px 1px 0 white,
      -1px -1px 0 white,
      1px -1px 0 white,
      -1px 1px 0 white,
      1px 0px 0 white,
      0px 1px 0 white,
      -1px 0px 0 white,
      0px -1px 0 white
    `,
  },
  dark: {
    color: 'white',
    textShadow: `
      1px 1px 0 black,
      -1px -1px 0 black,
      1px -1px 0 black,
      -1px 1px 0 black,
      1px 0px 0 black,
      0px 1px 0 black,
      -1px 0px 0 black,
      0px -1px 0 black
    `,
  },
} as const;

export const PIN_SCALE_CONFIG = {
  MIN_SCALE: 0.25,
  MAX_SCALE: 1,
  DEFAULT_ICON_SIZE: 1,
  EXPONENTIAL_ICON_RATIO: 1 / 6,
  ZOOM_STOPS: [6, 16] as const,
} as const;
