import { parseEnv, preventDuplicateItems } from '@plotr/common-utils';
import { CustomPin } from '@plotr/plotr-multiplayer-data';
import turfCircle from '@turf/circle';
import axios from 'axios';
import { useQuery } from 'react-query';
import { Boundary } from '../../demographic-point-lookup/hooks/useDemographicStore';
import { PlacesRequest } from '../../dynamic-map/services/poiService';

const env = parseEnv({
  PLOTR_API: process.env.PLOTR_API,
  API_V2: process.env.API_V2,
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
});

export type ReportItem = {
  id: string;
  name: string;
  address: string;
  lng: number;
  lat: number;
  websiteUrl: string | null;
};

const PULSE_REPORT_QUERY_KEY = 'QUERY_KEY_PULSE_REPORT';
const REPORT_MATRIXES_QUERY_KEY = 'QUERY_KEY_REPORT_MATRIXES';
const MAPBOX_COORDINATES_LIMIT = 24;

export interface QueryGroup {
  id: string;
  name: string;
  queries: object[];
}

export const getReportBrands = async (
  competitors: { brandId: string }[],
  generators: { brandId: string }[],
  competitorsQueries: PlacesRequest[],
  generatorsQueries: PlacesRequest[],
  evaluatedPin: CustomPin,
  boundaryData: Boundary
) => {
  const [competitorsRes, generatorsRes] = await Promise.all([
    axios.get<{ id: string }[]>(
      `${env.PLOTR_API}/poi-brand?ids=${encodeURIComponent(
        competitors.map((brand) => brand?.brandId).toString()
      )}`
    ),
    axios.get<{ id: string }[]>(
      `${env.PLOTR_API}/poi-brand?ids=${encodeURIComponent(generators.map((brand) => brand?.brandId).toString())}`
    ),
  ]);

  const [competitorsReport, generatorsReport] = await Promise.all([
    axios.post<ReportItem[]>(`${env.API_V2}/brand/competitors/`, {
      safegraphBrandIds: competitorsRes.data.map((brand) => brand.id),
      queries: competitorsQueries.map((query) => query.query),
      longitude: evaluatedPin?.pos.lng,
      latitude: evaluatedPin?.pos.lat,
      customBoundary: boundaryData,
      limit: 20,
    }),
    axios.post<ReportItem[]>(`${env.API_V2}/brand/competitors/`, {
      safegraphBrandIds: generatorsRes.data.map((brand) => brand.id),
      queries: generatorsQueries.map((query) => query.query),
      longitude: evaluatedPin?.pos.lng,
      latitude: evaluatedPin?.pos.lat,
      customBoundary: turfCircle(
        [evaluatedPin.pos.lng, evaluatedPin.pos.lat],
        0.5,
        {
          units: 'miles',
        }
      ),
      limit: 20,
    }),
  ]);

  const uniqueCompetitors = preventDuplicateItems<ReportItem>('id')(
    competitorsReport.data
  );
  const uniqueGenerators = preventDuplicateItems<ReportItem>('id')(
    generatorsReport.data
  );

  return {
    competitorsReport: uniqueCompetitors,
    generatorsReport: uniqueGenerators,
  } as {
    competitorsReport: ReportItem[];
    generatorsReport: ReportItem[];
  };
};
export const usePulseReport = (
  competitors: { brandId: string }[],
  generators: { brandId: string }[],
  competitorsQueries: PlacesRequest[],
  generatorsQueries: PlacesRequest[],
  evaluatedPin: CustomPin | null,
  boundaryData: Boundary | null,
  enabled: boolean = false
) => {
  return useQuery({
    queryKey: [PULSE_REPORT_QUERY_KEY, evaluatedPin, boundaryData],
    queryFn: () =>
      getReportBrands(
        competitors,
        generators,
        competitorsQueries,
        generatorsQueries,
        evaluatedPin!,
        boundaryData!
      ),
    enabled: !!evaluatedPin && !!boundaryData && enabled,
  });
};

export const getReportsByQueries = async (
  competitors: PlacesRequest[],
  generators: PlacesRequest[],
  evaluatedPin: CustomPin,
  boundaryData: Boundary
) => {
  const [competitorsRes, generatorsRes] = await Promise.all([
    await axios.post<ReportItem[]>(`${env.API_V2}/brand/competitors/query`, {
      queries: competitors.map((group) => group.query),
      longitude: evaluatedPin?.pos.lng,
      latitude: evaluatedPin?.pos.lat,
      customBoundary: boundaryData,
      limit: MAPBOX_COORDINATES_LIMIT,
    }),
    await axios.post<ReportItem[]>(`${env.API_V2}/brand/competitors/query`, {
      queries: generators.map((group) => group.query),
      longitude: evaluatedPin?.pos.lng,
      latitude: evaluatedPin?.pos.lat,
      customBoundary: boundaryData,
      limit: MAPBOX_COORDINATES_LIMIT,
    }),
  ]);

  const uniqueCompetitors = preventDuplicateItems<ReportItem>('id')(
    competitorsRes.data
  );
  const uniqueGenerators = preventDuplicateItems<ReportItem>('id')(
    generatorsRes.data
  );

  return {
    competitorsReport: uniqueCompetitors,
    generatorsReport: uniqueGenerators,
  };
};

export const useReportsByQueries = (
  competitors: PlacesRequest[],
  generators: PlacesRequest[],
  evaluatedPin: CustomPin | null,
  boundaryData: Boundary | null,
  enabled: boolean = false
) => {
  return useQuery({
    queryKey: [PULSE_REPORT_QUERY_KEY, evaluatedPin, boundaryData],
    queryFn: () =>
      getReportsByQueries(
        competitors,
        generators,
        evaluatedPin!,
        boundaryData!
      ),
    enabled: !!evaluatedPin && !!boundaryData && enabled,
  });
};

// Fetch drive time between point from Mapbox API

export const getDriveTime = async (
  firstPointLng: number,
  firstPointLat: number,
  reportLng: number,
  reportLat: number
) => {
  const res = await axios.get<{
    durations: number[][];
    destinations: { distance: number }[];
  }>(
    `https://api.mapbox.com/directions-matrix/v1/mapbox/driving/${firstPointLng},${firstPointLat};${reportLng},${reportLat}`,
    {
      params: {
        access_token: env.MAPBOX_API_KEY,
        destinations: '1',
      },
    }
  );

  return res.data;
};

export interface ReportMatrixResponse {
  durations: number[][];
  distances: number[][];
  destinations: {
    distance: number;
    name: string;
    location: [number, number];
  }[];
  sources: {
    distance: number;
    name: string;
    location: [number, number];
  }[];
}

export const getReportMatrixes = async (
  reports: ReportItem[],
  startingPoint: { lng: number; lat: number }
) => {
  const reportChunks = reports.reduce((acc, report, index) => {
    if (index % MAPBOX_COORDINATES_LIMIT === 0) {
      acc.push(reports.slice(index, index + MAPBOX_COORDINATES_LIMIT));
    }
    return acc;
  }, [] as ReportItem[][]);

  try {
    const matrixes = await Promise.all(
      reportChunks.map(async (chunk) => {
        const res = await axios.get<ReportMatrixResponse>(
          `https://api.mapbox.com/directions-matrix/v1/mapbox/driving/${startingPoint.lng},${startingPoint.lat};${chunk
            .map((report) => `${report.lng},${report.lat}`)
            .join(';')}`,
          {
            params: {
              access_token: env.MAPBOX_API_KEY,
              annotations: 'distance,duration',
            },
          }
        );
        return res.data;
      })
    );
    return matrixes;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useReportMatrixes = (
  reports: ReportItem[],
  startingPoint: { lng: number; lat: number } | null
) => {
  return useQuery({
    queryKey: [REPORT_MATRIXES_QUERY_KEY, reports, startingPoint],
    queryFn: () => getReportMatrixes(reports, startingPoint!),
    enabled: !!startingPoint && !!reports,
  });
};
