import { parseEnv } from '@plotr/common-utils';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import { MultiplayerProvider } from '@plotr/react-multiplayer';

import DynamicMap from '~/src/features/dynamic-map/DynamicMap';
import SubscriptionDialog from '~/src/global/components/SubscriptionDialog';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useDefaultProject from '~/src/global/hooks/useDefaultProject';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';

import { useCallback, useEffect } from 'react';
import NavBar from './NavBar';
import useAuthRedirect from './useAuthRedirect';

import { Box, Typography } from '@mui/material';
import axios from 'axios';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';

const env = parseEnv({
  PLOTR_API: process.env.PLOTR_API,
  MULTIPLAYER_SIGNALING_SERVER: process.env.MULTIPLAYER_SIGNALING_SERVER,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export default function UserDashboard() {
  const { user, isLoading: authLoading, LoadingComponent } = useAuthRedirect();
  const { accessToken, isLoading: tokenLoading } = useAccessToken();

  const defaultProject = useDefaultProject();

  const {
    userSettingsError,
    assignedRoomId,
    userSettingsLoading,
    fetchUserSettings,
    userSettings,
  } = useSettingsStore((state) => ({
    userSettingsError: state.error,
    assignedRoomId: state.userSettings?.assignedRoomId,
    userSettingsLoading: state.isLoading,
    fetchUserSettings: state.fetchUserSettings,
    userSettings: state.userSettings,
  }));

  const setIsReadOnly = usePermissionsStore((state) => state.setIsReadOnly);

  const checkReadOnly = useCallback(async () => {
    if (
      userSettings?.assignedRoomId &&
      userSettings.assignedRoomId !== defaultProject?.room._id
    ) {
      try {
        const { data } = await axios.get<{
          users: {
            userId: string;
            permissions: { read?: boolean; write?: boolean };
          }[];
        }>(`${env.PLOTR_API}/multiplayer/room/${userSettings.assignedRoomId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const users = data.users;

        const isReadOnly = users.some(
          (user) =>
            user.userId === userSettings.userId && !user.permissions.write
        );

        setIsReadOnly(isReadOnly);
      } catch (error) {
        console.error('Error checking read-only status:', error);
        setIsReadOnly(true);
      }
    }
  }, [
    accessToken,
    defaultProject?.room._id,
    setIsReadOnly,
    userSettings?.assignedRoomId,
    userSettings?.userId,
  ]);

  useEffect(() => {
    if (userSettingsLoading) return;
    checkReadOnly();
  }, [checkReadOnly, userSettingsLoading]);

  // Fetch user settings to get enterprise layer access
  useEffect(() => {
    if (accessToken == null) return;

    fetchUserSettings(accessToken);
  }, [accessToken, fetchUserSettings]);

  // Log any errors fetching user settings
  useEffect(() => {
    if (userSettingsError != null) {
      console.error('Error fetching user settings:', userSettingsError);
    }
  }, [userSettingsError]);

  window.pendo.initialize({
    visitor: {
      id: user?.sub,
      email: user?.email,
    },

    account: {
      id: user?.sub,
      accountName: user?.email,
      payingStatus: 'Team',
    },
  });

  const isLoading =
    authLoading ||
    tokenLoading ||
    userSettingsLoading ||
    defaultProject == null;

  const emailVerified = user?.email_verified ?? false;

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      onDrop={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
    >
      <NavBar />
      <SubscriptionDialog />
      <Box flex={1} overflow="auto">
        {emailVerified ? (
          <MultiplayerProvider
            room={assignedRoomId ?? defaultProject.room._id}
            signaling={env.MULTIPLAYER_SIGNALING_SERVER}
            token={accessToken}
            api={env.PLOTR_API + '/multiplayer'}
            data={plotrMultiplayerData}
          >
            <DynamicMap />
          </MultiplayerProvider>
        ) : user != null ? (
          <Box
            display="flex"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              Please check your email to verify your account, then refresh this
              page.
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
