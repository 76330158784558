import {
  AddLocation as AddLocationIcon,
  Bolt as BoltIcon,
  ContentCopy as ContentCopyIcon,
  GpsFixed as GpsFixedIcon,
} from '@mui/icons-material';
import {
  CustomPin,
  CustomTerritory,
  PinTemplate,
  plotrMultiplayerData,
  supportedCountriesArray,
} from '@plotr/plotr-multiplayer-data';
import { MapMouseEvent } from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as randomUUID } from 'uuid';
import { copyToClipboard } from '~/src/common/helpers/copyToClipboard';
import { getPointLocation } from '~/src/common/helpers/getPointPlace';
import {
  ModalNames,
  useModalDataContext,
} from '~/src/common/hooks/useModalDataContext';
import {
  FetchPOIDataParams,
  FetchSonarDataParams,
  fetchPOIData,
  fetchSonarData,
} from '~/src/features/dynamic-map/services/poiService';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import usePinTemplates from '~/src/global/hooks/usePinTemplates';
import useTrackMapChanges from '~/src/global/hooks/useTrackMangeChanges';
import useDemographicStore, {
  POIData,
  SonarData,
} from '../demographic-point-lookup/hooks/useDemographicStore';
import useCustomTerritories from '../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import useFlyToTerritory from '../dynamic-map/hooks/useFlyToTerritory';
import useMapContext from '../dynamic-map/hooks/useMapContext';
import ContextMenu from './ContextMenu';
import {
  checkFeatureType,
  getDemographicEntity,
} from './helpers/getDemographicEntity';
import useContextMenuStore, { ContextMenuOption } from './useContextMenuStore';

export default function MapContextMenu() {
  const map = useMapContext();
  const { accessToken } = useAccessToken();
  const { customPins } = useCustomPins();
  const { customTerritories } = useCustomTerritories();
  const templates = usePinTemplates();
  const flyToTerritory = useFlyToTerritory();
  const pinMethods = plotrMultiplayerData.methods?.pins;
  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);
  const [isMapMoving, setIsMapMoving] = useState(false);

  const {
    selectCustomPinId,
    clickedPOiFeature,
    evaluatedDemographicEntity,
    evaluatedTerritoryId,
    isDrawingTerritory,
    setEvaluatedDemographicEntity,
    setEvaluatedTerritoryId,
    setSelectedTerritoryGroup,
    pinToCopy,
    setPinToCopy,
  } = useDynamicMapStore((state) => ({
    selectCustomPinId: state.selectCustomPinId,
    clickedPOiFeature: state.clickedPOiFeature,
    evaluatedDemographicEntity: state.evaluatedDemographicEntity,
    setEvaluatedDemographicEntity: state.setEvaluatedDemographicEntity,
    evaluatedTerritoryId: state.evaluatedTerritoryId,
    setEvaluatedTerritoryId: state.setEvaluatedTerritoryId,
    isDrawingTerritory: state.isDrawingTerritory,
    setSelectedTerritoryGroup: state.setSelectedTerritoryGroup,
    pinToCopy: state.pinToCopy,
    setPinToCopy: state.setPinToCopy,
  }));

  const { onOpenChange, onDataChange } = useModalDataContext();

  const setContextMenu = useContextMenuStore((state) => state.setContextMenu);

  const mapChanges = useTrackMapChanges();

  const centerMapOnPin = useCallback(
    (position: { lng: number; lat: number }) => {
      if (map != null) {
        map.flyTo({ center: [position.lng, position.lat], essential: true });
      }
    },
    [map]
  );

  const setSonarData = useDemographicStore((state) => state.setSonarData);
  const setSonarDataIsLoading = useDemographicStore(
    (state) => state.setSonarDataIsLoading
  );
  const setSonarDataError = useDemographicStore(
    (state) => state.setSonarDataError
  );
  const setPoiDataError = useDemographicStore((state) => state.setPoiDataError);

  const copiedPin = useMemo(
    () => (pinToCopy ? customPins.find((pin) => pin.id === pinToCopy) : null),
    [pinToCopy, customPins]
  );

  const handleCreatePin = useCallback(
    async (
      e: MapMouseEvent,
      isCopyingPin?: boolean,
      template?: PinTemplate
    ) => {
      const templatePayload = template && {
        label: template.label ?? '',
        keyValuePairs: template.keyValuePairs ?? {},
        tags: template.tags ?? [],
        group: template.group,
      };

      const location = await getPointLocation(e.lngLat.lng, e.lngLat.lat);

      const countryCode = location.country.short_code;

      const newPin: CustomPin = {
        group: 'Default Group',
        label: `Pin ${customPins.length + 1}`,
        tags: [],
        keyValuePairs: {},
        ...(isCopyingPin ? copiedPin : {}),
        ...(!isCopyingPin && template ? templatePayload : {}),
        pos: e.lngLat,
        country: supportedCountriesArray.includes(countryCode)
          ? countryCode
          : null,
        id: randomUUID(),
      };

      pinMethods && pinMethods.addPin(newPin);
      selectCustomPinId(newPin.id);
      setPinToCopy(null);
    },
    [copiedPin, customPins.length, pinMethods, selectCustomPinId, setPinToCopy]
  );

  const dropPin = useCallback(
    (e: MapMouseEvent) => {
      return {
        icon: <AddLocationIcon fontSize="small" />,
        label: 'Drop Pin',
        onClick: () => {
          handleCreatePin(e, false);
        },
      };
    },
    [handleCreatePin]
  );

  const copyCoordinates = useCallback((e: MapMouseEvent) => {
    return {
      label: `${e.lngLat.lat.toFixed(5)}, ${e.lngLat.lng.toFixed(5)}`,
      onClick: () => {
        copyToClipboard(`${e.lngLat.lat}, ${e.lngLat.lng}`);
      },
    };
  }, []);

  const copyAddress = useCallback((e: MapMouseEvent) => {
    return {
      icon: <ContentCopyIcon fontSize="small" />,
      label: 'Copy Address',
      onClick: async () => {
        const location = await getPointLocation(e.lngLat.lng, e.lngLat.lat);
        location.place && copyToClipboard(location.place.place_name);
      },
    };
  }, []);

  const copyPin = useCallback(
    (e: MapMouseEvent) => {
      return {
        icon: <AddLocationIcon fontSize="small" />,
        label: `Paste Copy of "${copiedPin?.label}"`,
        onClick: () => handleCreatePin(e, true),
      };
    },
    [copiedPin?.label, handleCreatePin]
  );

  const createPinFromTemplate = useCallback(
    (e: MapMouseEvent) => {
      e.preventDefault();
      return {
        icon: <AddLocationIcon fontSize="small" />,
        label: `Pin from Template`,
        onClick: () => {
          onOpenChange(ModalNames.SELECT_TEMPLATE_FOR_PIN, true);
          onDataChange(ModalNames.SELECT_TEMPLATE_FOR_PIN, {
            onSelect: (template: PinTemplate) => {
              handleCreatePin(e, false, template);
              onOpenChange(ModalNames.SELECT_TEMPLATE_FOR_PIN, false);
            },
          });
        },
      };
    },
    [handleCreatePin, onDataChange, onOpenChange]
  );

  const setClickedPOiFeature = useDynamicMapStore(
    (state) => state.setClickedPOiFeature
  );

  const sonarDataYear = useDemographicStore((state) => state.sonarDataYear);

  const fetchSonarDataCallback = useCallback(
    async (id: string, brandId: string) => {
      if (!accessToken) return; // Only proceed if accessToken is available

      setSonarDataIsLoading(true); // Start loading state

      try {
        const params: FetchSonarDataParams = {
          id: id,
          brand_id: brandId,
          access_token: accessToken,
          ranking_year: sonarDataYear,
        };
        const data: SonarData = await fetchSonarData(params);
        if (data != null) {
          setSonarData(data);
        } else {
          setSonarDataError('No data returned');
          setSonarData(null); // Clear sonar data if data is null
        }
      } catch (err) {
        // Set the error message appropriately
        const errorMessage =
          err instanceof Error
            ? err.message
            : 'An unexpected error occurred while fetching Sonar data.';
        setSonarDataError(errorMessage);
        setSonarData(null); // Clear sonar data on error
      } finally {
        setSonarDataIsLoading(false); // Stop loading state
      }
    },
    [
      accessToken,
      setSonarData,
      setSonarDataIsLoading,
      setSonarDataError,
      sonarDataYear,
    ]
  );

  useEffect(() => {
    if (clickedPOiFeature && accessToken) {
      fetchSonarDataCallback(
        clickedPOiFeature.properties?.id,
        clickedPOiFeature.layer?.id
      );
    }
  }, [clickedPOiFeature, sonarDataYear, accessToken, fetchSonarDataCallback]);

  const setPOIData = useDemographicStore((state) => state.setPOIData);
  const setPoiDataIsLoading = useDemographicStore(
    (state) => state.setPoiDataIsLoading
  );

  const fetchPOIDataCallback = useCallback(
    async (id: string) => {
      setSonarDataIsLoading(true);
      setPoiDataIsLoading(true);

      try {
        console.log('Fetching POI data for ID:', id);
        const params: FetchPOIDataParams = {
          placeIds: [id],
          returnData: true,
          accessToken: accessToken!,
        };
        const data = await fetchPOIData(params);
        if (data != null && data.length > 0) {
          const poiData = data[0] as POIData;
          setPOIData(poiData);
        } else {
          setPOIData(null);
        }
      } catch (err) {
        console.error('Error fetching POI data:', err);
        setPOIData(null);
        setPoiDataError(err as string);
      } finally {
        setPoiDataIsLoading(false);
      }
    },
    [clickedPOiFeature, accessToken, setPOIData, setPoiDataIsLoading]
  );

  useEffect(() => {
    if (map == null || pinMethods == null) return;

    const handleContextMenu = (e: MapMouseEvent) => {
      if (isMapMoving) return; // Prevent context menu during map movement

      const contextMenuOptions = [copyCoordinates(e)] as ContextMenuOption[];

      // try to get features from clicked on location
      const clickedOnThisFeatures = map.queryRenderedFeatures(e.point, {});
      const featureFromTop = clickedOnThisFeatures[0];

      const { isPOI, isTerritory } = checkFeatureType(featureFromTop);

      const enableRightClickContextMenu =
        featureFromTop?.geometry && (isPOI || isTerritory);

      const territoriesToAddPulse = new Set<CustomTerritory>();

      // if there is a feature and it is a poi or territory
      if (enableRightClickContextMenu) {
        const territories: CustomTerritory[] = [];

        clickedOnThisFeatures.forEach((feature) => {
          const { territory, demographicEntity } = getDemographicEntity(
            feature,
            customTerritories
          );

          if (
            territory &&
            demographicEntity &&
            !territories.includes(territory)
          ) {
            territories.push(territory);
            territoriesToAddPulse.add(territory);
          }
        });

        // add center on pin option to only poi
        if (isPOI) {
          const { demographicEntity } = getDemographicEntity(
            featureFromTop,
            customTerritories
          );

          contextMenuOptions.push({
            icon: <GpsFixedIcon fontSize="small" />,
            label: 'Center on Pin',
            onClick: () =>
              demographicEntity?.type === 'point' &&
              centerMapOnPin(demographicEntity.pos),
          });
        }
      }
      contextMenuOptions.push(copyAddress(e));
      if (!isReadOnly) {
        contextMenuOptions.push(dropPin(e));
        if (copiedPin) {
          contextMenuOptions.push(copyPin(e));
        }
        if (templates?.length > 0) {
          contextMenuOptions.push(createPinFromTemplate(e));
        }
      }

      territoriesToAddPulse.forEach((territory) => {
        contextMenuOptions.push({
          icon: <BoltIcon fontSize="small" />,
          label: `Get Pulse for Territory ${territory.label}`,
          onClick: () => {
            setEvaluatedDemographicEntity({
              type: 'territory',
              id: territory.id,
            });
          },
        });
      });

      // set the context menu options
      setContextMenu({
        position: e.lngLat,
        offset: [0, 0] as [number, number],
        options: contextMenuOptions,
      });
    };

    const handleLeftClick = (e: MapMouseEvent) => {
      if (isMapMoving) return;

      const clickedOnThisFeature = map.queryRenderedFeatures(e.point, {})?.[0];

      const { isPOI, isTerritory } = checkFeatureType(clickedOnThisFeature);

      if (
        clickedOnThisFeature &&
        clickedOnThisFeature.geometry &&
        (isPOI || isTerritory)
      ) {
        // Log all properties from the tileset for POIs
        if (isPOI) {
          console.log(
            'POI Tileset Properties:',
            clickedOnThisFeature.properties
          );
        }

        const { demographicEntity, territory } = getDemographicEntity(
          clickedOnThisFeature,
          customTerritories
        );

        if (demographicEntity) {
          // Set the drawer state immediately to avoid UI delay
          setClickedPOiFeature(clickedOnThisFeature);
          if (demographicEntity.type !== 'territory') {
            setEvaluatedDemographicEntity(demographicEntity);
          }

          if (
            demographicEntity.type === 'territory' &&
            territory &&
            territory.id !== evaluatedTerritoryId &&
            !isDrawingTerritory
          ) {
            setSelectedTerritoryGroup(territory?.group);
            setEvaluatedTerritoryId(demographicEntity.id);
            flyToTerritory(territory);
          }

          // Fetch POI and Sonar data asynchronously after setting the UI state
          (async () => {
            try {
              if (isPOI) {
                await fetchPOIDataCallback(clickedOnThisFeature.properties?.id);
                await fetchSonarDataCallback(
                  clickedOnThisFeature.properties?.id,
                  clickedOnThisFeature.layer?.id
                );
              }
            } catch (error) {
              console.error('Error fetching POI or Sonar data:', error);
            }
          })();
        }
      }
    };

    map.on('movestart', () => setIsMapMoving(true));
    map.on('moveend', () => setIsMapMoving(false));
    map.on('contextmenu', handleContextMenu);
    map.on('click', handleLeftClick);

    return () => {
      map.off('movestart', () => setIsMapMoving(true));
      map.off('moveend', () => setIsMapMoving(false));
      map.off('contextmenu', handleContextMenu);
      map.off('click', handleLeftClick);
    };
  }, [
    map,
    customPins.length,
    pinMethods,
    selectCustomPinId,
    setContextMenu,
    mapChanges,
    evaluatedDemographicEntity,
    setEvaluatedDemographicEntity,
    clickedPOiFeature,
    centerMapOnPin,
    dropPin,
    setClickedPOiFeature,
    fetchSonarDataCallback,
    customTerritories,
    isMapMoving,
    setSelectedTerritoryGroup,
    setEvaluatedTerritoryId,
    copiedPin,
    copyPin,
    templates,
    createPinFromTemplate,
    copyCoordinates,
    isReadOnly,
    copyAddress,
    evaluatedTerritoryId,
    isDrawingTerritory,
    flyToTerritory,
    fetchPOIDataCallback,
  ]);

  return <ContextMenu />;
}
