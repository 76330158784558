import { Bolt as BoltIcon } from '@mui/icons-material';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styled from '@emotion/styled';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Input,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  CustomTerritory,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_PIN_GROUP_NAME } from '~/src/constants';
import DeleteConfirmationDialog from '~/src/features/custom-territories/DeleteConfirmationDialog';
import PopupWithoutWheel from '~/src/features/dynamic-map/components/PopupWithoutWheel';
import useCustomTerritories from '~/src/features/dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import GeneralTab from './general';
import LocationSearch from './LocationSearch';
import PropertiesTab from './properties';
import { EditPinSchema, editPinSchema } from './schema';

const StyledPopup = styled(PopupWithoutWheel)`
  .mapboxgl-popup-content {
    padding: 0.5rem;
    border-radius: 0.5rem;
    min-width: 22rem;
    position: relative;
    top: 0;
  }

  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: 100%;
    box-shadow: none;

    input {
      height: 32px;
    }
  }
`;

const EditPinPopup: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const theme = useTheme();
  const selectPinId = useDynamicMapStore((state) => state.selectCustomPinId);
  const deselectPin = () => selectPinId(null);

  const {
    selectedPinId,
    evaluatedDemographicEntity,
    setEvaluatedDemographicEntity,
    setEvaluatedPinId,
    setClickedPOiFeature,
  } = useDynamicMapStore((state) => ({
    selectedPinId: state.selectedCustomPinId,
    evaluatedDemographicEntity: state.evaluatedDemographicEntity,
    setEvaluatedDemographicEntity: state.setEvaluatedDemographicEntity,
    setEvaluatedPinId: state.setEvaluatedPinId,
    setClickedPOiFeature: state.setClickedPOiFeature,
  }));
  const { customPins } = useCustomPins();
  const { customTerritories } = useCustomTerritories();
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const customTerritoriesMethods = plotrMultiplayerData.methods?.territories;

  const pin = useMemo(
    () => customPins.find((p) => p.id === selectedPinId) ?? null,
    [customPins, selectedPinId]
  );

  const pinGroups = useMemo(
    () => [...new Set(customPins.map((p) => p.group))],
    [customPins]
  );

  const pinTerritories = useMemo<CustomTerritory[]>(
    () =>
      pin
        ? customTerritories.filter((territory) =>
            territory.pins?.includes(pin.id)
          )
        : [],
    [customTerritories, pin]
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  const determineDemographicButtonBackground =
    evaluatedDemographicPoint?.lat === pin?.pos.lat &&
    evaluatedDemographicPoint?.lng === pin?.pos.lng
      ? 'primary'
      : 'disabled';

  const onClickDemographics = () => {
    if (!pin) return;

    if (
      evaluatedDemographicPoint?.lat === pin.pos.lat &&
      evaluatedDemographicPoint?.lng === pin.pos.lng
    ) {
      setEvaluatedDemographicEntity(null);
      return;
    }
    setClickedPOiFeature(null);
    setEvaluatedDemographicEntity({
      type: 'point',
      pos: pin.pos,
    });
    setEvaluatedPinId(pin.id);
  };

  const { handleSubmit, control, setValue, reset } = useForm<EditPinSchema>({
    resolver: zodResolver(editPinSchema),
    defaultValues: {
      label: '',
      pinGroup: DEFAULT_PIN_GROUP_NAME,
      pinTerritories: [],
      tags: [],
      kvPairs: pin?.keyValuePairs || {},
    },
  });

  function onSave(data: EditPinSchema) {
    if (!pin) return;

    if (data.label !== pin.label) {
      customPinMethods?.setLabel(pin.id, data.label);
    }

    if (data.pinGroup !== pin.group) {
      customPinMethods?.setGroup(
        pin.id,
        data.pinGroup?.trim() || DEFAULT_PIN_GROUP_NAME
      );
    }

    // update territories
    const addedTerritories = data.pinTerritories.filter(
      ({ id }) => !pinTerritories.some((territory) => territory.id === id)
    );
    const removedTerritories = pinTerritories.filter(
      ({ id }) => !data.pinTerritories.some((territory) => territory.id === id)
    );

    addedTerritories.forEach((territory) =>
      customTerritoriesMethods?.setPins(territory.id, [
        ...(territory.pins ?? []),
        ...(pin ? [pin.id] : []),
      ])
    );
    removedTerritories.forEach((territory) =>
      customTerritoriesMethods?.setPins(
        territory.id,
        territory.pins?.filter((id) => id !== pin?.id) ?? []
      )
    );

    // update tags
    const addedTags = data.tags.filter(
      (tag) => !pin.tags?.includes(tag.toLowerCase())
    );
    const removedTags = pin.tags?.filter(
      (tag) => !data.tags.includes(tag.toLowerCase())
    );

    addedTags.forEach((tag) => customPinMethods?.addTag(pin.id, tag));
    removedTags.forEach((tag) => customPinMethods?.removeTag(pin.id, tag));

    // update key value pairs
    const prevKvPairs = pin.keyValuePairs;

    const addedKvPairs = Object.entries(data.kvPairs).reduce(
      (acc, [key, value]) => {
        if (!prevKvPairs[key]) {
          acc.set(key, value);
        }

        return acc;
      },
      new Map<string, string>()
    );

    const updatedKvPairs = Object.entries(data.kvPairs)
      .filter(
        ([key]) => !data.kvPairsToDelete.includes(key) && !addedKvPairs.has(key)
      )
      .reduce(
        (acc, [key, value]) => {
          if (prevKvPairs[key] !== value) {
            acc.push({ key, value });
          }

          return acc;
        },
        [] as { key: string; value: string }[]
      );

    customPinMethods?.removeKeyValuePairs(pin.id, data.kvPairsToDelete);
    customPinMethods?.addKeyValuePairs(pin.id, addedKvPairs);
    updatedKvPairs.forEach(({ key, value }) =>
      customPinMethods?.updateKeyValuePair(pin.id, key, value)
    );

    setDefaultValues();
    setIsEditing(false);
  }

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const setDefaultValues = useCallback(() => {
    if (!pin) return;

    reset({
      label: pin.label,
      pinGroup: pin.group || DEFAULT_PIN_GROUP_NAME,
      pinTerritories,
      tags: pin.tags ?? [],
      kvPairs: pin.keyValuePairs,
      kvPairsToDelete: [],
    });
  }, [pin, pinTerritories, reset]);

  useEffect(() => {
    setDefaultValues();
  }, [pin, pinTerritories, setValue, setDefaultValues]);

  if (!pin || !customPinMethods) return null;

  const tabs: ReactNode[] = [
    <GeneralTab
      key="general-tab"
      isEditing={isEditing}
      control={control}
      pinGroups={pinGroups}
      pin={pin}
    />,
    <PropertiesTab
      key="properties-tab"
      isEditing={isEditing}
      control={control}
    />,
  ];

  return (
    <>
      <Card>
        <StyledPopup
          offset={[0, -50] as [number, number]}
          longitude={pin.pos.lng}
          latitude={pin.pos.lat}
          closeOnClick={false}
          onClose={deselectPin}
        >
          <form onSubmit={handleSubmit(onSave)}>
            <CardHeader
              title={
                isEditing ? (
                  <Controller
                    control={control}
                    name="label"
                    render={({ field: { value, onChange } }) => (
                      <Input
                        type="text"
                        value={value}
                        onChange={onChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Pin Label"
                        style={{ width: '100%' }}
                        size="small"
                      />
                    )}
                  />
                ) : (
                  <Typography variant="h6" fontWeight={700}>
                    {pin.label}
                  </Typography>
                )
              }
              sx={{ padding: 1 }}
            />
            <Box px={1} pb={1}>
              <LocationSearch pin={pin} isEditing={isEditing} />
            </Box>

            <CardContent sx={{ padding: 1, pt: 0 }}>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                aria-label="Edit pin tabs"
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Overview" sx={{ fontSize: 11 }} />
                <Tab label="Data" sx={{ fontSize: 11 }} />
              </Tabs>
              <Box pt={1}>{tabs[tabIndex]}</Box>
            </CardContent>

            <CardActions>
              <Box display="flex" justifyContent="space-between">
                <Tooltip title="Get Pulse Report">
                  <BoltIcon
                    color={determineDemographicButtonBackground}
                    onClick={onClickDemographics}
                    cursor="pointer"
                    sx={{
                      ':hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                </Tooltip>
              </Box>
              <Box display="flex" justifyContent="end" width="100%">
                {isEditing ? (
                  <Box display="flex" gap={1}>
                    <Button
                      size="small"
                      onClick={() => {
                        setDefaultValues();
                        setIsEditing(false);
                      }}
                      color="error"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" size="small" variant="contained">
                      Save
                    </Button>
                  </Box>
                ) : (
                  <>
                    <IconButton
                      aria-label="edit"
                      onClick={() => setIsEditing(true)}
                      size="small"
                      title="Edit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => setIsDeleteDialogOpen(true)}
                      disabled={pin == null}
                      color="error"
                      size="small"
                      title="Delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </CardActions>
          </form>
        </StyledPopup>
      </Card>
      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          customPinMethods?.removePin(pin.id);
          deselectPin();
          setIsDeleteDialogOpen(false);
        }}
        itemName={pin.label}
      />
    </>
  );
};

export default EditPinPopup;
