export const PRICING_LAYERS = {
  MATCHING: {
    id: 'pulse-price-matching',
    displayName: 'Pulse Price: Competitors',
    color: '#2196F3',
    endpoint: 'sampleset',
  },
  TAID: {
    id: 'pulse-price-taid',
    displayName: 'Pulse Price: TAID',
    color: '#4CAF50',
    endpoint: 'taid',
  },
  TERRITORY: {
    id: 'pulse-price-territory',
    displayName: 'Pulse Price: Territories',
    color: '#FF4B4B',
    endpoint: 'territories',
  },
  FRANCHISES: {
    id: 'pulse-price-franchises',
    displayName: 'Pulse Price: Open Locations',
    color: '#9C27B0',
    endpoint: 'franchises',
  },
} as const;

export type PricingLayerId =
  (typeof PRICING_LAYERS)[keyof typeof PRICING_LAYERS]['id'];
