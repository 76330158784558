import {
  Check as CheckIcon,
  Delete,
  Edit as EditIcon,
  List as ListIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import { useState } from 'react';
import usePermissionsStore from '~/src/global/hooks/usePermissionsStore';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import DeletePinGroupDialog from './DeletePinGroupDialog';

const PinGroupCard = ({
  group,
  pinCount,
  onAction,
}: {
  group: string;
  pinCount: number;
  onAction: () => void;
}) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const { hiddenPinGroups, togglePinGroupVisibility } = useDynamicMapStore(
    (state) => state
  );
  const isReadOnly = usePermissionsStore((state) => state.isReadOnly);

  const submitNewName = () => {
    if (customPinMethods == null) return;

    customPinMethods.renameGroup(group, newGroupName);
    setIsEditing(false);
  };

  return (
    <>
      <Paper key={group} variant="outlined" sx={{ marginBottom: 1 }}>
        <Box
          padding={2}
          width="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box sx={{ padding: 0.5 }}>
            {isEditing ? (
              <>
                <TextField
                  autoFocus
                  size="small"
                  value={newGroupName}
                  onChange={(event) => setNewGroupName(event.target.value)}
                  onBlur={submitNewName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      submitNewName();
                    } else if (e.key === 'Escape') {
                      setIsEditing(false);
                    }
                  }}
                  disabled={customPinMethods == null}
                />
                <IconButton onClick={submitNewName}>
                  <CheckIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <Typography variant="body1" fontWeight={500}>
                  {group}
                </Typography>
                <Box display="flex" gap={0.5} alignItems="flex-start">
                  <IconButton
                    disabled={isReadOnly}
                    onClick={() => {
                      setIsEditing(true);
                      setNewGroupName(group);
                    }}
                    size="small"
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    disabled={isReadOnly}
                    onClick={() => setIsDeleteDialogOpen(true)}
                    size="small"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      togglePinGroupVisibility(group);
                    }}
                    sx={{ padding: 0.5 }}
                  >
                    {hiddenPinGroups.includes(group) ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              endIcon={<ListIcon />}
              onClick={onAction}
              size="small"
              sx={{
                borderRadius: 1,
                textTransform: 'none',
                minWidth: '120px',
                marginX: 2,
              }}
            >
              View Pins ({pinCount})
            </Button>
          </Box>
        </Box>
      </Paper>
      <DeletePinGroupDialog
        group={group}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};

export default PinGroupCard;
