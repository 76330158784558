import { Box, Chip, Grid, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { EditPinSchema } from '../schema';
import { Control, Controller } from 'react-hook-form';

type PinTagsProps = {
  control: Control<EditPinSchema>;
  isEditing: boolean;
};

export const PinTags: FC<PinTagsProps> = ({ control, isEditing }) => {
  const [tagInput, setTagInput] = useState('');
  const [duplicatedTag, setDuplicatedTag] = useState<string>();

  const onKeyDown =
    (value: string[], onChange: (...event: any[]) => void) =>
    (keyEvent: React.KeyboardEvent) => {
      if (keyEvent.key !== 'Enter') {
        return;
      }

      keyEvent.preventDefault();
      const finalValue = tagInput.toLowerCase().trim();

      if (finalValue.length === 0) {
        return;
      }

      if (value.includes(finalValue)) {
        setDuplicatedTag(tagInput);
        setTagInput('');
        return;
      }

      onChange([...value, finalValue]);
      setTagInput('');
    };

  useEffect(() => {
    if (!duplicatedTag) return;

    const timeout = setTimeout(() => {
      setDuplicatedTag(undefined);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [duplicatedTag]);

  return (
    <Controller
      name="tags"
      control={control}
      render={({ field: { value, onChange } }) => (
        <Grid container spacing={1} alignItems="center">
          {isEditing && (
            <Grid item xs={12}>
              <TextField
                id="tag"
                label="Add Tag"
                variant="outlined"
                size="small"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                inputProps={{ maxLength: 30 }}
                onKeyDown={onKeyDown(value, onChange)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" height="100%" gap={1} flexWrap="wrap">
              {value.length || isEditing ? (
                value.map((tag) => (
                  <Chip
                    key={tag}
                    size="small"
                    label={tag}
                    color={duplicatedTag === tag ? 'error' : 'primary'}
                    onDelete={
                      isEditing
                        ? () => {
                            onChange(value.filter((t) => t !== tag));
                          }
                        : undefined
                    }
                  />
                ))
              ) : (
                <Typography variant="body2" color="gray" sx={{ pt: 0.2 }}>
                  No value
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    />
  );
};
